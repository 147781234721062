import CustomerDocuments from '../model/customer/CustomerDocuments';
import { DocumentStateType } from '../model/value-objects/DocumentStateType';
import { createUUID } from '../model/value-objects/UUID';

export default class CustomerDocumentsUnitTestHelper {
	public static create(updates?: any): CustomerDocuments {
		return new CustomerDocuments({
			applicationId: createUUID(),
			customerId: createUUID(),
			photoId: DocumentStateType.NotRequested,
			payStub: DocumentStateType.NotRequested,
			T4: DocumentStateType.NotRequested,
			T1: DocumentStateType.NotRequested,
			NOA: DocumentStateType.NotRequested,
			mortgageStatement: DocumentStateType.NotRequested,
			...updates
		});
	}
}
