import { ApplicationRating, ApplicationType } from '@application/common';

export default class FacebookPixelClient {
	public static init(customerId: string | undefined): void {
		if (window && (window as any).fbq) {
			if (customerId) {
				(window as any).fbq(
					'init',
					`${process.env.REACT_APP_FACEBOOK_PIXEL_PUBLIC_ID}`,
					{
						extern_id: customerId,
						external_id: customerId
					}
				);
			}
		} else {
			// eslint-disable-next-line no-console
			console.warn('Pixel not loaded');
		}
	}

	public static pageView(
		name: string,
		type?: ApplicationType | undefined
	): void {
		if (window && (window as any).fbq) {
			(window as any).fbq('track', 'ViewContent', {
				content_name: `${this.getApplicationType(type)}${name}`
			});
		} else {
			// eslint-disable-next-line no-console
			console.warn('Pixel not loaded');
		}
	}

	public static logEvent(event: string): void {
		if (window && (window as any).fbq) {
			(window as any).fbq('trackCustom', event);
		} else {
			// eslint-disable-next-line no-console
			console.warn('Pixel not loaded');
		}
	}

	public static logPreQualConversion(applicationId: string): void {
		if (window && (window as any).fbq) {
			(window as any).fbq(
				'trackCustom',
				'All General Leads',
				{},
				{ eventID: applicationId }
			);
		} else {
			// eslint-disable-next-line no-console
			console.warn('Pixel not loaded');
		}
	}

	public static logPreQualConversionV2(
		applicationId: string,
		applicationType: ApplicationType
	): void {
		if (window && (window as any).fbq) {
			if (applicationType === ApplicationType.NewMortgage) {
				(window as any).fbq(
					'trackCustom',
					'Lead V2',
					{},
					{ eventID: applicationId }
				);
			} else if (applicationType === ApplicationType.Refinance) {
				(window as any).fbq(
					'trackCustom',
					'Refinance Lead V2',
					{},
					{ eventID: applicationId }
				);
			} else if (applicationType === ApplicationType.Renewal) {
				(window as any).fbq(
					'trackCustom',
					'Renewal Lead V2',
					{},
					{ eventID: applicationId }
				);
			}
		} else {
			// eslint-disable-next-line no-console
			console.warn('Pixel not loaded');
		}
	}

	public static logRating(
		applicationId: string,
		rating: ApplicationRating | null
	) {
		if (rating === ApplicationRating.A || rating === ApplicationRating.B) {
			if (window && (window as any).fbq) {
				(window as any).fbq(
					'trackCustom',
					'All Qualified Leads',
					{},
					{ eventID: applicationId }
				);
			} else {
				// eslint-disable-next-line no-console
				console.warn('Pixel not loaded');
			}
		}
	}

	private static getApplicationType(
		type: ApplicationType | undefined
	): string {
		if (type === undefined) {
			return '';
		}
		return type !== ApplicationType.NewMortgage ? `${type} ` : '';
	}
}
