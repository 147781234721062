export default class Exception extends Error {
	private __proto__: Exception | undefined;
	constructor(message?: string) {
		// 'Error' breaks prototype chain here
		super(message);

		// restore prototype chain
		const actualProto = new.target.prototype;

		if (Object.setPrototypeOf) {
			Object.setPrototypeOf(this, actualProto);
		} else {
			this.__proto__ = actualProto;
		}
	}
}
