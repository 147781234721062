import { ExperimentDto } from '@application/common';
import ExperimentHelper from 'common/experiments/ExperimentHelper';
import React, { useCallback, useContext, useState } from 'react';
import { useEffectOnce } from '../hooks/useEffectOnce';

interface IExperimentContext {
	isLoading: boolean;
	experiments: Array<ExperimentDto>;
}

export class ExperimentContext implements IExperimentContext {
	public isLoading: boolean;
	public experiments: Array<ExperimentDto>;

	constructor(state?: IExperimentContext) {
		this.isLoading = state?.isLoading ?? false;
		this.experiments = state?.experiments ?? [];
	}

	public hasExperiment(id: number) {
		return (
			ExperimentHelper.retrieveExperimentValue(this.experiments, id) === 1
		);
	}
}

export const ExperimentContextState = React.createContext<ExperimentContext>(
	new ExperimentContext()
);

export function useExperimentContext(): ExperimentContext {
	return useContext(ExperimentContextState);
}

export default function ExperimentProvider({ children }): JSX.Element {
	const [state, setState] = useState<ExperimentContext>(
		new ExperimentContext()
	);
	const getExperiments = useCallback(async () => {
		setState(
			new ExperimentContext({
				...state,
				isLoading: true
			})
		);
		const response = await fetch(`/api/experiments`);
		if (response.status === 200) {
			setState(
				new ExperimentContext({
					...state,
					isLoading: false,
					experiments: await response.json()
				})
			);
		} else if (response.status === 204) {
			// eslint-disable-next-line no-console
			console.info(`No experiments.`);
			setState(
				new ExperimentContext({
					...state,
					isLoading: false,
					experiments: []
				})
			);
		} else {
			// eslint-disable-next-line no-console
			console.warn(
				`Failed to load experiments. status: ${response.status}`
			);
			setState(
				new ExperimentContext({
					...state,
					isLoading: false,
					experiments: []
				})
			);
		}
	}, [state, setState]);

	useEffectOnce(() => {
		getExperiments();
	});

	if (state.isLoading) {
		return <></>;
	}

	return (
		<ExperimentContextState.Provider value={state}>
			{children}
		</ExperimentContextState.Provider>
	);
}
