import BaseEntity from '../common/BaseEntity';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import { IpaStatus } from '../ipa/IpaStatus';
import ILiability from '../liabilities/ILiability';
import { CreditScore } from '../value-objects/CreditScore';
import { IncomeType } from '../value-objects/IncomeType';
import { UUID } from '../value-objects/UUID';
import { YesNo } from '../value-objects/YesNo';
import ICoApplicant from './ICoApplicant';
import ICoApplicantDocuments from './ICoApplicantDocuments';

export default class CoApplicant extends BaseEntity implements ICoApplicant {
	public readonly id: UUID;
	public readonly applicationId: UUID;
	public readonly coApplicantCustomerId: UUID | null;
	public readonly firstName: string | null;
	public readonly lastName: string | null;
	public readonly email: string | null;
	public readonly mobilePhone: string | null;
	public readonly birthdate: Date | null;
	public readonly creditScore: CreditScore | null;
	public readonly incomeType: IncomeType | null;
	public readonly isFirstTimeBuyer: YesNo | null;
	public readonly hasBankruptcy: YesNo | null;
	public readonly isBankruptcyLiberated: YesNo | null;
	public readonly bankruptcyYearLiberated: number | null;
	public readonly grossIncomeApprox: number | null;
	public readonly liabilities: Array<ILiability>;
	public readonly documents: ICoApplicantDocuments | undefined;
	public readonly isInvitationSent: boolean;
	public readonly ipaStatus: IpaStatus | null;
	public readonly ipaSentDate: Date | null;

	constructor(input: ICoApplicant) {
		super({ entityType: EntityTypeEnum.CoApplicant });
		this.id = input.id;
		this.applicationId = input.applicationId;
		this.coApplicantCustomerId = input.coApplicantCustomerId;
		this.firstName = input.firstName;
		this.lastName = input.lastName;
		this.email = input.email;
		this.mobilePhone = input.mobilePhone;
		this.birthdate = input.birthdate;
		this.creditScore = input.creditScore;
		this.incomeType = input.incomeType;
		this.isFirstTimeBuyer = input.isFirstTimeBuyer;
		this.hasBankruptcy = input.hasBankruptcy;
		this.isBankruptcyLiberated = input.isBankruptcyLiberated;
		this.bankruptcyYearLiberated = input.bankruptcyYearLiberated;
		this.grossIncomeApprox = input.grossIncomeApprox;
		this.liabilities = input.liabilities;
		this.documents = input.documents;
		this.isInvitationSent = input.isInvitationSent ?? false;
		this.ipaStatus = input.ipaStatus;
		this.ipaSentDate = input.ipaSentDate;
	}
}
