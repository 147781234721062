import ArgumentException from '../exceptions/ArgumentException';

export default class FullNameHelper {
	public static extract(
		name: 'firstName' | 'lastName',
		fullName: string
	): string {
		switch (name) {
			case 'firstName':
				return fullName.trim().split(' ')[0];
			case 'lastName':
				return fullName.trim().split(' ').slice(1).join(' ');
			default:
				throw new ArgumentException('name', name);
		}
	}

	public static combine(
		firstName: string | null | undefined,
		lastName: string | null | undefined
	): string {
		return `${firstName} ${lastName ?? ''}`.trim();
	}
}
