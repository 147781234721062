import { z } from 'zod';
import { LenderType } from '../../value-objects/LenderType';
import { RateTerm } from '../../value-objects/RateTerm';
import { RateType } from '../../value-objects/RateType';

export const RateAndPaymentDtoSchema = z.object({
	isInsured: z.boolean(),
	rate: z.number(),
	monthlyPayment: z.number(),
	term: z.nativeEnum(RateTerm),
	rateType: z.nativeEnum(RateType),
	lenderType: z.nativeEnum(LenderType)
});

type RateAndPaymentDtoInput = z.infer<typeof RateAndPaymentDtoSchema>;

export default class RateAndPaymentDto {
	public static readonly Schema = RateAndPaymentDtoSchema;
	public readonly isInsured: boolean;
	public readonly rate: number;
	public readonly monthlyPayment: number;
	public readonly term: RateTerm;
	public readonly rateType: RateType;
	public readonly lenderType: LenderType;

	constructor(input: RateAndPaymentDtoInput) {
		const schema = RateAndPaymentDtoSchema.parse(input);
		this.isInsured = schema.isInsured;
		this.rate = schema.rate;
		this.monthlyPayment = schema.monthlyPayment;
		this.term = schema.term;
		this.rateType = schema.rateType;
		this.lenderType = schema.lenderType;
	}
}
