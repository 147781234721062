import { Auth0Provider } from '@auth0/auth0-react';
import DataDogClient from 'services/DataDogClient';
import FeatureFlagClient from 'services/FeatureFlagClient';
import AppRouter from './AppRouter';
import ExperimentProvider from './common/context/ExperimentProvider';
import LanguageProvider from './common/context/LanguageProvider';
import UserContextProvider from './common/login/UserContextProvider';

export default function App() {
	DataDogClient.getInstance();
	FeatureFlagClient.getInstance().refresh();

	return (
		// Never put a provide above Auth0 (Login loop)
		<Auth0Provider
			domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
			clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
			redirectUri={`${process.env.REACT_APP_APPLICATION_ROOT_URL}/loginCallback`}
			audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
			responseType="token id_token"
			useRefreshTokens={true}
		>
			<LanguageProvider>
				<ExperimentProvider>
					<UserContextProvider>
						<AppRouter />
					</UserContextProvider>
				</ExperimentProvider>
			</LanguageProvider>
		</Auth0Provider>
	);
}
