export default class CookieConsentClient {
	public static getConsent(): 'accepted' | 'rejected' | null {
		let consent: any = localStorage.getItem('cookieConsent');
		if (consent === null) {
			consent = CookieConsentClient.getCookie('cookieConsent');
		}
		return consent;
	}

	private static getCookie(name: string): string | null {
		const cookieArray = document.cookie.split(';');
		for (let i = 0; i < cookieArray.length; i++) {
			let cookie = cookieArray[i];
			while (cookie.charAt(0) == ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name + '=') == 0) {
				return cookie.substring(name.length + 1, cookie.length);
			}
		}
		return null;
	}
}
