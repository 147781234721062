import React, { useEffect, useRef, useState } from 'react';
import styles from './TextInput.module.scss';
import { updateDocumentHeight } from 'components/common/hooks/useViewportHeight';
import classnames from 'classnames';

interface TextInputProps {
	label: string;
	name: string;
	value: string | undefined;
	displayValue?: string;
	autoFocus?: boolean;
	placeholder?: string;
	autoComplete?: string;
	type?: string;
	locked?: boolean;
	focussed?: boolean;
	error?: string;
	className?: string;
	isPublic?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default function TextInput(props: TextInputProps): JSX.Element {
	const { isPublic, autoComplete } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [state, setState] = useState({
		locked: props.locked ?? false,
		focussed: (props.locked && props.focussed) || false,
		value: props.value || ''
	});
	useEffect(() => {
		setState({ ...state, value: props.value ?? '' });
	}, [props.value]);

	const doChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const value = e.target.value;
		setState({ ...state, value });
		if (props.onChange) {
			props.onChange(e);
		}
	};
	return (
		<>
			<div
				className={classnames(
					styles.textInput,
					state.locked && styles.locked,
					props.error && styles.error,
					props.className
				)}
			>
				{props.displayValue && (
					<input
						id={props.name}
						hidden={true}
						name={props.name}
						readOnly={true}
						value={state.value}
					/>
				)}
				<div
					className={styles.border}
					onClick={() => {
						inputRef?.current?.focus();
					}}
				>
					<div
						className={classnames(
							styles.label,
							props.error && styles.error
						)}
					>
						{props.label}
					</div>
					<input
						ref={inputRef}
						className={classnames(
							state.locked && styles.locked,
							props.type === 'date' && styles.date
						)}
						id={
							props.displayValue
								? `${props.name}-display`
								: props.name
						}
						type={props.type ?? 'text'}
						name={
							props.displayValue
								? `${props.name}-display`
								: props.name
						}
						value={
							props.displayValue
								? props.displayValue
								: state.value
						}
						disabled={props.displayValue != undefined}
						placeholder={props.placeholder}
						autoComplete={autoComplete}
						readOnly={state.locked}
						onChange={doChange}
						onFocus={() => {
							updateDocumentHeight();
							!state.locked &&
								setState({ ...state, focussed: true });
						}}
						onBlur={() => {
							updateDocumentHeight();
							!state.locked &&
								setState({ ...state, focussed: false });
						}}
						autoFocus={props.autoFocus}
						data-public={isPublic ? '' : undefined}
						data-private={!isPublic ? '' : undefined}
						data-dd-privacy={!isPublic ? 'mask' : 'allow'}
					/>
				</div>
				<div className={styles.errorMessage}>{props.error}</div>
			</div>
		</>
	);
}
