import { useEffect } from 'react';
import ScriptHelper from './ScriptHelper';

const FacebookPixelScript = () => {
	const FB_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_PUBLIC_ID?.trim();

	useEffect(() => {
		if (FB_PIXEL_ID && FB_PIXEL_ID.length > 0) {
			const script = document.createElement('script');
			script.nonce = ScriptHelper.getNonce();
			script.id = 'facebook-pixel';
			script.innerHTML = `
                if (typeof fbq === 'undefined') {
                    !function(f,b,e,v,n,t,s){
                        if(f.fbq)return; n=f.fbq=function(){
                            n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
                        };
                        if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
                        n.queue=[]; t=b.createElement(e); t.async=!0;
                        t.src=v; s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)
                    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${FB_PIXEL_ID}');
                }
                fbq('track', 'PageView');
            `;

			document.body.appendChild(script);
		}
	}, [FB_PIXEL_ID]);

	return null;
};

export default FacebookPixelScript;
