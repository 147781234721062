import { Language } from '../model/value-objects/Language';
import MathHelper from './MathHelper';

export default class DateHelper {
	private static readonly twoDaysMilliseconds = 2 * 24 * 60 * 60 * 1000;
	private static readonly dayMilliseconds = 24 * 60 * 60 * 1000;
	private static readonly hourMilliseconds = 60 * 60 * 1000;
	private static readonly minuteMilliseconds = 60 * 1000;
	private static zeroPad = (num, places) => String(num).padStart(places, '0');

	public static parse(
		value: string | Date | null | undefined,
		defaultValue?: Date | null | undefined
	): any {
		let result: Date | null | undefined = defaultValue;
		if (value instanceof Date) {
			result = value;
		} else if (value) {
			result = new Date(Date.parse(value));
		}
		return result;
	}

	public static addDays(date: Date, days: number): Date {
		const inputDate = new Date(date);
		inputDate.setDate(inputDate.getDate() + days);
		return inputDate;
	}

	public static addMinutes(date: Date, minutes: number): Date {
		const inputDate = new Date(date);
		inputDate.setMinutes(inputDate.getMinutes() + minutes);
		return inputDate;
	}

	public static diffInDays(start: Date, end: Date): number {
		const timeDiff = end.getTime() - start.getTime();
		return MathHelper.round(timeDiff / (1000 * 3600 * 24), 0);
	}

	public static diffInMinutes(start: Date, end: Date): number {
		const timeDiff = end.getTime() - start.getTime();
		return MathHelper.round(timeDiff / (1000 * 60), 0);
	}

	public static toShortDate(date: Date, separator = '-'): string {
		const day = this.zeroPad(date.getUTCDate(), 2);
		const month = this.zeroPad(date.getUTCMonth() + 1, 2);
		const year = date.getUTCFullYear();
		return `${year}${separator}${month}${separator}${day}`;
	}

	public static toLongDate(lng: Language, date: Date): string {
		return this.capitalizeFirstLetter(
			Intl.DateTimeFormat(lng === Language.Fr ? 'fr-ca' : 'en-ca', {
				weekday: 'long',
				month: 'long',
				day: 'numeric',
				year: 'numeric'
			}).format(new Date(date))
		);
	}

	/**
	 * Return the ISO string without milliseconds
	 * @param date date object
	 * @returns ISO string without milliseconds
	 */
	public static toShortISOString(date: Date): string {
		return date.toISOString().substring(0, 19) + 'Z';
	}

	public static isValidDate(
		year: number,
		month: number,
		day: number
	): boolean {
		const d = new Date(year, month, day);
		if (
			d.getFullYear() == year &&
			d.getMonth() == month &&
			d.getDate() == day
		) {
			return true;
		}
		return false;
	}

	public static isValid(date: Date): boolean {
		return !isNaN(date.getTime());
	}

	public static toRelativeFromNow(lng: Language, date: Date) {
		const differenceMilliseconds =
			new Date(Date.now()).getTime() - new Date(date).getTime(); // milliseconds
		if (differenceMilliseconds > DateHelper.twoDaysMilliseconds) {
			return this.capitalizeFirstLetter(
				Intl.DateTimeFormat(lng === Language.Fr ? 'fr-ca' : 'en-ca', {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				}).format(new Date(date))
			);
		} else {
			let difference = Math.floor(
				differenceMilliseconds / DateHelper.minuteMilliseconds
			);
			let differenceUnit: Intl.RelativeTimeFormatUnit = 'minute';

			if (differenceMilliseconds / DateHelper.dayMilliseconds >= 1) {
				difference = Math.floor(
					differenceMilliseconds / DateHelper.dayMilliseconds
				);
				differenceUnit = 'day';
			} else if (
				differenceMilliseconds / DateHelper.hourMilliseconds >=
				1
			) {
				difference = Math.floor(
					differenceMilliseconds / DateHelper.hourMilliseconds
				);
				differenceUnit = 'hour';
			}
			return this.capitalizeFirstLetter(
				new Intl.RelativeTimeFormat(
					lng === Language.Fr ? 'fr-ca' : 'en-ca',
					{
						localeMatcher: 'best fit',
						numeric: 'auto',
						style: 'long'
					}
				).format(-difference, differenceUnit)
			);
		}
	}

	private static capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
}
