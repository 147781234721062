import Section from '../containers/Section';
import Page from './Page';
import styles from './CustomPage.module.scss';
import NotFound from 'components/common/notFound/NotFound';
import { useEffectOnce } from '../hooks/useEffectOnce';
import DataDogClient from 'services/DataDogClient';
import { useLocation } from 'react-router-dom';

export default function ErrorPage(): JSX.Element {
	const { pathname } = useLocation();
	useEffectOnce(() => {
		DataDogClient.getInstance().warn(`Page not found : '${pathname}'`, {
			url: pathname
		});
	});
	return (
		<Page title={''} className={styles.content}>
			<Section>
				<NotFound />
			</Section>
		</Page>
	);
}
