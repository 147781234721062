export interface PrivateSpanProps {
	className?: string;
	children?: any;
}

export default function PrivateSpan(props: PrivateSpanProps) {
	const { className, children } = props;
	return (
		<span className={className} data-private="" data-dd-privacy={'mask'}>
			{children}
		</span>
	);
}
