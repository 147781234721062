import PIIHelper from './PIIHelper';

export default class CustomerHelper {
	public static removePII(data: any): any {
		const result = JSON.parse(JSON.stringify(data));

		PIIHelper.replacePiiPropertyForAllDescendant(
			['firstName', 'middleName', 'lastName', 'mobilePhone', 'homePhone'],
			result
		);

		PIIHelper.replaceEmailPiiPropertyForAllDescendant(['email'], result);

		return result;
	}
}
