import Exception from '../exceptions/Exception';

type MapFuncton<V, X> = (v: V, i: number) => X;

export class Node<T> {
	public next: Node<T> | null = null;
	public prev: Node<T> | null = null;
	constructor(public data: T) {}
}

export interface ILinkedList<T> {
	insertAtStart(data: T): Node<T>;
	insertAtEnd(data: T): Node<T>;
	deleteNode(node: Node<T>): void;
	traverse(): T[];
	size(): number;
	search(comparator: (data: T) => boolean): Node<T> | null;
}

export default class LinkedList<T> implements ILinkedList<T> {
	private head: Node<T> | null = null;

	constructor(items?: Array<T>) {
		items?.forEach((i) => this.insertAtEnd(i));
	}

	public insertAtEnd(data: T): Node<T> {
		const node = new Node(data);
		if (!this.head) {
			this.head = node;
		} else {
			const getLast = (node: Node<T>): Node<T> => {
				return node.next ? getLast(node.next) : node;
			};

			const lastNode = getLast(this.head);
			node.prev = lastNode;
			lastNode.next = node;
		}
		return node;
	}

	public insertAtStart(data: T): Node<T> {
		const node = new Node(data);
		if (!this.head) {
			this.head = node;
		} else {
			this.head.prev = node;
			node.next = this.head;
			this.head = node;
		}
		return node;
	}

	public insertAfter(comparator: (data: T) => boolean, data: T): Node<T> {
		const newNode = new Node(data);
		const node = this.search(comparator);

		if (node === null) {
			throw new Exception('insertAfter node does not exists.');
		}

		newNode.prev = node;
		newNode.next = node.next;

		if (node.next) {
			node.next.prev = newNode;
		}

		node.next = newNode;

		return newNode;
	}

	public deleteNode(node: Node<T>): void {
		if (!node.prev) {
			this.head = node.next;
		} else {
			const prevNode = node.prev;
			prevNode.next = node.next;
		}
	}

	public search(comparator: (data: T) => boolean): Node<T> | null {
		const checkNext = (node: Node<T>): Node<T> | null => {
			if (comparator(node.data)) {
				return node;
			}
			return node.next ? checkNext(node.next) : null;
		};

		return this.head ? checkNext(this.head) : null;
	}

	public traverse(): T[] {
		const array: T[] = [];
		if (!this.head) {
			return array;
		}

		const addToArray = (node: Node<T>): T[] => {
			array.push(node.data);
			return node.next ? addToArray(node.next) : array;
		};
		return addToArray(this.head);
	}

	public indexOf(comparator: (data: T) => boolean): number {
		let index = -1;
		const checkNext = (node: Node<T>): number => {
			index = index + 1;
			if (comparator(node.data)) {
				return index;
			}
			return node.next ? checkNext(node.next) : -1;
		};

		return this.head ? checkNext(this.head) : -1;
	}

	public size(): number {
		return this.traverse().length;
	}

	public json(): any {
		return this.traverse();
	}

	public map<X>(map: MapFuncton<T, X>): X[] {
		return this.traverse().map(map);
	}
}
