import { Language } from '../model/value-objects/Language';
import { Province } from '../model/value-objects/Province';
import PhoneHelper from './PhoneHelper';

export default class BookACallHelper {
	public static buildLink(
		language: Language,
		province: Province | null | undefined,
		email: string,
		name: string,
		phoneNumber: string
	): string {
		return `${this.getBookingURL(
			language,
			province
		)}?email=${encodeURIComponent(email)}&name=${encodeURIComponent(
			name
		)}&phone_number=${PhoneHelper.format(phoneNumber)}`;
	}

	private static getBookingURL(
		language: Language,
		province: Province | null | undefined
	) {
		if (province === Province.Ontario) {
			if (language === Language.Fr) {
				return `https://calendly.com/wiseday-1/mortgage-application-on-fr`;
			} else {
				return `https://calendly.com/wiseday-1/wiseday-on-en`;
			}
		} else if (province === Province.Quebec) {
			if (language === Language.Fr) {
				return `https://calendly.com/wiseday-1/wiseday-qc-fr`;
			} else {
				return `https://calendly.com/wiseday-1/mortgage-application-qc-en`;
			}
		} else {
			return `https://calendly.com/wiseday-1/application-hypothecaire-mortgage-ca`;
		}
	}
}
