import CMHCPremium from './CMHCPremium';

export default class CMHCPremiumHelper {
	public static calculate(
		valueOfProperty: number,
		downPayment: number
	): CMHCPremium {
		const loanAmount = valueOfProperty - downPayment;
		const downPaymentPercentage = downPayment / valueOfProperty;
		const needInsured = downPaymentPercentage < 0.2;

		if (needInsured) {
			if (downPaymentPercentage < 0.1) {
				return new CMHCPremium(0.04, loanAmount * 0.04);
			} else if (downPaymentPercentage < 0.15) {
				return new CMHCPremium(0.031, loanAmount * 0.031);
			} else if (downPaymentPercentage < 0.2) {
				return new CMHCPremium(0.028, loanAmount * 0.028);
			}
		}

		return new CMHCPremium(0, 0);
	}
}
