import { ApplicationType, ExperimentDto, Language } from '@application/common';
import amplitude from 'amplitude-js';
import Cookies from 'js-cookie';
import CookieConsentClient from './CookieConsentClient';
import DataDogClient from './DataDogClient';

export default class AmplitudeClient {
	private static identify: any;
	private static client: AmplitudeClient;
	public static getInstance(): AmplitudeClient {
		if (!this.client) {
			const consentAccepted =
				CookieConsentClient.getConsent() === 'accepted';

			amplitude
				.getInstance()
				.init(process.env.REACT_APP_AMPLITUDE_PUBLIC_ID, null, {
					deferInitialization: !consentAccepted,
					includeUtm: true,
					includeGclid: true,
					includeFbclid: true,
					includeReferrer: true,
					deviceId: Cookies.get('wd-d')
				});
			this.identify = new amplitude.Identify();
			amplitude.getInstance().identify(AmplitudeClient.identify);
			this.client = new AmplitudeClient();

			if (consentAccepted) {
				this.client.enable();
			}
		}

		return this.client;
	}

	public enable() {
		DataDogClient.getInstance().debug('Amplitude enabled');
		amplitude.getInstance().enableTracking();
	}

	public setLanguage(language: Language) {
		amplitude.getInstance().setUserProperties({ AppLanguage: language });
		amplitude.getInstance().logEvent(`change language`, {
			language: language
		});
	}

	public setUserId(id: string | null) {
		amplitude.getInstance().setUserId(id);
	}

	public logExperiment(experiment: ExperimentDto) {
		amplitude.getInstance().logEvent('Experiment', experiment);
	}

	public logEvent(event: string, properties = {}) {
		amplitude.getInstance().logEvent(event, properties);
	}

	public logApplicationEvent(
		type: ApplicationType,
		event: string,
		properties = {}
	) {
		amplitude
			.getInstance()
			.logEvent(`${this.getApplicationType(type)} ${event}`, properties);
	}

	public logStepEvent(
		type: ApplicationType,
		section: string,
		step: string,
		event: string,
		properties?: any
	) {
		amplitude
			.getInstance()
			.logEvent(`${this.getApplicationType(type)}${step} ${event}`, {
				Section: section,
				Step: step,
				event: event,
				...properties
			});
	}

	public logStepShow(type: ApplicationType, section: string, step: string) {
		AmplitudeClient.getInstance().logStepEvent(type, section, step, 'show');
	}

	public logStepSubmit(
		type: ApplicationType,
		section: string,
		step: string,
		answer: any,
		isPII: boolean
	) {
		AmplitudeClient.getInstance().logStepEvent(
			type,
			section,
			step,
			'submit',
			{
				Answer: isPII === false ? answer : '! Hidden PII !'
			}
		);
	}

	public logStepSubmitWithErrors(
		type: ApplicationType,
		section: string,
		step: string,
		errors: any,
		isPII: boolean
	) {
		amplitude
			.getInstance()
			.logEvent(
				`${this.getApplicationType(type)}${step} submit with errors`,
				{
					Section: section,
					Step: step,
					Answer: isPII === false ? errors : '! Hidden PII !'
				}
			);
	}

	private getApplicationType(type: ApplicationType): string {
		return type !== ApplicationType.NewMortgage ? `${type} ` : '';
	}
}
