// use-api.js
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const cookieExists = (name: string): boolean => {
	const match = document.cookie.match(
		RegExp('(?:^|;\\s*)' + name + '=([^;]*)')
	);
	return match ? true : false;
};

export const useCustomAuth = () => {
	const [isTempAuthenticated, setIsTempAuthenticated] = useState<boolean>(
		cookieExists('wisedayLEState')
	);
	const {
		isLoading,
		error,
		isAuthenticated,
		loginWithRedirect,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const { pathname } = useLocation();

	useEffect(() => {
		setIsTempAuthenticated(cookieExists('wisedayLEState'));
	}, [pathname]);

	return {
		isTempAuthenticated,
		isAuthenticated,
		error: error,
		isLoading: isLoading,
		requiresLogin: !isTempAuthenticated && !isAuthenticated,
		loginWithRedirect,
		getAccessTokenSilently,
		logout
	};
};
