import React from 'react';
import styles from './Button.module.scss';
import classnames from 'classnames';
import Loading from 'components/common/loading/Loading';

export enum ButtonType {
	Primary,
	Secondary
}

export enum ButtonSize {
	Big,
	Medium,
	Small
}

interface ButtonProps {
	children: any;
	buttonType?: ButtonType;
	buttonSize?: ButtonSize;
	ref?: any;
	type?: 'button' | 'submit' | 'reset';
	autoFocus?: boolean;
	className?: string;
	isBusy?: boolean;
	disabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function Button(props: ButtonProps): JSX.Element {
	const {
		buttonType,
		buttonSize,
		type,
		ref,
		children,
		className,
		autoFocus,
		disabled,
		isBusy,
		onClick
	} = props;
	let buttonSizeClassName = styles.medium;
	switch (buttonSize) {
		case ButtonSize.Big:
			buttonSizeClassName = styles.big;
			break;
		case ButtonSize.Medium:
			buttonSizeClassName = styles.medium;
			break;
		case ButtonSize.Small:
			buttonSizeClassName = styles.small;
			break;
	}
	return (
		<button
			ref={ref}
			type={type}
			className={classnames(
				styles.button,
				buttonType === ButtonType.Secondary
					? styles.secondary
					: styles.primary,
				buttonSizeClassName,
				isBusy && styles.busy,
				disabled && styles.disabled,
				className
			)}
			autoFocus={autoFocus}
			disabled={disabled || isBusy}
			onClick={onClick}
		>
			{isBusy ? <Loading className={styles.busyIcon} /> : children}
		</button>
	);
}
