export enum IpaResultCode {
	Success = 'S-001',
	SalaryNoData = 'F-001',
	SalaryProvinceUnsupported = 'F-002',
	SalaryNoCurrentAddress = 'F-003',
	NoFicoScore = 'F-011',
	FicoScoreTooLow = 'F-012',
	MortgageAmountTooLow = 'F-031',
	HasBankruptcy = 'F-41'
}
