import { CustomerDto } from '@application/common';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AmplitudeClient from 'services/AmplitudeClient';
import HttpClient from 'services/HttpClient';
import { useLanguageContext } from '../context/LanguageProvider';
import ExitDialog from '../dialog/ExitDialog';
import { useCustomAuth } from '../hooks/useCustomAuth';

export interface IUserContext {
	isLoading: boolean;
	user: CustomerDto | undefined;
	userExit: () => Promise<boolean>;
}

const defaultState = {
	isLoading: false,
	user: undefined,
	userExit: (): Promise<boolean> => {
		return Promise.resolve(true);
	}
};

export const UserContext = React.createContext<IUserContext>(defaultState);

export function useUserContext(): IUserContext {
	return useContext(UserContext);
}

type ExitDialogState = {
	isOpen: boolean;
	resolve: any;
	reject: any;
};

export default function UserContextProvider({ children }): JSX.Element {
	const [state, setState] = useState<IUserContext>(defaultState);
	const [exitDialogState, setExitDialogState] = useState<ExitDialogState>({
		isOpen: false,
		resolve: undefined,
		reject: undefined
	});
	const {
		isLoading,
		isAuthenticated,
		isTempAuthenticated,
		getAccessTokenSilently,
		logout
	} = useCustomAuth();
	const { language } = useLanguageContext();

	const userExit = useCallback(async (): Promise<boolean> => {
		const promise = new Promise((resolve, reject) => {
			setExitDialogState({
				isOpen: true,
				resolve: resolve,
				reject: reject
			});
		});

		return promise.then(
			() => {
				setExitDialogState({
					...exitDialogState,
					isOpen: false
				});
				return true;
			},
			() => {
				setExitDialogState({
					...exitDialogState,
					isOpen: false
				});
				return false;
			}
		);
	}, [exitDialogState, setExitDialogState]);

	useEffect(() => {
		if (isAuthenticated || isTempAuthenticated) {
			const getUser = async (): Promise<void> => {
				try {
					setState({
						...state,
						isLoading: true
					});
					const accessToken = !isTempAuthenticated
						? await getAccessTokenSilently()
						: null;
					const authorizationHeader =
						accessToken != null
							? {
									Authorization: `Bearer ${accessToken}`
							  }
							: undefined;
					const headers: HeadersInit = {
						'Content-Type': 'application/json',
						'Accept-Language': language,
						...authorizationHeader
					};
					const fetchOptions: RequestInit = {
						method: 'GET',
						credentials: 'include',
						headers: headers
					};
					const response = await HttpClient.fetch(
						'/api/customer/user',
						fetchOptions
					);
					const body = await response.json();
					setState({
						...state,
						isLoading: false,
						user: new CustomerDto(
							body.id,
							body.firstName,
							body.lastName,
							body.email,
							body.mobilePhone,
							body.province
						),
						userExit: userExit
					});
				} catch (ex: any) {
					setState({
						...state,
						isLoading: false,
						user: undefined,
						userExit: userExit
					});
				}
			};

			getUser();
		}
	}, [isLoading, isTempAuthenticated, isAuthenticated]);

	if (state.isLoading) {
		return <></>;
	}

	return (
		<UserContext.Provider value={state}>
			{children}
			{exitDialogState.isOpen && (
				<ExitDialog
					onCancel={() => {
						AmplitudeClient.getInstance().logEvent(
							'app-exit-cancel'
						);
						exitDialogState.reject();
					}}
					onExit={() => {
						{
							AmplitudeClient.getInstance().logEvent(
								'app-exit-confirm'
							);
							if (isAuthenticated || isTempAuthenticated) {
								logout({
									returnTo: `${process.env.REACT_APP_APPLICATION_ROOT_URL}/api/account/logout`
								});
							} else {
								exitDialogState.resolve();
							}
						}
					}}
				/>
			)}
		</UserContext.Provider>
	);
}
