import Customer from '../model/customer/Customer';
import { Language } from '../model/value-objects/Language';
import { createUUID } from '../model/value-objects/UUID';

export default class CustomerUnitTestHelper {
	public static createCustomer(updates?: any): Customer {
		return new Customer({
			id: createUUID(),
			email: 'borrowwer-test@test.io',
			firstName: 'John',
			lastName: 'The Doe',
			language: Language.Fr,
			mobilePhone: '15674140011',
			homePhone: '15674140011',
			isEmailConfirmed: false,
			hasAcceptedCreditTerms: false,
			acceptedCreditTermsDate: null,
			...updates
		});
	}
}
