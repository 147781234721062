import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import AmplitudeClient from './AmplitudeClient';
import CookieConsentClient from './CookieConsentClient';

export default class DataDogClient {
	private static clientInitialized: boolean;
	public static getInstance(): DataDogClient {
		if (!this.clientInitialized) {
			const consentAccepted =
				CookieConsentClient.getConsent() === 'accepted';

			const clientKey = `${process.env.REACT_APP_DATADOG_RUM_PUBLIC_ID}`;
			if (clientKey.length > 0) {
				const commonConfig = {
					clientToken: clientKey,
					env: `${process.env.REACT_APP_ENV_PUBLIC}`,
					site: 'datadoghq.com',
					service: 'wiseday-application',
					sampleRate: 100,
					trackSessionAcrossSubdomains: true
				};
				datadogLogs.init({
					...commonConfig,
					forwardErrorsToLogs: true,
					forwardReports: ['csp_violation']
				});
				if (consentAccepted) {
					datadogRum.init({
						...commonConfig,
						applicationId: 'b4c6db6a-80cf-4029-aed8-abbce5d1ee29',
						premiumSampleRate: 100,
						trackInteractions: true,
						defaultPrivacyLevel: 'mask-user-input',
						enableExperimentalFeatures: ['clickmap']
					});
					datadogRum.startSessionReplayRecording();
				}
				this.clientInitialized = true;

				if (consentAccepted) {
					const sessionId =
						datadogRum.getInternalContext()?.session_id;
					const sessionURL = `https://app.datadoghq.com/rum/explorer?query=%40session.id%3A${sessionId}`;
					AmplitudeClient.getInstance().logEvent('DataDog', {
						sessionURL: sessionURL
					});
				}
			} else {
				// eslint-disable-next-line no-console
				console.warn('DataDogClient is not enabled');
			}
		}

		return new DataDogClient();
	}

	public setUserId(id: string | null, properties: any = {}) {
		if (DataDogClient.clientInitialized) {
			datadogRum.setUser({
				id: id ?? '',
				...properties
			});
		} else {
			// eslint-disable-next-line no-console
			console.warn('DataDogClient is not initialized or enabled');
		}
	}

	public debug(message: string, properties?: any | undefined) {
		if (DataDogClient.clientInitialized) {
			datadogLogs.logger.debug(message, properties);
		} else {
			// eslint-disable-next-line no-console
			console.debug(message, properties);
		}
	}

	public info(message: string, properties?: any | undefined) {
		if (DataDogClient.clientInitialized) {
			datadogLogs.logger.info(message, properties);
		} else {
			// eslint-disable-next-line no-console
			console.info(message, properties);
		}
	}

	public warn(message: string, properties?: any | undefined) {
		if (DataDogClient.clientInitialized) {
			datadogLogs.logger.warn(message, properties);
		} else {
			// eslint-disable-next-line no-console
			console.warn(message, properties);
		}
	}

	public error(message: string, properties?: any | undefined) {
		if (DataDogClient.clientInitialized) {
			datadogLogs.logger.error(message, properties);
		} else {
			// eslint-disable-next-line no-console
			console.error(message, properties);
		}
	}
}
