export default class CurrencyHelper {
	public static formatCurrency(
		value: number,
		zeroValue = '',
		showPennies = true,
		currencySign = true
	): string {
		return value > 0
			? new Intl.NumberFormat('en-CA', {
					style: 'currency',
					currency: 'CAD',
					minimumFractionDigits: 0,
					maximumFractionDigits: showPennies ? 2 : 0
			  })
					.format(value)
					.slice(currencySign ? 0 : 1)
			: zeroValue;
	}
}
