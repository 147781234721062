export default class PIIHelper {
	public static replacePii(
		value: any | null | undefined
	): string | null | undefined {
		if (value === null || value === undefined) {
			return value;
		}

		if (typeof value === 'string') {
			return value.replaceAll(/./g, '*');
		}

		return null;
	}

	public static replaceEmailPii(
		value: string | null | undefined
	): string | null | undefined {
		if (value === null || value === undefined) {
			return value;
		}

		const parts = value.split('@');
		if (parts.length === 2) {
			return `${this.replacePii(parts[0])}@${parts[1]}`;
		} else {
			return this.replacePii(value);
		}
	}

	public static replacePiiPropertyForAllDescendant(
		propertyNames: Array<string>,
		object: any
	) {
		this.doActionOnPropertyForAllDescendant(
			propertyNames,
			object,
			(object: any, propertyName: string) => {
				object[propertyName] = PIIHelper.replacePii(
					object[propertyName]
				);
			}
		);
	}

	public static replaceEmailPiiPropertyForAllDescendant(
		propertyNames: Array<string>,
		object: any
	) {
		this.doActionOnPropertyForAllDescendant(
			propertyNames,
			object,
			(object: any, propertyName: string) => {
				object[propertyName] = PIIHelper.replaceEmailPii(
					object[propertyName]
				);
			}
		);
	}

	public static deletePropertyForAllDescendant(
		propertyNames: Array<string>,
		object: any
	) {
		this.doActionOnPropertyForAllDescendant(
			propertyNames,
			object,
			(object: any, propertyName: string) => {
				delete object[propertyName];
			}
		);
	}

	private static doActionOnPropertyForAllDescendant(
		propertyNames: Array<string>,
		object: any,
		action: (object: any, propertyName: string) => void
	) {
		for (const property in object) {
			if (Object.prototype.hasOwnProperty.call(object, property)) {
				if (propertyNames.includes(property)) {
					action(object, property);
					continue;
				}
				if (typeof object[property] == 'object') {
					if (object[property] instanceof Array) {
						object[property].forEach((element) => {
							this.doActionOnPropertyForAllDescendant(
								propertyNames,
								element,
								action
							);
						});
					} else {
						this.doActionOnPropertyForAllDescendant(
							propertyNames,
							object[property],
							action
						);
					}
				}
			}
		}
	}
}
