import { FormError } from '@application/common';
import Alert, { AlertType } from 'components/common/alerts/Alert';
import Button from 'components/common/buttons/button/Button';
import Card from 'components/common/card/Card';
import { useCallPostApi } from 'components/common/hooks/useCallApi';
import Form from 'components/common/inputs/form/Form';
import SelectInput, {
	SelectOption
} from 'components/common/inputs/selectInput/SelectInput';
import TextInputArea from 'components/common/inputs/textInput/TextAreaInput';
import TextInput from 'components/common/inputs/textInput/TextInput';
import Page from 'components/common/page/Page';
import Toast from 'components/common/toast/Toast';
import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import baseStyles from './UnsubscribeRouter.module.scss';

export default function UnsubscribeRequest() {
	const { t } = useTranslation();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [searchParams] = useSearchParams();
	const { isBusy, callPostApi } = useCallPostApi(false);
	const [formErrors, setFormErrors] = useState<Record<string, FormError>>({});
	const [success, setSuccess] = useState(false);
	const [reason, setReason] = useState('');
	const [reasonDesc, setReasonDesc] = useState('');
	const [alert, setAlert] = useState({ open: false, message: '' });
	const email = searchParams.get('email');
	const unsubscribeToken = searchParams.get('unsubscribeToken');
	const submit = () => {
		buttonRef.current?.click();
	};
	const handleSubmit = async (
		e: FormEvent<HTMLFormElement>
	): Promise<void> => {
		setFormErrors({});
		const formData = new FormData(e.currentTarget);
		const formJSON = Object.fromEntries(formData.entries());
		const response = await callPostApi(
			`/api/newsletter/unsubscribe`,
			formJSON
		);
		if (response.status === 200) {
			setSuccess(true);
		} else if (response.status === 400) {
			if (response.data.errors) {
				setFormErrors(response.data.errors ?? []);
			} else {
				setAlert({ open: true, message: response.data });
			}
		} else {
			setAlert({ open: true, message: t('common:api-error-toast') });
		}
	};

	const reasons: Array<SelectOption> = [];
	const addReason = (reasonId: string) => {
		reasons.push({
			label: t(`unsubscribe:unsubscribe-apply.reasons.${reasonId}`),
			value: reasonId
		});
	};
	addReason('difficulty-website');
	addReason('too-many-emails');
	addReason('difficulty-email');
	addReason('not-satisfied');
	addReason('non-relevant');
	addReason('did-not-subscribe');
	addReason('no-longer-interested');
	addReason('other');

	return (
		<Page
			title={t('unsubscribe:unsubscribe-apply.page-title')}
			className={baseStyles.container}
		>
			<Card className={baseStyles.card}>
				<h1 className={baseStyles.title}>
					{t(`unsubscribe:unsubscribe-apply.title`)}
				</h1>
				<p className={baseStyles.text}>
					{t(`unsubscribe:unsubscribe-apply.details-1`)}
				</p>
				<p className={baseStyles.text}>
					{t(`unsubscribe:unsubscribe-apply.details-2`)}
				</p>
				<Form submitButtonRef={buttonRef} handleSubmit={handleSubmit}>
					<fieldset className={baseStyles.fieldset}>
						<input
							id="unsubscribeToken"
							name="unsubscribeToken"
							readOnly={true}
							hidden={true}
							value={unsubscribeToken ?? ''}
						/>
						<SelectInput
							isPublic
							autoFocus
							name="reason"
							value={reason}
							error={formErrors['reason']?.msg}
							label={t(
								'unsubscribe:unsubscribe-apply.reason.label'
							)}
							placeholder={t(
								'unsubscribe:unsubscribe-apply.reason.placeholder'
							)}
							options={reasons}
							onChange={(e) => setReason(e?.value ?? '')}
						/>
						<TextInputArea
							className={baseStyles.textarea}
							type="textarea"
							name="reasonDesc"
							error={formErrors['reasonDesc']?.msg}
							value={reasonDesc}
							maxLength={500}
							label={t(
								'unsubscribe:unsubscribe-apply.reason-desc.label'
							)}
							placeholder={t(
								'unsubscribe:unsubscribe-apply.reason-desc.placeholder'
							)}
							onChange={(e) => setReasonDesc(e.target.value)}
						/>
						<TextInput
							type="email"
							name="email"
							value={email ?? ''}
							error={formErrors['email']?.msg}
							locked={true}
							label={t(
								'unsubscribe:unsubscribe-apply.email.label'
							)}
						/>
						{success ? (
							<Alert showIcon type={AlertType.Success}>
								{t(
									'unsubscribe:unsubscribe-apply.success-message'
								)}
							</Alert>
						) : (
							<Button
								isBusy={isBusy}
								disabled={
									!email || !reason || !unsubscribeToken
								}
								onClick={() => submit()}
							>
								{t('unsubscribe:unsubscribe-apply.confirm')}
							</Button>
						)}
					</fieldset>
				</Form>
				<Toast
					open={alert.open}
					autoHideDuration={5000}
					onClose={() => setAlert({ ...alert, open: false })}
				>
					<Alert showIcon type={AlertType.Error}>
						{alert.message}
					</Alert>
				</Toast>
			</Card>
		</Page>
	);
}
