import { DateHelper, Dictionary, FeatureFlags } from '@application/common';
import DataDogClient from './DataDogClient';
import HttpClient from './HttpClient';

export default class FeatureFlagClient {
	private static instance: FeatureFlagClient;
	private static lastRefresh: Date;
	private static readonly featureFlags: Dictionary<boolean> =
		new Dictionary<boolean>();

	public static getInstance(): FeatureFlagClient {
		if (!FeatureFlagClient.instance) {
			FeatureFlagClient.instance = new FeatureFlagClient();
		}
		if (
			!FeatureFlagClient.lastRefresh ||
			FeatureFlagClient.lastRefresh <=
				DateHelper.addMinutes(new Date(), -1)
		) {
			FeatureFlagClient.lastRefresh = new Date();
			FeatureFlagClient.instance.refresh();
		}
		return FeatureFlagClient.instance;
	}

	public async refresh(): Promise<void> {
		let result = await this.getFeatureFlag(
			FeatureFlags.IsFacebookEnabled,
			true
		);
		FeatureFlagClient.featureFlags.set(
			FeatureFlags.IsFacebookEnabled,
			result
		);
		result = await this.getFeatureFlag(FeatureFlags.IsGoogleEnabled, true);
		FeatureFlagClient.featureFlags.set(
			FeatureFlags.IsGoogleEnabled,
			result
		);
	}

	public getFlag(flag: FeatureFlags): boolean | undefined {
		return FeatureFlagClient.featureFlags.get(flag);
	}

	private async getFeatureFlag(
		flag: FeatureFlags,
		defaultValue: boolean
	): Promise<boolean> {
		let result = defaultValue;
		try {
			const response = await HttpClient.fetch(
				`/api/featureflag/${flag}`,
				{
					method: 'GET'
				}
			);

			if (response.ok) {
				const json = await response.json();
				result = json.value;
			}
		} catch (e) {
			DataDogClient.getInstance().error('Failed to retrieve FF', e);
		}
		return result;
	}
}
