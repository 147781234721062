import { StepSectionType } from '@application/common';
import ErrorPage from 'components/common/page/ErrorPage';
import FullPage from 'components/common/page/FullPage';
import { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AmplitudeClient from 'services/AmplitudeClient';
import DataDogClient from 'services/DataDogClient';
import FacebookPixelClient from 'services/FacebookPixelClient';
import GoogleTagClient from 'services/GoogleTagClient';
import LoginRedirect from './common/login/LoginRedirect';
import { useUserContext } from './common/login/UserContextProvider';
import GetStarted from './get-started/GetStarted';
import UnsubscribeRouter from './unsubscribe/UnsubscribeRouter';

// const SignupRouter = lazy(() => import('components/pre-qual/SignupRouter'));
const NewMortgageRouter = lazy(
	() => import('components/pre-qual/NewMortgageRouter')
);
// const NewMortgageIpaRouter = lazy(
// 	() => import('components/pre-qual/NewMortgageIpaRouter')
// );
// const CoApplicantNewMortgageIpaRouter = lazy(
// 	() => import('components/pre-qual/CoApplicantNewMortgageIpaRouter')
// );
// TODO: Re-enable once we have Mortgage/Refinance in Truss
// const RefinanceRouter = lazy(
// 	() => import('components/pre-qual/RefinanceRouter')
// );
const RenewalRouter = lazy(() => import('components/pre-qual/RenewalRouter'));
// const AccountRouter = lazy(() => import('components/account/AccountRouter'));
// const GetApproval = lazy(() => import('components/get-approval/GetApproval'));
// const IpaMainApplicantRouter = lazy(
// 	() =>
// 		import(
// 			'components/instant-pre-approval/main-applicant/IpaMainApplicantRouter'
// 		)
// );
// const IpaCoApplicantRouter = lazy(
// 	() =>
// 		import(
// 			'components/instant-pre-approval/co-applicant/IpaCoApplicantRouter'
// 		)
// );

export default function AppRouter() {
	const { user } = useUserContext();
	useEffect(() => {
		AmplitudeClient.getInstance().setUserId(user?.id ?? null);
		DataDogClient.getInstance().setUserId(user?.id ?? null);
		FacebookPixelClient.init(user?.id);
		GoogleTagClient.setUserId(user?.id);
	}, [user]);
	return (
		<Routes>
			<Route
				path="*"
				element={
					<FullPage>
						<ErrorPage />
					</FullPage>
				}
			/>
			<Route path="/login" element={<LoginRedirect />} />
			<Route path="/:lang/login" element={<LoginRedirect />} />
			<Route path="/:lang/account/*" element={<LoginRedirect />} />
			{/* 
			// Disabled because we dont do IPA anynore in B2B
			<Route path="/loginCallback" element={<LoginCallback />} />
			<Route path={`/:lang/signup/*`} element={<SignupRouter />} /> */}
			<Route
				path={`/:lang/${StepSectionType.NewMortgage}/*`}
				element={<NewMortgageRouter />}
			/>
			{/* <Route
				// Disabled because we dont do IPA anynore in B2B
				path={`/:lang/${StepSectionType.NewMortgageIpa}/*`}
				element={<NewMortgageIpaRouter />}
			/> 
			<Route
				path={`/:lang/${StepSectionType.CoApplicantNewMortgageIpa}/*`}
				element={
					<IpaMainApplicantProvider>
						<CoApplicantNewMortgageIpaRouter />
					</IpaMainApplicantProvider>
				}
			/>
			*/}
			{/* 
            // TODO: Re-enable once we have Mortgage/Refinance in Truss
			<Route
				path={`/:lang/${StepSectionType.Refinance}/*`}
				element={<RefinanceRouter />}
			/>
             */}
			<Route
				path={`/:lang/${StepSectionType.Renewal}/*`}
				element={<RenewalRouter />}
			/>
			<Route path="/:lang/get-started" element={<GetStarted />} />
			{/* // Disabled almost all routes because we dont do IPA anynore in B2B 
			<Route
				path="/:lang/account/*"
				element={
					<RequiredAuth>
						<AccountRouter />
					</RequiredAuth>
				}
			/>
			<Route
				path="/:lang/get-approval/:applicationId/:section/:step/:instanceId"
				element={
					<RequiredAuth>
						<GetApproval />
					</RequiredAuth>
				}
			/>
			<Route
				path="/:lang/get-approval/:applicationId/:section/:step"
				element={
					<RequiredAuth>
						<GetApproval />
					</RequiredAuth>
				}
			/>
			<Route
				path="/:lang/get-approval/:applicationId/"
				element={
					<RequiredAuth>
						<GetApproval />
					</RequiredAuth>
				}
			/>
			<Route
				path="/:lang/document-upload/:applicationId/reset-skipped"
				element={
					<RequiredAuth>
						<ResetSkippedDocuments />
					</RequiredAuth>
				}
			/>
			<Route
				path={`/:lang/${IpaUserType.MainApplicant}/instant-pre-approval/:applicationId/*`}
				element={
					<RequiredAuth>
						<ApplicationProvider>
							<IpaMainApplicantRouter />
						</ApplicationProvider>
					</RequiredAuth>
				}
			/>
			<Route
				path={`/:lang/${IpaUserType.CoApplicant}/instant-pre-approval/:applicationId/*`}
				element={
					<RequiredAuth>
						<IpaCoApplicantApplicationProvider>
							<IpaCoApplicantRouter />
						</IpaCoApplicantApplicationProvider>
					</RequiredAuth>
				}
			/>
			<Route
				path={`/:lang/${IpaUserType.MainApplicant}/completed/:applicationId/*`}
				element={
					<RequiredAuth>
						<ApplicationProvider>
							<CompletedRouter />
						</ApplicationProvider>
					</RequiredAuth>
				}
			/>
			<Route
				path={`/:lang/${IpaUserType.CoApplicant}/completed/:applicationId/*`}
				element={
					<RequiredAuth>
						<ApplicationProvider>
							<CompletedRouter />
						</ApplicationProvider>
					</RequiredAuth>
				}
			/>
			<Route
				path={`/:lang/completed/:applicationId/*`}
				element={
					<RequiredAuth>
						<ApplicationProvider>
							<CompletedRouter />
						</ApplicationProvider>
					</RequiredAuth>
				}
			/> */}
			<Route
				path="/:lang/unsubscribe/*"
				element={<UnsubscribeRouter />}
			/>
		</Routes>
	);
}
