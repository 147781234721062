import { useEffect } from 'react';

export const updateDocumentHeight = () => {
	if (typeof visualViewport !== 'undefined') {
		document.documentElement.style.setProperty(
			'--viewport-height',
			`${visualViewport?.height}px`
		);
	}
};

export default function useViewportHeight() {
	useEffect(() => {
		if (typeof visualViewport !== 'undefined') {
			updateDocumentHeight();
			visualViewport?.addEventListener('resize', () => {
				updateDocumentHeight();
			});
			return () =>
				visualViewport?.removeEventListener('resize', () => {
					// do nothing
				});
		}
	}, []);
}
