import ArgumentException from '../../exceptions/ArgumentException';
import Exception from '../../exceptions/Exception';
import BaseApplication from '../application/BaseApplication';
import BaseEntity from '../common/BaseEntity';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import { ApplicationType } from '../value-objects/ApplicationType';
import { DocumentStateType } from '../value-objects/DocumentStateType';
import { DocumentType } from '../value-objects/DocumentType';
import { IncomeType } from '../value-objects/IncomeType';
import { UUID } from '../value-objects/UUID';
import ICoApplicant from './ICoApplicant';
import ICoApplicantDocuments from './ICoApplicantDocuments';

export interface ICoApplicantDocumentItem {
	documentType: DocumentType;
	state: DocumentStateType;
}
export default class CoApplicantDocuments
	extends BaseEntity
	implements ICoApplicantDocuments
{
	public readonly applicationId: UUID;
	public readonly coApplicantId: UUID;
	public readonly isStarted: boolean;
	public readonly photoId: DocumentStateType;
	public readonly payStub: DocumentStateType;
	public readonly T4: DocumentStateType;
	public readonly T4A: DocumentStateType;
	public readonly T1: DocumentStateType;
	public readonly NOA: DocumentStateType;
	public readonly bankStatement90Days: DocumentStateType;
	public readonly employmentLetter: DocumentStateType;
	public readonly secondaryId: DocumentStateType;

	constructor(input: ICoApplicantDocuments) {
		super({ entityType: EntityTypeEnum.CoApplicantDocuments });
		this.applicationId = input.applicationId;
		this.coApplicantId = input.coApplicantId;
		this.isStarted = input.isStarted;
		this.photoId = input.photoId;
		this.payStub = input.payStub;
		this.T4 = input.T4;
		this.T4A = input.T4A;
		this.T1 = input.T1;
		this.NOA = input.NOA;
		this.bankStatement90Days = input.bankStatement90Days;
		this.employmentLetter = input.employmentLetter;
		this.secondaryId = input.secondaryId;
	}

	public getState(documentType: DocumentType): DocumentStateType {
		const fieldName = CoApplicantDocuments.getFieldName(documentType);
		return this[fieldName];
	}

	public list(): Array<ICoApplicantDocumentItem> {
		return [
			DocumentType.PhotoId,
			DocumentType.PayStub,
			DocumentType.T4,
			DocumentType.T4A,
			DocumentType.T1,
			DocumentType.NOA,
			DocumentType.BankStatement90Days,
			DocumentType.EmploymentLetter,
			DocumentType.SecondaryId
		].map((t) => {
			return {
				documentType: t,
				state: this.getState(t)
			};
		});
	}

	public listMissing(application: BaseApplication): Array<DocumentType> {
		const incomeType = application.getCoApplicant()?.incomeType;

		if (incomeType === undefined) {
			throw new Exception(
				'CoApplicantDocuments.listMissing. Current application has no current income type.'
			);
		}

		const documentsNeeded = CoApplicantDocuments.getNeededDocuments(
			application.applicationType,
			incomeType
		);
		return documentsNeeded.filter(
			(d) => this.getState(d) !== DocumentStateType.Received
		);
	}

	public static create(
		applicationType: ApplicationType,
		coApplicant: ICoApplicant
	): CoApplicantDocuments {
		const oldDocuments = coApplicant.documents;
		let result = new CoApplicantDocuments({
			coApplicantId: coApplicant.id,
			applicationId: coApplicant.applicationId,
			isStarted: oldDocuments?.isStarted ?? false
		} as ICoApplicantDocuments);

		Object.values(DocumentType).forEach((t) => {
			result = CoApplicantDocuments.update(
				result,
				t,
				CoApplicantDocuments.getDefaultState(
					t,
					coApplicant.incomeType,
					applicationType,
					oldDocuments
				)
			);
		});

		return result;
	}

	public static update(
		documents: ICoApplicantDocuments | undefined,
		documentType: DocumentType,
		state: DocumentStateType
	): CoApplicantDocuments {
		const fieldName = CoApplicantDocuments.getFieldName(documentType);

		return new CoApplicantDocuments({
			...documents,
			[fieldName]: state
		} as ICoApplicantDocuments);
	}

	public static getNeededDocuments(
		applicationType: ApplicationType,
		incomeType: IncomeType | null
	): Array<DocumentType> {
		let result: Array<DocumentType> = [];
		if (incomeType === null) {
			return result;
		}

		if (incomeType === IncomeType.SelfEmployed) {
			result = [DocumentType.PhotoId, DocumentType.T1, DocumentType.NOA];
		} else if (
			incomeType === IncomeType.None ||
			incomeType === IncomeType.Pension
		) {
			result = [DocumentType.PhotoId, DocumentType.T4A, DocumentType.NOA];
		} else {
			result = [
				DocumentType.PhotoId,
				DocumentType.PayStub,
				DocumentType.T4,
				DocumentType.EmploymentLetter
			];
		}

		result.push(DocumentType.BankStatement90Days);
		result.push(DocumentType.SecondaryId);

		return result;
	}

	public static isDocumentApplicable(
		application: BaseApplication,
		documentType: DocumentType
	): boolean {
		const incomeType = application.getCoApplicant()?.incomeType ?? null;
		return this.getNeededDocuments(
			application.applicationType,
			incomeType
		).includes(documentType);
	}

	public static getDefaultState(
		documentType: DocumentType,
		incomeType: IncomeType | null,
		applicationType: ApplicationType,
		oldDocuments?: ICoApplicantDocuments
	): DocumentStateType {
		if (incomeType === null) {
			throw new ArgumentException('incomeType', 'null');
		}
		const requestedDocuments = this.getNeededDocuments(
			applicationType,
			incomeType
		);
		const newState = requestedDocuments.includes(documentType)
			? DocumentStateType.Requested
			: DocumentStateType.NotRequested;
		const oldCustomerDocuments = oldDocuments
			? new CoApplicantDocuments(oldDocuments)
			: undefined;

		return CoApplicantDocuments.getDocumentState(
			oldCustomerDocuments?.getState(documentType),
			newState
		);
	}

	public static getFieldName(documentType: DocumentType): string {
		if (!Object.values(DocumentType).find((x) => x === documentType)) {
			throw new ArgumentException('documentType', documentType);
		}

		let nextIsCapitalized = false;
		return (documentType as string).split('').reduce((p, c) => {
			if (c === '-') {
				nextIsCapitalized = true;
			} else {
				p += nextIsCapitalized ? c.toLocaleUpperCase() : c;
				nextIsCapitalized = false;
			}
			return p;
		});
	}

	private static getDocumentState(
		existingState: DocumentStateType | undefined,
		newState: DocumentStateType
	): DocumentStateType {
		return existingState === DocumentStateType.Received
			? DocumentStateType.Received
			: newState;
	}
}
