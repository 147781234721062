import classnames from 'classnames';
import styles from './Loading.module.scss';

export type LoadingProps = {
	className?: string;
};

export default function Loading(props: LoadingProps) {
	const { className } = props;
	return (
		<div className={classnames(styles.loading, className)}>
			<svg
				version="1.1"
				id="L4"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 40 12"
				enableBackground="new 0 0 0 0"
				xmlSpace="preserve"
			>
				<circle fill="currentColor" stroke="none" cx="6" cy="6" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.1"
					/>
				</circle>
				<circle fill="currentColor" stroke="none" cx="20" cy="6" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.2"
					/>
				</circle>
				<circle fill="currentColor" stroke="none" cx="34" cy="6" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.3"
					/>
				</circle>
			</svg>
		</div>
	);
}
