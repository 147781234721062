import { UUID, createUUID } from '../value-objects/UUID';

export interface IAddress {
	readonly id: UUID;
	readonly isCurrent: boolean;
	readonly addressLine1: string | null;
	readonly addressLine2: string | null;
	readonly city: string | null;
	readonly province: string | null;
	readonly country: string | null;
	readonly postalCode: string | null;
	readonly years: number | null;
	readonly months: number | null;
}

export default class Address implements IAddress {
	public readonly id: UUID;
	public readonly isCurrent: boolean;
	public readonly addressLine1: string | null;
	public readonly addressLine2: string | null;
	public readonly city: string | null;
	public readonly province: string | null;
	public readonly country: string | null;
	public readonly postalCode: string | null;
	public readonly years: number | null;
	public readonly months: number | null;

	constructor(input: IAddress) {
		this.id = input.id;
		this.isCurrent = input.isCurrent;
		this.addressLine1 = input.addressLine1;
		this.addressLine2 = input.addressLine2;
		this.city = input.city;
		this.province = input.province;
		this.country = input.country;
		this.postalCode = input.postalCode;
		this.years = input.years;
		this.months = input.months;
	}

	public getCivicNumber(): string {
		if (this.addressLine1) {
			const parts = this.addressLine1.split(' ');
			return Number.isInteger(parseInt(parts[0][0])) ? parts[0] : '';
		}
		return '';
	}

	public getStreetName(): string {
		if (this.addressLine1) {
			const parts = this.addressLine1.split(' ');
			if (Number.isInteger(parseInt(parts[0][0]))) {
				return parts.slice(1).join(' ');
			} else {
				return this.addressLine1;
			}
		}
		return '';
	}

	public getTotalMonths(): number {
		return (this.years ?? 0) * 12 + (this.months ?? 0);
	}

	public toString(): string {
		const parts = [
			this.addressLine1,
			this.city,
			this.province,
			this.country,
			this.postalCode
		];
		const filteredParts = parts.filter(
			(part) => part !== null && part !== ''
		);
		return filteredParts.join(', ');
	}

	public static create(instance?: Partial<IAddress>): Address {
		return new Address({
			id: instance?.id ?? createUUID(),
			isCurrent: instance?.isCurrent ?? false,
			addressLine1: instance?.addressLine1 ?? null,
			addressLine2: instance?.addressLine2 ?? null,
			city: instance?.city ?? null,
			province: instance?.province ?? null,
			postalCode: instance?.postalCode ?? null,
			country: instance?.country ?? null,
			years: instance?.years ?? null,
			months: instance?.months ?? null
		});
	}

	public static isAddressTotalMonthsOk(customerAddresses: Array<Address>) {
		const totalYears = customerAddresses
			.map((address) => address.years ?? 0)
			.reduce((result, v) => result + v, 0);
		const totalMonths = customerAddresses
			.map((address) => address.months ?? 0)
			.reduce((result, v) => result + v, 0);
		return totalYears * 12 + totalMonths >= 36;
	}
}
