import Header from 'components/common/header/Header';
import layoutStyles from '../layout.module.scss';
import WisedayHomeLink from 'components/common/links/WisedayHomeLink';
import ErrorPage from 'components/common/page/ErrorPage';
import { Route, Routes } from 'react-router-dom';
import Unsubscribe from './Unsubscribe';
import UnsubscribeApply from './UnsubscribeApply';
import { useCustomAuth } from 'components/common/hooks/useCustomAuth';

export default function GetRateRouter() {
	const { isAuthenticated, isTempAuthenticated } = useCustomAuth();
	return (
		<main className={layoutStyles.layout}>
			<header className={layoutStyles.header}>
				<Header
					rightSideContent={<WisedayHomeLink />}
					showAccountMenu={isAuthenticated || isTempAuthenticated}
				/>
			</header>
			<section id="content" className={layoutStyles.content}>
				<Routes>
					<Route path="*" element={<ErrorPage />} />
					<Route path="" element={<Unsubscribe />} />
					<Route path="apply" element={<UnsubscribeApply />} />
				</Routes>
			</section>
		</main>
	);
}
