import { ApplicationType } from '../../value-objects/ApplicationType';
import IIpaCoApplicantApplicationDto, {
	IIpaCoApplicantApplicationDetailsDto
} from './IIpaCoApplicantApplicationDto';
import IIpaCoApplicantMainApplicantDto from './IIpaCoApplicantMainApplicantDto';

export default class IpaCoApplicantApplicationDto
	implements IIpaCoApplicantApplicationDto
{
	public readonly applicationType: ApplicationType;
	public readonly mainApplicant: IIpaCoApplicantMainApplicantDto;
	public readonly currentApplicationDetails: IIpaCoApplicantApplicationDetailsDto;
	public readonly requestedApplicationDetails: IIpaCoApplicantApplicationDetailsDto;
	public readonly newApplicationDetails: IIpaCoApplicantApplicationDetailsDto;
	public readonly newMortgageAmountDifference: number;

	constructor(input: IIpaCoApplicantApplicationDto) {
		this.applicationType = input.applicationType;
		this.mainApplicant = input.mainApplicant;
		this.currentApplicationDetails = input.currentApplicationDetails;
		this.requestedApplicationDetails = input.requestedApplicationDetails;
		this.newApplicationDetails = input.newApplicationDetails;
		this.newMortgageAmountDifference = input.newMortgageAmountDifference;
	}
}
