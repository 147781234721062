import Exception from '../../exceptions/Exception';
import { LiabilitySource } from '../value-objects/LiabilitySource';
import { LiabilityType } from '../value-objects/LiabilityType';
import { Payoff } from '../value-objects/Payoff';
import { UUID, createUUID } from '../value-objects/UUID';
import ILiability from './ILiability';

export default class Liability implements ILiability {
	public readonly id: UUID;
	public readonly type: LiabilityType;
	public readonly source: LiabilitySource;
	public readonly balance: number | null;
	public readonly limit: number | null;
	public readonly maturityDate: Date | null;
	public readonly payment: number | null;
	public readonly payoff: Payoff | null;
	public readonly details: any | null;

	constructor(input: ILiability) {
		this.id = input.id || createUUID();
		this.type = input.type;
		this.source = input.source;
		this.balance = input.balance;
		this.limit = input.limit;
		this.maturityDate = input.maturityDate;
		this.payment = input.payment;
		this.payoff = input.payoff;
		this.details = input.details;
	}

	public getAnnualAmount(): number {
		return this.getAmount() * 12;
	}

	public setAmount(value: number): void {
		switch (this.type) {
			case LiabilityType.LineOfCredit:
			case LiabilityType.LineOfCreditInsecure:
			case LiabilityType.CreditCard:
				(this.balance as any) = value;
				(this.payment as any) = null;
				break;
			default:
				(this.balance as any) = null;
				(this.limit as any) = null;
				(this.payment as any) = value;
		}
	}

	public getAmount(): number {
		switch (this.type) {
			case LiabilityType.LineOfCredit:
			case LiabilityType.LineOfCreditInsecure:
			case LiabilityType.CreditCard:
				return this.getAmountForCredit();
			case LiabilityType.Mortgage:
				return this.getAmountForMortgage();
			default:
				return this.payment ?? 0;
		}
	}

	public setTdsPercent(value: number): void {
		switch (this.type) {
			case LiabilityType.LineOfCredit:
			case LiabilityType.LineOfCreditInsecure:
			case LiabilityType.CreditCard:
				(this.details as any) = { tdsPercent: value };
				break;
			default:
				throw new Exception(
					`Cannot set Tds percent on '${this.type}'.`
				);
		}
	}

	private getAmountForMortgage(): number {
		const schoolTaxes = this.details?.schoolTaxes ?? 0;
		const condoFees = this.details?.condoFees ?? 0;
		return this.payment + schoolTaxes + condoFees;
	}

	private getAmountForCredit(): number {
		if (this.balance === null || this.details?.tdsPercent == null) {
			return 0;
		}
		return this.balance * (this.details?.tdsPercent / 100);
	}

	public static create(instance?: Partial<Liability>): Liability {
		return new Liability({
			id: instance?.id ?? createUUID(),
			type: instance?.type ?? LiabilityType.Mortgage,
			source: instance?.source ?? LiabilitySource.Customer,
			balance: instance?.balance ?? null,
			payment: instance?.payment ?? null,
			limit: instance?.limit ?? null,
			maturityDate: instance?.maturityDate ?? null,
			payoff: instance?.payoff ?? null,
			details: instance?.details ?? null
		});
	}
}
