import CoApplicant from '../model/co-applicant/CoApplicant';
import ICoApplicant from '../model/co-applicant/ICoApplicant';
import { createUUID } from '../model/value-objects/UUID';

export default class CoApplicantUnitTestHelper {
	public static create(updates?: any): CoApplicant {
		return new CoApplicant({
			id: createUUID(),
			applicationId: createUUID(),
			firstName: 'co-applicant firstname',
			lastName: 'co-applicant lastname',
			email: 'co-applicant@test.wiseday.com',
			mobilePhone: 'co-applicant mobile',
			...updates
		} as ICoApplicant);
	}
}
