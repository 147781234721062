import { Province } from '../model/value-objects/Province';

export default class ProvinceHelper {
	public static parse(province: string): Province | null {
		switch (province.toLowerCase()) {
			case Province.Quebec.toLocaleLowerCase():
			case 'qc':
				return Province.Quebec;
			case Province.Ontario.toLocaleLowerCase():
			case 'on':
				return Province.Ontario;
			default:
				return null;
		}
	}
}
