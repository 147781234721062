import React from 'react';
import styles from './AccountMenu.module.scss';
import { ReactComponent as ArrowDropDownIcon } from 'assets/images/chevron-down.svg';
import { ReactComponent as KebabMenuIcon } from 'assets/images/kebab-menu.svg';
import { useTranslation } from 'react-i18next';
import { useCustomAuth } from 'components/common/hooks/useCustomAuth';
import LiveSupportMenu from '../LiveSupportMenu';
import classnames from 'classnames';
import { useUserContext } from 'components/common/login/UserContextProvider';
import PrivateSpan from 'components/common/privacy/PrivateSpan';
import LanguageMenuItem from './LanguageMenuItem';
import { useEffectOnce } from 'components/common/hooks/useEffectOnce';
import { useLanguageContext } from 'components/common/context/LanguageProvider';

interface AccountMenuProps {
	className?: string;
	useIcon?: boolean;
}

export default function AccountMenu(props: AccountMenuProps) {
	const { className, useIcon } = props;
	const { isLoading, isAuthenticated, isTempAuthenticated } = useCustomAuth();
	const { userExit } = useUserContext();
	const { user } = useUserContext();
	const { t } = useTranslation();
	const { language } = useLanguageContext();

	const [open, setOpen] = React.useState(false);
	const drop = React.useRef<any>();
	function handleClick(e) {
		if (!e.target.closest(`.${drop.current?.className}`) && open) {
			setOpen(false);
		}
	}

	useEffectOnce(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	});

	if (isLoading) {
		return null;
	}
	const buildMenus = () => (
		<div className={styles.dropDown}>
			<a
				className={classnames(styles.dropDownItem, styles.link)}
				href={`/${language}/account`}
			>
				{t('common:my-applications')}
			</a>
			<a
				className={classnames(styles.dropDownItem, styles.link)}
				href={`/${language}/account`}
			>
				{t('common:my-profile')}
			</a>
			<LanguageMenuItem className={styles.dropDownItem} />
			<LiveSupportMenu
				className={styles.dropDownItem}
				hideIcon={true}
				phoneNumberLeft={true}
			/>
			<div
				key="logout"
				className={classnames(styles.dropDownItem, styles.logout)}
				onClick={userExit}
			>
				{t('common:logout')}
			</div>
		</div>
	);

	return (
		<div className={(styles.container, className)} ref={drop}>
			<div
				className={styles.container}
				ref={drop}
				onClick={() => setOpen(!open)}
			>
				{useIcon === true ? (
					<KebabMenuIcon className={styles.buttonDots} />
				) : isAuthenticated || isTempAuthenticated ? (
					<div className={styles.buttonUser}>
						<PrivateSpan className={styles.userName}>
							{user?.firstName}
						</PrivateSpan>
						<ArrowDropDownIcon className={styles.buttonArrow} />
						<span
							className={styles.email}
							data-private=""
							data-dd-privacy={'mask'}
						>
							{user?.email}
						</span>
					</div>
				) : null}
				{open && buildMenus()}
			</div>
		</div>
	);
}
