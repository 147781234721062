import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export type PortalProps = {
	className?: string;
	parentId?: string;
	children?: any;
};

export default function Portal(props: PortalProps) {
	const { className, parentId, children } = props;
	const container = useRef(document.createElement('div'));

	useEffect(() => {
		if (className) {
			container.current.classList.add(className);
		}
		const mainElement = parentId
			? document.getElementById(parentId)
			: document.getElementsByTagName('main')[0];
		mainElement?.appendChild(container.current);
		return () => {
			mainElement?.removeChild(container.current);
		};
	});

	return ReactDOM.createPortal(children, container.current);
}
