import ArgumentException from '../../exceptions/ArgumentException';
import { IpaResultCode } from './IpaResultCode';

export default class IpaResultCodeHelper {
	public static getName(ipaResultCode: IpaResultCode | null): string {
		if (!ipaResultCode) {
			return '';
		}

		switch (ipaResultCode) {
			case IpaResultCode.FicoScoreTooLow:
				return 'fico score is too low';
			case IpaResultCode.HasBankruptcy:
				return 'has bankruptcy';
			case IpaResultCode.MortgageAmountTooLow:
				return 'mortgage amount is too low';
			case IpaResultCode.NoFicoScore:
				return 'no fico score';
			case IpaResultCode.SalaryNoCurrentAddress:
				return 'has no current address';
			case IpaResultCode.SalaryNoData:
				return 'no salary data';
			case IpaResultCode.SalaryProvinceUnsupported:
				return 'salary province not supported';
			case IpaResultCode.Success:
				return 'success';
			default:
				throw new ArgumentException('ipaResultCode', ipaResultCode);
		}
	}
}
