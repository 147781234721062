import FormError from './FormError';

export default class FormResult {
	public readonly errors: Record<string, FormError>;

	constructor(public readonly data: any, errors: Array<FormError>) {
		this.errors = {};
		errors.forEach((e) => {
			if (!this.errors[e.param]) {
				this.errors[e.param] = e;
			}
		});
	}

	public hasErrors(): boolean {
		return Object.keys(this.errors).length > 0;
	}
}
