import { z } from 'zod';
import Exception from '../../../exceptions/Exception';
import { RateTerm } from '../../value-objects/RateTerm';
import { RateType } from '../../value-objects/RateType';

export const LtvRateSchema = z.object({
	loanToValueMin: z.number().min(0).max(1),
	loanToValueMax: z.number().min(0).max(1),
	isInsured: z.boolean(),
	term: z.nativeEnum(RateTerm),
	variableRate: z.number().nullable(),
	fixedRate: z.number()
});

type LtvRateSchemaInput = z.infer<typeof LtvRateSchema>;

export default class LtvRate {
	public static readonly Schema = LtvRateSchema;
	public readonly loanToValueMin: number;
	public readonly loanToValueMax: number;
	public readonly isInsured: boolean;
	public readonly term: RateTerm;
	public readonly variableRate: number | null;
	public readonly fixedRate: number;

	constructor(input: LtvRateSchemaInput) {
		const schema = LtvRateSchema.parse(input);
		this.loanToValueMin = schema.loanToValueMin;
		this.loanToValueMax = schema.loanToValueMax;
		this.isInsured = schema.isInsured;
		this.term = schema.term;
		this.variableRate = schema.variableRate;
		this.fixedRate = schema.fixedRate;
	}

	public getRate(rateType: RateType): number | null {
		switch (rateType) {
			case RateType.Variable:
				return this.variableRate;
			case RateType.Fixed:
				return this.fixedRate;
			default:
				throw new Exception(`Invalid RateType: ${rateType}`);
		}
	}
}
