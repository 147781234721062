export default class PhoneHelper {
	public static format(number: string, ouputOnlyNumbers = false): string {
		let result = number;
		const onlyNumbers = number.replace(/\D+/g, '');
		if (onlyNumbers.length === 10) {
			result = `+1${onlyNumbers}`;
		}
		if (onlyNumbers.length === 11 && onlyNumbers.startsWith('1')) {
			result = `+1${onlyNumbers.slice(1, onlyNumbers.length)}`;
		}

		if (ouputOnlyNumbers) {
			result = result.replace(/\D+/g, '');
		}

		return result;
	}

	public static formatNullable(
		number: string | null,
		ouputOnlyNumbers = false
	): string | null {
		if (number != null) {
			return PhoneHelper.format(number, ouputOnlyNumbers);
		}
		return null;
	}

	public static removeCountryCode(number: string): string {
		let result = number;
		const onlyNumbers = number.replace(/\D+/g, '');
		if (onlyNumbers.length === 10) {
			result = `${onlyNumbers}`;
		}
		if (onlyNumbers.length === 11 && onlyNumbers.startsWith('1')) {
			result = `${onlyNumbers.slice(1, onlyNumbers.length)}`;
		}

		return result;
	}

	public static isValid(number: string): boolean {
		const onlyNumbers = this.format(number, true);
		return !(
			onlyNumbers.startsWith('1555') ||
			onlyNumbers.startsWith('555') ||
			onlyNumbers.startsWith('1888') ||
			onlyNumbers.startsWith('888') ||
			onlyNumbers.startsWith('1866') ||
			onlyNumbers.startsWith('866') ||
			PhoneHelper.isAllSameDigitPhone(onlyNumbers)
		);
	}

	public static isAllSameDigitPhone(number: string): boolean {
		const onlyNumbers = this.format(number, true);
		for (let digit = 0; digit <= 9; digit++) {
			const regex = new RegExp(`${digit}`, 'g');
			const replaced = onlyNumbers.replace(regex, '');
			if (replaced.length === 0 || replaced === '1') {
				return true;
			}
		}
		return false;
	}

	public static isValidE164(number: string): boolean {
		const regEx = /^\+[1-9]\d{10,14}$/;
		return regEx.test(number);
	}

	public static formatToE164(number: string): string {
		return `+${this.format(number, true)}`;
	}
}
