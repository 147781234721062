import BaseEntity from '../common/BaseEntity';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import { Language } from '../value-objects/Language';
import { UUID } from '../value-objects/UUID';
import ICustomer from './ICustomer';

export default class Customer extends BaseEntity implements ICustomer {
	public readonly id: UUID;
	public readonly firstName: string;
	public readonly lastName: string;
	public readonly language: Language;
	public readonly email: string;
	public readonly mobilePhone: string;
	public readonly homePhone: string;
	public readonly birthdate?: Date | null;
	public readonly connection: string;
	public readonly isEmailConfirmed: boolean;
	public readonly hasAcceptedCreditTerms: boolean;
	public readonly acceptedCreditTermsDate: Date | null;
	public readonly createdDate: Date;
	public readonly updatedDate?: Date | null;

	constructor(input: ICustomer) {
		super({ entityType: EntityTypeEnum.Customer });
		this.id = input.id;
		this.firstName = input.firstName;
		this.lastName = input.lastName;
		this.language = input.language;
		this.email = input.email;
		this.mobilePhone = input.mobilePhone;
		this.homePhone = input.homePhone;
		this.birthdate = input.birthdate;
		this.connection = input.connection;
		this.isEmailConfirmed = input.isEmailConfirmed;
		this.hasAcceptedCreditTerms = input.hasAcceptedCreditTerms;
		this.acceptedCreditTermsDate = input.acceptedCreditTermsDate;
		this.createdDate = input.createdDate ?? new Date();
		this.updatedDate = input.updatedDate ?? null;
	}
}
