import Address from '../model/addresses/Address';
import { createUUID } from '../model/value-objects/UUID';

export default class AddressUnitTestHelper {
	public static createAddress(updates?: any): Address {
		return new Address({
			id: createUUID(),
			applicationId: createUUID(),
			customerId: createUUID(),
			isCurrent: true,
			addressLine1: 'unit-test-addressLine1',
			addressLine2: 'unit-test-addressLine2',
			city: 'unit-test-city',
			province: 'QC',
			country: 'CA',
			postalCode: 'X0Y 1R8',
			years: 3,
			months: 0,
			...updates
		});
	}
}
