import Alert, { AlertType } from 'components/common/alerts/Alert';
import Button from 'components/common/buttons/button/Button';
import Card from 'components/common/card/Card';
import { useCallPostApi } from 'components/common/hooks/useCallApi';
import TextInput from 'components/common/inputs/textInput/TextInput';
import Page from 'components/common/page/Page';
import Form from 'components/common/inputs/form/Form';
import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import baseStyles from './UnsubscribeRouter.module.scss';
import { FormError } from '@application/common';
import Toast from 'components/common/toast/Toast';

export default function Unsubscribe() {
	const { t } = useTranslation();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const { isBusy, callPostApi } = useCallPostApi(false);
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);
	const [formErrors, setFormErrors] = useState<Record<string, FormError>>({});
	const [alert, setAlert] = useState({ open: false, message: '' });
	const submit = () => {
		buttonRef.current?.click();
	};
	const handleSubmit = async (
		e: FormEvent<HTMLFormElement>
	): Promise<void> => {
		setFormErrors({});
		const formData = new FormData(e.currentTarget);
		const formJSON = Object.fromEntries(formData.entries());
		const response = await callPostApi(
			`/api/newsletter/unsubscribe/request`,
			formJSON
		);
		if (response.status === 200) {
			setSuccess(true);
		} else if (response.status === 400) {
			if (response.data.errors) {
				setFormErrors(response.data.errors ?? []);
			} else {
				setAlert({ open: true, message: response.data });
			}
		} else {
			setAlert({ open: true, message: t('common:api-error-toast') });
		}
	};

	return (
		<Page
			title={t('unsubscribe:unsubscribe.page-title')}
			className={baseStyles.container}
		>
			<Card className={baseStyles.card}>
				<h1 className={baseStyles.title}>
					{t(`unsubscribe:unsubscribe.title`)}
				</h1>
				<p className={baseStyles.text}>
					{t(`unsubscribe:unsubscribe.details-1`)}
				</p>
				<p className={baseStyles.text}>
					{t(`unsubscribe:unsubscribe.details-2`)}
				</p>
				<Form submitButtonRef={buttonRef} handleSubmit={handleSubmit}>
					<fieldset className={baseStyles.fieldset}>
						<TextInput
							autoFocus
							type="email"
							name="email"
							value={email}
							label={t('unsubscribe:unsubscribe.email.label')}
							placeholder={t(
								'unsubscribe:unsubscribe.email.placeholder'
							)}
							error={formErrors['email']?.msg}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{success ? (
							<Alert showIcon type={AlertType.Success}>
								{t('unsubscribe:unsubscribe.success-message')}
							</Alert>
						) : (
							<Button
								isBusy={isBusy}
								disabled={email.length === 0}
								onClick={() => submit()}
							>
								{t('unsubscribe:unsubscribe.send-request')}
							</Button>
						)}
					</fieldset>
					<Toast
						open={alert.open}
						autoHideDuration={5000}
						onClose={() => setAlert({ ...alert, open: false })}
					>
						<Alert showIcon type={AlertType.Error}>
							{alert.message}
						</Alert>
					</Toast>
				</Form>
			</Card>
		</Page>
	);
}
