import Exception from '../../exceptions/Exception';
import Address from '../addresses/Address';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import Employment from '../employments/Employment';
import { ApplicationRating } from '../value-objects/ApplicationRating';
import { ApplicationType } from '../value-objects/ApplicationType';
import { MortgageStage } from '../value-objects/MortgageStage';
import { RefinanceRenewingDateType } from '../value-objects/RefinanceRenewingDateType';
import { YesNo } from '../value-objects/YesNo';
import BaseApplication from './BaseApplication';
import IRefinanceApplication from './IRefinanceApplication';

export default class RefinanceApplication
	extends BaseApplication
	implements IRefinanceApplication
{
	public readonly valueOfProperty: number | null;
	public readonly mortgageBalance: number | null;
	public readonly isMortgageInsured: YesNo;
	public readonly mortgageWithdraw: number | null;
	public readonly mortgageRenewing: RefinanceRenewingDateType | null;

	constructor(input: IRefinanceApplication) {
		super(
			{ ...input, applicationType: ApplicationType.Refinance },
			EntityTypeEnum.Refinance
		);

		this.valueOfProperty = input.valueOfProperty;
		this.mortgageBalance = input.mortgageBalance;
		this.isMortgageInsured = input.isMortgageInsured;
		this.mortgageWithdraw = input.mortgageWithdraw;
		this.mortgageRenewing = input.mortgageRenewing;
	}

	public getAmount(originalRequestedAmount?: boolean): number {
		let result = 0;
		if (!this.maximumMortgageAmount || originalRequestedAmount) {
			result =
				parseFloat(`${this.mortgageBalance ?? 0}`) +
				parseFloat(`${this.mortgageWithdraw ?? 0}`);
		} else {
			result = this.maximumMortgageAmount;
		}
		return result;
	}

	public getRenewalDate(): string {
		if (!this.mortgageRenewing) {
			return 'N/A';
		}

		switch (this.mortgageRenewing) {
			case RefinanceRenewingDateType.AsSoonAsPossible:
				return 'As soon as possible';
			case RefinanceRenewingDateType.In1To2Months:
				return 'In 1 to 2 months';
			case RefinanceRenewingDateType.In2To4Months:
				return 'In 2 to 4 months';
			case RefinanceRenewingDateType.InMoreThan4Months:
				return 'In more than 4 months';
			default:
				throw new Exception(
					`Invalid RefinanceRenewingDateType value: ${this.mortgageRenewing}`
				);
		}
	}

	public hasGoodLendingRatio(): boolean {
		if (this.getTotalAnnualIncome() * 5.5 < this.getAmount(true)) {
			return false;
		}

		return true;
	}

	public getProgress(): number {
		if (this.rating === ApplicationRating.F) {
			return 100;
		}
		if (this.stage && this.stage !== MortgageStage.Lead) {
			return 80;
		}
		// Critical Information
		if (this.applyingWithCoApplicants == null) {
			return 24;
		}
		if (
			this.applyingWithCoApplicants === YesNo.Yes &&
			this.coApplicants.length === 0
		) {
			return 36;
		}
		// Additional Income
		if (!this.additionalIncomeCompleted) {
			return 43;
		}
		// Personal Information
		if (!Address.isAddressTotalMonthsOk(this.mainApplicant.addresses)) {
			return 54;
		}
		// Employment Income
		const currentEmployment = this.mainApplicant.employments?.find(
			(i) => i.isCurrent === true
		);
		if (
			currentEmployment === undefined ||
			!Employment.isTotalMonthsOk(this.mainApplicant.employments)
		) {
			return 61;
		}
		// Customer Documents
		if (this.hasMissingDocuments()) {
			return 80;
		}

		return 100;
	}
}
