import Header from 'components/common/header/Header';
import WisedayHomeLink from 'components/common/links/WisedayHomeLink';
import styles from './CustomPage.module.scss';
import classnames from 'classnames';
import { useTheme } from '../theme/ThemeProvider';

type FullPageProps = {
	children: any;
	showAccountMenu?: boolean | undefined;
};

export default function FullPage(props: FullPageProps): JSX.Element {
	const { children, showAccountMenu } = props;
	const { theme } = useTheme();
	return (
		<main className={classnames(theme.main, styles.layout)}>
			<header className={styles.header}>
				<Header
					rightSideContent={<WisedayHomeLink />}
					showAccountMenu={showAccountMenu ?? true}
				/>
			</header>
			{children}
		</main>
	);
}
