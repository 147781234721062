import ArgumentException from '../exceptions/ArgumentException';
import AnyApplication from '../model/application/AnyApplication';
import IBaseApplication from '../model/application/IBaseApplication';
import INewMortgageApplication from '../model/application/INewMortgageApplication';
import IRefinanceApplication from '../model/application/IRefinanceApplication';
import IRenewalApplication from '../model/application/IRenewalApplication';
import NewMortgageApplication from '../model/application/NewMortgageApplication';
import RefinanceApplication from '../model/application/RefinanceApplication';
import RenewalApplication from '../model/application/RenewalApplication';
import MainApplicant from '../model/main-applicant/MainApplicant';
import { ApplicationRequestedRatingType } from '../model/value-objects/ApplicationRequestedRatingType';
import { ApplicationType } from '../model/value-objects/ApplicationType';
import CompletedSections from '../model/value-objects/CompletedSections';
import { ContactStage } from '../model/value-objects/ContactStage';
import { DownPaymentPercentage } from '../model/value-objects/DownPaymentPercentage';
import { LenderType } from '../model/value-objects/LenderType';
import { MortgagePurchaseDateType } from '../model/value-objects/MortgagePurchaseDateType';
import { MortgageStage } from '../model/value-objects/MortgageStage';
import { PropertyType } from '../model/value-objects/PropertyType';
import { Province } from '../model/value-objects/Province';
import { RateTerm } from '../model/value-objects/RateTerm';
import { RateType } from '../model/value-objects/RateType';
import { RefinanceRenewingDateType } from '../model/value-objects/RefinanceRenewingDateType';
import { RenewalRenewingDateType } from '../model/value-objects/RenewalRenewingDateType';
import { createUUID } from '../model/value-objects/UUID';
import { YesNo } from '../model/value-objects/YesNo';

export default class ApplicationUnitTestHelper {
	public static createApplicationOfType(
		type: ApplicationType,
		updates?: any
	): AnyApplication {
		switch (type) {
			case ApplicationType.NewMortgage:
				return ApplicationUnitTestHelper.createNewMortgageApplication(
					updates
				);
			case ApplicationType.Refinance:
				return ApplicationUnitTestHelper.createRefinanceApplication(
					updates
				);
			case ApplicationType.Renewal:
				return ApplicationUnitTestHelper.createRenewalApplication(
					updates
				);
			default:
				throw new ArgumentException('type', type);
		}
	}

	public static createBaseApplication(
		updates?: Partial<IBaseApplication>
	): IBaseApplication {
		const customerId = updates?.customerId ?? createUUID();
		const applicationId = updates?.applicationId ?? createUUID();
		return {
			customerId: customerId,
			applicationId: applicationId,
			applicationNumber: updates?.applicationNumber ?? null,
			sdrOwner: updates?.sdrOwner,
			brokerOwner: updates?.brokerOwner,
			stage: updates?.stage ?? MortgageStage.Lead,
			filogixApplicationId: undefined,
			version: 2,
			applicationType: ApplicationType.NewMortgage,
			province: Province.Quebec,
			createdDate: new Date(),
			updatedDate: null,
			completedDate: null,
			submittedDate: null,
			rating: null,
			ratingLogs: null,
			rate: 1.2,
			rateDate: new Date(),
			rateTerm: RateTerm.ThreeYear,
			rateType: RateType.Variable,
			rateLenderType: LenderType.Virtual,
			rateAmount: 967,
			maximumMortgageAmount: updates?.maximumMortgageAmount ?? null,
			additionalIncomeMore: true,
			additionalIncomeCompleted: false,
			customerDocuments: undefined,
			applyingWithCoApplicants: null,
			ratingType: updates?.ratingType ?? null,
			ratingDate: updates?.ratingDate ?? null,
			ipaMaxDate: updates?.ipaMaxDate ?? null,
			ipaResultCode: updates?.ipaResultCode ?? null,
			requestedRatingType:
				updates?.requestedRatingType ??
				ApplicationRequestedRatingType.Default,
			checkWithPartnerDeclineDate:
				updates?.checkWithPartnerDeclineDate ?? null,
			mainApplicant:
				updates?.mainApplicant ??
				MainApplicant.create(customerId, applicationId, {}),
			coApplicants: updates?.coApplicants ?? [],
			completedSections: new CompletedSections(),
			...updates
		};
	}
	public static createNewMortgageApplication(
		updates?: Partial<INewMortgageApplication>
	): NewMortgageApplication {
		const baseApplication =
			ApplicationUnitTestHelper.createBaseApplication(updates);
		return new NewMortgageApplication({
			...baseApplication,
			contactStage: ContactStage.Curious,
			willYouLiveInProperty: YesNo.No,
			typeOfProperty: PropertyType.House,
			valueOfProperty: 340000,
			downPayment: 45000,
			hasRealEstateAgent: YesNo.Yes,
			purchaseDate: MortgagePurchaseDateType.ThreeToSixMonths,
			...updates
		});
	}
	public static createRefinanceApplication(
		updates?: Partial<IRefinanceApplication>
	): RefinanceApplication {
		const baseApplication =
			ApplicationUnitTestHelper.createBaseApplication(updates);
		return new RefinanceApplication({
			...baseApplication,
			valueOfProperty: 500000,
			mortgageBalance: 100000,
			isMortgageInsured: YesNo.Yes,
			mortgageWithdraw: 49000,
			mortgageRenewing: RefinanceRenewingDateType.In1To2Months,
			...updates
		});
	}
	public static createRenewalApplication(
		updates?: Partial<IRenewalApplication>
	): RenewalApplication {
		const baseApplication =
			ApplicationUnitTestHelper.createBaseApplication(updates);
		return new RenewalApplication({
			...baseApplication,
			valueOfProperty: 600000,
			mortgageBalance: 230000,
			isFirstTimeRenewing: YesNo.Yes,
			amortizationOriginalLength: 25,
			amortizationYearPurchased: null,
			previousAmortizationYears: 20,
			previousAmortizationMonths: 0,
			originalDownPaymentPercentage:
				DownPaymentPercentage.LessThan20Percent,
			mortgageRenewing: RenewalRenewingDateType.LessThanAMonth,
			mortgageType: null,
			isPropertyRefinanced: null,
			typeOfProperty: null,
			...updates
		});
	}
}
