import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const resources = {
	en: 'en',
	fr: 'fr'
};

export const availableLanguages = Object.keys(resources);

i18n.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		ns: [
			'common',
			'completed',
			'get-started',
			'get-rate',
			'refinance',
			'renewal',
			'new-mortgage-ipa',
			'co-new-mortgage-ipa',
			'get-approval',
			'instant-pre-approval',
			'hints',
			'account',
			'unsubscribe'
		],
		debug: process.env.NODE_ENV === 'development',
		defaultNS: 'common',
		fallbackLng: 'en',
		supportedLngs: ['en', 'fr'],
		load: 'languageOnly',
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		detection: {
			order: ['path', 'navigator'],
			lookupFromPathIndex: 0
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		},
		react: {
			useSuspense: true
		}
	});

export default i18n;
