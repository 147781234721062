import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import FacebookPixelScript from 'components/scripts/FacebookPixelScript';
import GoogleAnalyticsScript from 'components/scripts/GoogleAnalyticsScript';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DataDogClient from 'services/DataDogClient';
import Button, { ButtonSize, ButtonType } from '../buttons/button/Button';
import styles from './CookieConsent.module.scss';

function clearAllCookies() {
	const cookies = document.cookie.split(';');

	for (const cookie of cookies) {
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie.trim();
		document.cookie =
			name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
	}
}

function setConsentAnswer(answer: 'accepted' | 'rejected'): void {
	localStorage.setItem('cookieConsent', answer);
	document.cookie = `cookieConsent=${answer}; domain=.${process.env.REACT_APP_APPLICATION_ROOT_DOMAIN}; path=/; Secure; SameSite=Lax`;
}

function getCookie(name: string): string | null {
	const cookieArray = document.cookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];
		while (cookie.charAt(0) == ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name + '=') == 0) {
			return cookie.substring(name.length + 1, cookie.length);
		}
	}
	return null;
}

export default function CookieConsent() {
	const [isVisible, setIsVisible] = useState(false);
	const [isAccepted, setIsAccepted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		let consent: any = localStorage.getItem('cookieConsent');
		if (consent === null) {
			consent = getCookie('cookieConsent');
		}
		if (!consent) {
			setIsVisible(true);
		}
		if (consent === 'accepted') {
			setConsentAnswer('accepted');
			setIsAccepted(true);
		}
		if (consent === 'rejected') {
			setConsentAnswer('rejected');
			setIsAccepted(false);
		}
	}, []);

	const handleAccept = () => {
		DataDogClient.getInstance().info('Cookie consent accepted');
		setConsentAnswer('accepted');
		setIsVisible(false);
		setIsAccepted(true);
	};

	const handleReject = () => {
		DataDogClient.getInstance().info('Cookie consent rejected');
		localStorage.clear();
		clearAllCookies();
		setConsentAnswer('rejected');
		setIsVisible(false);
		setIsAccepted(false);
	};

	if (isAccepted) {
		return (
			<>
				<FacebookPixelScript />
				<GoogleAnalyticsScript />
			</>
		);
	}

	if (!isVisible) return null;

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<img
					className={styles.image}
					alt="Cookiee icon"
					src={'/images/cookie.png'}
				/>
				<p className={styles.text}>
					<Trans
						i18nKey="common:cookie-consent.text"
						t={t}
						components={[
							<a
								className={styles.link}
								key={0}
								href={`${
									process.env.REACT_APP_WEBSITE_ROOT_URL
								}/privacy-policy#${t(
									'common:cookie-consent.anchor'
								)}`}
							/>
						]}
					/>
				</p>
				<div className={styles.buttons}>
					<Button
						className={styles.ctaAccept}
						buttonType={ButtonType.Primary}
						buttonSize={ButtonSize.Small}
						onClick={handleAccept}
					>
						{t('common:cookie-consent.accept-cta')}
						<CheckIcon className={styles.greenCheck} />
					</Button>
					<Button
						className={styles.ctaReject}
						buttonType={ButtonType.Secondary}
						buttonSize={ButtonSize.Small}
						onClick={handleReject}
					>
						{t('common:cookie-consent.reject-cta')}
					</Button>
				</div>
			</div>
		</div>
	);
}
