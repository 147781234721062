import Exception from '../exceptions/Exception';
import { Province } from '../model/value-objects/Province';
import MathHelper from './MathHelper';

export class IncomeBracket {
	constructor(
		public readonly min: number,
		public readonly max: number,
		public readonly percent: number,
		public readonly amount: number // Not used
	) {}
}

export default class GrossIncomeHelper {
	private static readonly OntarioProvincialBrackets = [
		new IncomeBracket(0, 46226, 0.0505, 0),
		new IncomeBracket(46226, 92454, 0.0915, 6765.75),
		new IncomeBracket(92454, 150000, 0.1116, 15784.75),
		new IncomeBracket(150000, Number.MAX_VALUE, 0.1216, 20477.95)
	];
	private static readonly QuebecProvincialBrackets = [
		new IncomeBracket(0, 46295, 0.15, 0),
		new IncomeBracket(46295, 92580, 0.2, 6765.75),
		new IncomeBracket(92580, 112655, 0.24, 15784.75),
		new IncomeBracket(112655, Number.MAX_VALUE, 0.2575, 20477.95)
	];
	private static readonly FederalBrackets = [
		new IncomeBracket(0, 49020, 0.15, 0),
		new IncomeBracket(49020, 98040, 0.205, 7353),
		new IncomeBracket(98040, 151978, 0.26, 17402.1),
		new IncomeBracket(151978, 216511, 0.29, 31425.98),
		new IncomeBracket(216511, Number.MAX_VALUE, 0.33, 50140.55)
	];
	public static QppMaxGrossIncome = 55000;
	public static QppConstant = 3776.1;

	public static calculateGrossFromNetAnnualIncome(
		netAnnualIncome: number,
		province: Province
	): number {
		let calculatedNetAmount = 0;
		let previousCalculatedGrossAmount = 0;
		let calculatedGrossAmount = netAnnualIncome; // Start Gross at net amoutn
		let calculatedTaxedAmount = 0;

		do {
			previousCalculatedGrossAmount = calculatedGrossAmount;
			calculatedGrossAmount += 1;
			calculatedTaxedAmount =
				GrossIncomeHelper.calculateProvincialTaxedAmount(
					calculatedGrossAmount,
					province
				) +
				GrossIncomeHelper.calculateFederalTaxedAmount(
					calculatedGrossAmount
				);

			if (province === Province.Quebec) {
				calculatedTaxedAmount += GrossIncomeHelper.calculateQpp(
					calculatedGrossAmount
				);
			}
			calculatedNetAmount = calculatedGrossAmount - calculatedTaxedAmount;
		} while (calculatedNetAmount < netAnnualIncome);

		return previousCalculatedGrossAmount;
	}

	public static calculateFederalTaxedAmount(
		calculatedGrossAmount: number
	): number {
		let result = 0;
		let calculatedGrossAmountTemp = calculatedGrossAmount;
		GrossIncomeHelper.FederalBrackets.map((b) => {
			if (calculatedGrossAmountTemp > 0) {
				let tempResult = 0;
				if (calculatedGrossAmount < b.max) {
					tempResult = calculatedGrossAmountTemp * b.percent;
				} else {
					tempResult = b.percent * (b.max - b.min);
				}
				calculatedGrossAmountTemp -= b.max - b.min;
				result += tempResult;
			}
		});
		return result;
	}

	public static calculateProvincialTaxedAmount(
		calculatedGrossAmount: number,
		province: Province
	): number {
		let result = 0;
		switch (province) {
			case Province.Quebec:
				result = GrossIncomeHelper.calculateQuebecTaxedAmount(
					calculatedGrossAmount
				);
				break;
			case Province.Ontario:
				result = GrossIncomeHelper.calculateOntarioTaxedAmount(
					calculatedGrossAmount
				);
				break;
			default:
				throw new Exception(`Unsupported province '${province}'`);
		}
		return MathHelper.round(result, 2);
	}

	private static calculateQuebecTaxedAmount(
		calculatedGrossAmount: number
	): number {
		let result = 0;
		let calculatedGrossAmountTemp = calculatedGrossAmount;
		GrossIncomeHelper.QuebecProvincialBrackets.map((b) => {
			if (calculatedGrossAmountTemp > 0) {
				let tempResult = 0;
				if (calculatedGrossAmount < b.max) {
					tempResult = calculatedGrossAmountTemp * b.percent;
				} else {
					tempResult = b.percent * (b.max - b.min);
				}
				calculatedGrossAmountTemp -= b.max - b.min;
				result += tempResult;
			}
		});
		return result;
	}

	private static calculateOntarioTaxedAmount(
		calculatedGrossAmount: number
	): number {
		let result = 0;
		let calculatedGrossAmountTemp = calculatedGrossAmount;
		GrossIncomeHelper.OntarioProvincialBrackets.map((b) => {
			if (calculatedGrossAmountTemp > 0) {
				let tempResult = 0;
				if (calculatedGrossAmount < b.max) {
					tempResult = calculatedGrossAmountTemp * b.percent;
				} else {
					tempResult = b.percent * (b.max - b.min);
				}
				calculatedGrossAmountTemp -= b.max - b.min;
				result += tempResult;
			}
		});
		return result;
	}

	public static calculateQpp(calculatedGrossAmount: number): number {
		let result = 0;
		if (calculatedGrossAmount >= GrossIncomeHelper.QppMaxGrossIncome) {
			result = GrossIncomeHelper.QppConstant;
		} else {
			result = MathHelper.round(
				GrossIncomeHelper.QppConstant *
					(calculatedGrossAmount /
						GrossIncomeHelper.QppMaxGrossIncome),
				2
			);
		}
		return result;
	}
}
