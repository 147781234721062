import classnames from 'classnames';
import styles from './Alert.module.scss';
import { ReactComponent as InfoIcon } from 'assets/images/info-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/check-circle.svg';

export enum AlertType {
	Error,
	Info,
	Success
}

interface AlertProps {
	className?: string;
	type: AlertType;
	showIcon?: boolean;
	children: any;
}

export default function Alert(props: AlertProps): JSX.Element {
	const { className, type, children, showIcon } = props;
	return (
		<div
			className={classnames(
				AlertType.Error === type && styles.error,
				AlertType.Info === type && styles.info,
				AlertType.Success === type && styles.success,
				!showIcon && styles.noIcon,
				className
			)}
		>
			{showIcon && AlertType.Success === type && (
				<SuccessIcon className={styles.icon} />
			)}
			{showIcon && AlertType.Info === type && (
				<InfoIcon className={styles.icon} />
			)}
			{children}
		</div>
	);
}
