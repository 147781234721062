import { UUID } from '../../value-objects/UUID';
import IStepMetadata from './IStepMetadata';
import { ProgressState } from './ProgressState';
import { StepAction } from './StepAction';
import { StepType } from './StepType';

export default class StepMetadata implements IStepMetadata {
	public readonly id: string;
	public readonly name: string | undefined;
	public readonly type: StepType;
	public readonly action: StepAction;
	public readonly redirectUrl?: string;
	public readonly instanceId: UUID | undefined;
	public readonly isPII: boolean;
	private _progressState: ProgressState;
	public get progressState(): ProgressState {
		return this._progressState;
	}
	public progressStep: number;

	constructor(object?: any) {
		this.id = object?.id ?? '';
		this.name = object?.name;
		this.type = object?.type ?? StepType.Standard;
		this.action = object?.action ?? StepAction.Submit;
		this.redirectUrl = object?.redirectUrl;
		this.instanceId = object?.instanceId;
		this.isPII = object?.isPII ?? true;
		this.progressStep = object?.progressStep ?? 0;
		this._progressState = object?.progressState ?? ProgressState.None;
	}

	public json(): any {
		const json = {
			id: this.id,
			type: this.type,
			action: this.action,
			progressStep: this.progressStep,
			progressState: this.progressState,
			isPII: this.isPII
		};
		if (this.name) {
			json['name'] = this.name;
		}
		if (this.instanceId) {
			json['instanceId'] = this.instanceId;
		}
		if (this.redirectUrl) {
			json['redirectUrl'] = this.redirectUrl;
		}
		return json;
	}

	complete(): void {
		this._progressState = ProgressState.Completed;
	}

	skip(): void {
		this._progressState = ProgressState.Skipped;
	}

	current(): void {
		this._progressState = ProgressState.Current;
	}

	none(): void {
		this._progressState = ProgressState.None;
	}
}
