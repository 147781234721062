import ArgumentException from '../exceptions/ArgumentException';
import Exception from '../exceptions/Exception';
import AnyApplication from '../model/application/AnyApplication';
import IBaseApplication from '../model/application/IBaseApplication';
import INewMortgageApplication from '../model/application/INewMortgageApplication';
import IRefinanceApplication from '../model/application/IRefinanceApplication';
import IRenewalApplication from '../model/application/IRenewalApplication';
import NewMortgageApplication from '../model/application/NewMortgageApplication';
import RefinanceApplication from '../model/application/RefinanceApplication';
import RenewalApplication from '../model/application/RenewalApplication';
import CoApplicant from '../model/co-applicant/CoApplicant';
import CoApplicantDocuments from '../model/co-applicant/CoApplicantDocuments';
import ICoApplicantDocuments from '../model/co-applicant/ICoApplicantDocuments';
import ICustomerDocuments from '../model/customer/ICustomerDocuments';
import { ApplicationType } from '../model/value-objects/ApplicationType';
import { MortgageStage } from '../model/value-objects/MortgageStage';
import { YesNo } from '../model/value-objects/YesNo';
import PIIHelper from './PIIHelper';

export default class ApplicationHelper {
	public static MaximumMortgageAmount = 1000000;

	public static create(application: IBaseApplication): AnyApplication {
		switch (application.applicationType) {
			case ApplicationType.NewMortgage:
				return new NewMortgageApplication(
					application as INewMortgageApplication
				);
			case ApplicationType.Refinance:
				return new RefinanceApplication(
					application as IRefinanceApplication
				);
			case ApplicationType.Renewal:
				return new RenewalApplication(
					application as IRenewalApplication
				);
			default:
				throw new ArgumentException(
					'type',
					application.applicationType
				);
		}
	}

	public static getMinimumMortgageAmount(
		applicationType: ApplicationType
	): number {
		switch (applicationType) {
			case ApplicationType.NewMortgage:
			case ApplicationType.Refinance:
				return 200000;
			case ApplicationType.Renewal:
				return 140000;
			default:
				throw new ArgumentException('type', applicationType);
		}
	}

	public static updateApplication(
		application: IBaseApplication,
		updates?: Partial<IBaseApplication>
	): AnyApplication {
		switch (application.applicationType) {
			case ApplicationType.NewMortgage:
				return new NewMortgageApplication({
					...(application as NewMortgageApplication),
					...updates
				});
			case ApplicationType.Refinance:
				return new RefinanceApplication({
					...(application as RefinanceApplication),
					...updates
				});
			case ApplicationType.Renewal:
				return new RenewalApplication({
					...(application as RenewalApplication),
					...updates
				});
			default:
				throw new ArgumentException(
					'type',
					application.applicationType
				);
		}
	}

	public static updateCustomerDocuments(
		application: IBaseApplication,
		customerDocuments: ICustomerDocuments
	): AnyApplication {
		return ApplicationHelper.updateApplication(application, {
			customerDocuments: customerDocuments
		} as IBaseApplication);
	}

	public static updateCoApplicantDocuments(
		application: AnyApplication,
		coApplicantDocuments: ICoApplicantDocuments
	): AnyApplication {
		if (application.applyingWithCoApplicants === YesNo.Yes) {
			const coApplicant = application.getCoApplicant();
			if (coApplicant) {
				return ApplicationHelper.updateApplication(application, {
					coApplicants: [
						new CoApplicant({
							...coApplicant,
							documents: new CoApplicantDocuments(
								coApplicantDocuments
							)
						})
					]
				} as IBaseApplication);
			} else {
				throw new Exception(
					'Cannot update co-applicant documents since no co-applicant.'
				);
			}
		} else {
			throw new Exception(
				'Cannot update co-applicant documents since not applying with co-applicants.'
			);
		}
	}

	/**
	 * Removes the sensitive backend data from the application object so that it can be send to clients.
	 * Hack until we rework the DTOs for the client in the common project and move the internal types in backend-common.
	 *
	 * @param application The application that needs to be returned
	 * @returns The same application but with some fields removed.
	 */
	public static toApplicationJsonDto(application: AnyApplication): any {
		const json = application.json();
		// need to send wether user skipped or not the hard flow. Currently this is the CRM stage.
		// Cannot send all stage values to Frontend, instead, use only 2
		if (json.stage) {
			json.stage =
				json.stage === MortgageStage.Lead
					? MortgageStage.Lead
					: MortgageStage.DocumentsPending;
		}

		delete json.filogixApplicationId;
		delete json.requestedRatingType;
		delete json.ratingType;
		delete json.ratingLogs;
		delete json.promos;
		delete json.ficoScore;
		delete json.ipaResultCode;

		return json;
	}

	public static removePII(data: any): any {
		const result = JSON.parse(JSON.stringify(data));

		PIIHelper.replacePiiPropertyForAllDescendant(
			[
				'firstName',
				'middleName',
				'lastName',
				'mobilePhone',
				'homePhone',
				'workPhone',
				'addressLine1',
				'addressLine2',
				'employerAddressLine1',
				'employerAddressLine2',
				'employerName',
				'employerPhone',
				'sin',
				'identifications',
				'additionalInfo',
				'description',
				'phone', // Employment
				'name', // Employment,
				'title', // Employment
				'improvementDescription', // closingDetails
				'mortgagePurpose', // closingDetails
				'goal', // lenderNote
				'additionalNotes', // lenderNote
				'creditBureauAddressMatchReason', // lenderNote
				'creditBureauEmployerMatchReason', // lenderNote
				'creditNotes', // lenderNote
				'incomeNotes', // lenderNote
				'otherIncomeNotes' // lenderNote
			],
			result
		);

		PIIHelper.replaceEmailPiiPropertyForAllDescendant(['email'], result);

		return result;
	}
}
