export default class DownPaymentHelper {
	public static calculateMinDownPayment(amount: number): number {
		if (amount >= 1000000) {
			return amount * 0.2;
		}

		let amountBelow500k = amount * 0.05;
		let amountAbove500k = 0;

		if (amount > 500000) {
			amountBelow500k = 500000 * 0.05;
			amountAbove500k = (amount - 500000) * 0.1;
		}

		return amountBelow500k + amountAbove500k;
	}
}
