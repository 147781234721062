export enum LiabilityType {
	Mortgage = 'mortgage',
	CreditCard = 'credit-card',
	PersonalLoan = 'personal-loan',
	CarPayment = 'auto-loan',
	Alimony = 'alimony',
	ChildSupport = 'child-support',
	StudentLoan = 'student-loan',
	WageGarnishment = 'wage-garnishment',
	LineOfCreditInsecure = 'unsecured-line-of-credit',
	LineOfCredit = 'secured-line-of-credit',
	IncomeTax = 'income-tax',
	MortgageRentalProperty = 'mortgage-rental-property',
	Lease = 'lease',
	AutoLease = 'auto-lease',
	Rent = 'rent',
	Other = 'other'
}
