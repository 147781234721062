import { z } from 'zod';
import Exception from '../../../exceptions/Exception';
import { LenderType } from '../../value-objects/LenderType';
import { RateTerm } from '../../value-objects/RateTerm';
import { RateType } from '../../value-objects/RateType';
import RateAndPaymentDto from './RateAndPaymentDto';

export const TermRatesDtoSchema = z.object({
	rateTerm: z.nativeEnum(RateTerm),
	wisedayVariable: RateAndPaymentDto.Schema.nullable(),
	wisedayFixed: RateAndPaymentDto.Schema,
	bankVariable: RateAndPaymentDto.Schema.nullable(),
	bankFixed: RateAndPaymentDto.Schema
});

type TermRatesDtoInput = z.infer<typeof TermRatesDtoSchema>;

export default class TermRatesDto implements TermRatesDtoInput {
	public static readonly Schema = TermRatesDtoSchema;
	public readonly rateTerm: RateTerm;
	public readonly wisedayVariable: RateAndPaymentDto | null;
	public readonly wisedayFixed: RateAndPaymentDto;
	public readonly bankVariable: RateAndPaymentDto | null;
	public readonly bankFixed: RateAndPaymentDto;

	constructor(input: TermRatesDtoInput) {
		const schema = TermRatesDtoSchema.parse(input);
		this.rateTerm = schema.rateTerm;
		this.wisedayVariable = schema.wisedayVariable;
		this.wisedayFixed = schema.wisedayFixed;
		this.bankVariable = schema.bankVariable;
		this.bankFixed = schema.bankFixed;
	}

	public getRate(
		lenderType: LenderType,
		rateType: RateType,
		rateTerm: RateTerm
	): RateAndPaymentDto {
		if (lenderType === LenderType.Institutional) {
			if (rateType === RateType.Fixed) {
				return this.bankFixed;
			}
			if (rateTerm !== RateTerm.FiveYear || this.bankVariable == null) {
				throw new Exception(
					`Variable rates not available on ${rateTerm} term`
				);
			}
			return this.bankVariable;
		} else {
			if (rateType === RateType.Fixed) {
				return this.wisedayFixed;
			}
			if (
				rateTerm !== RateTerm.FiveYear ||
				this.wisedayVariable == null
			) {
				throw new Exception(
					`Variable rates not available on ${rateTerm} term`
				);
			}
			return this.wisedayVariable;
		}
	}
}
