import Exception from '../../exceptions/Exception';
import Address from '../addresses/Address';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import Employment from '../employments/Employment';
import { ApplicationRating } from '../value-objects/ApplicationRating';
import { ApplicationType } from '../value-objects/ApplicationType';
import { DownPaymentPercentage } from '../value-objects/DownPaymentPercentage';
import { MortgageStage } from '../value-objects/MortgageStage';
import { MortgageType } from '../value-objects/MortgageType';
import { PropertyType } from '../value-objects/PropertyType';
import { RenewalRenewingDateType } from '../value-objects/RenewalRenewingDateType';
import { YesNo } from '../value-objects/YesNo';
import BaseApplication from './BaseApplication';
import IRenewalApplication from './IRenewalApplication';

export default class RenewalApplication
	extends BaseApplication
	implements IRenewalApplication
{
	public readonly mortgageRenewing: RenewalRenewingDateType | null;
	public readonly valueOfProperty: number | null;
	public readonly mortgageBalance: number | null;
	public readonly isFirstTimeRenewing: YesNo | null;
	public readonly amortizationOriginalLength: number | null;
	public readonly amortizationYearPurchased: number | null;
	public readonly previousAmortizationYears: number | null;
	public readonly previousAmortizationMonths: number | null;
	public readonly originalDownPaymentPercentage: DownPaymentPercentage | null;
	public readonly typeOfProperty: PropertyType | null;
	public readonly mortgageType: MortgageType | null;
	public readonly isPropertyRefinanced: YesNo | null;

	constructor(input: IRenewalApplication) {
		super(
			{ ...input, applicationType: ApplicationType.Renewal },
			EntityTypeEnum.Renewal
		);

		this.mortgageRenewing = input.mortgageRenewing;
		this.valueOfProperty = input.valueOfProperty;
		this.mortgageBalance = input.mortgageBalance;
		this.isFirstTimeRenewing = input.isFirstTimeRenewing;
		this.amortizationOriginalLength = input.amortizationOriginalLength;
		this.amortizationYearPurchased = input.amortizationYearPurchased;
		this.previousAmortizationYears = input.previousAmortizationYears;
		this.previousAmortizationMonths = input.previousAmortizationMonths;
		this.originalDownPaymentPercentage =
			input.originalDownPaymentPercentage;
		this.typeOfProperty = input.typeOfProperty;
		this.mortgageType = input.mortgageType;
		this.isPropertyRefinanced = input.isPropertyRefinanced;
	}

	public getAmount(originalRequestedAmount?: boolean): number {
		let result = 0;
		if (!this.maximumMortgageAmount || originalRequestedAmount) {
			result = parseFloat(`${this.mortgageBalance}`);
		} else {
			result = this.maximumMortgageAmount;
		}
		return result;
	}

	public getRenewalDate(): string {
		if (!this.mortgageRenewing) {
			return 'N/A';
		}

		switch (this.mortgageRenewing) {
			case RenewalRenewingDateType.LessThanAMonth:
				return 'Less than a month';
			case RenewalRenewingDateType.In1To2Months:
				return 'In 1 to 2 months';
			case RenewalRenewingDateType.In2To4Months:
				return 'In 2 to 4 months';
			case RenewalRenewingDateType.InMoreThan4Months:
				return 'In more than 4 months';
			default:
				throw new Exception(
					`Invalid RenewalRenewingDateType value: ${this.mortgageRenewing}`
				);
		}
	}

	public getTotalAmortizationMonths(): number | null {
		let result = 0;

		if (this.previousAmortizationYears) {
			result = this.previousAmortizationYears * 12;
		}
		if (this.previousAmortizationMonths) {
			result += this.previousAmortizationMonths ?? 0;
		}

		return result > 0 ? result : null;
	}

	public hasGoodLendingRatio(): boolean {
		if (this.getTotalAnnualIncome() * 5.5 < this.getAmount(true)) {
			return false;
		}

		return true;
	}

	public getProgress(): number {
		if (this.rating === ApplicationRating.F) {
			return 100;
		}
		if (this.stage && this.stage !== MortgageStage.Lead) {
			return 80;
		}
		// Critical Information
		if (this.applyingWithCoApplicants == null) {
			return 24;
		}
		if (
			this.applyingWithCoApplicants === YesNo.Yes &&
			this.coApplicants.length === 0
		) {
			return 36;
		}
		// Additional Income
		if (!this.additionalIncomeCompleted) {
			return 43;
		}
		// Personal Information
		if (!Address.isAddressTotalMonthsOk(this.mainApplicant.addresses)) {
			return 54;
		}
		// Employment Income
		const currentEmployment = this.mainApplicant.employments?.find(
			(i) => i.isCurrent === true
		);
		if (
			currentEmployment === undefined ||
			!Employment.isTotalMonthsOk(this.mainApplicant.employments)
		) {
			return 61;
		}
		// Customer Documents
		if (this.hasMissingDocuments()) {
			return 80;
		}

		return 100;
	}
}
