import { z } from 'zod';
import Exception from '../../../exceptions/Exception';
import { LenderType } from '../../value-objects/LenderType';
import LenderLtvRate from './LenderLtvRate';

export const LenderLtvRatesSchema = z.object({
	virtual: LenderLtvRate.Schema,
	institutional: LenderLtvRate.Schema
});

type LenderLtvRatesInput = z.infer<typeof LenderLtvRatesSchema>;

export default class LenderLtvRates {
	public static readonly Schema = LenderLtvRatesSchema;
	public readonly virtual: LenderLtvRate;
	public readonly institutional: LenderLtvRate;

	constructor(input: LenderLtvRatesInput) {
		const schema = LenderLtvRatesSchema.parse(input);
		this.virtual = new LenderLtvRate(schema.virtual);
		this.institutional = new LenderLtvRate(schema.institutional);
	}

	public getLenderRate(type: LenderType): LenderLtvRate {
		switch (type) {
			case LenderType.Virtual:
				return this.virtual;
			case LenderType.Institutional:
				return this.institutional;
			default:
				throw new Exception(
					`Invalid ${LenderLtvRates.name} lender type '${type}'`
				);
		}
	}
}
