export enum MortgageStage {
	Lead = 'Lead',
	QualifiedCustomer = 'Qualified Customer',
	MeetingBooked = 'Meeting Booked',
	BrokerDueDiligence = 'Broker Due Diligence',
	CustomerDecisionPending = 'Customer Decision Pending',
	DocumentsPending = 'Documents Pending',
	BuildFile = 'Build File',
	UnderwritingInProgress = 'Underwriting In Progress',
	PendingCommitmentSignature = 'Pending Commitment Signature',
	ConditionsOutstanding = 'Conditions Outstanding',
	FileComplete = 'File Complete',
	NotaryAlerted = 'Notary Alerted',
	Funded = 'Funded',
	ContactInFuture = 'Contact in Future',
	LeadMonitization = 'Lead Monitization',
	PreApproved = 'Pre-Approved',
	FakeLead = 'Fake Lead',
	Unqualified = 'Unqualified',
	NotInterested = 'Not Interested'
}
