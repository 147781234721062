import { ApplicationRating, ApplicationType, UUID } from '@application/common';
import DataDogClient from './DataDogClient';

export enum GoogleAdsTags {
	// All Qualified Lead
	AllQualifiedRenewalLeads = '6WtpCMKhgZUYEJvh4Kco',
	// Mortgage
	LeadMortgageApplication = 'wWdICPvSrqQDEJvh4Kco',
	LeadMortgageTypeA = 'Rb9CCPS06c0DEJvh4Kco',
	LeadMortgageTypeB = 'zGm1CPe06c0DEJvh4Kco',
	LeadMortgageTypeC = 'XFB4CPq06c0DEJvh4Kco',
	LeadMortgageTypeP = 'sQvUCP2B39wDEJvh4Kco',
	// Refinance
	LeadRefinanceApplication = 'jxw-CKiA_LADEJvh4Kco',
	LeadRefinanceTypeA = 'lhrXCOu06c0DEJvh4Kco',
	LeadRefinanceTypeB = '5i3bCO606c0DEJvh4Kco',
	LeadRefinanceTypeC = 'LwrfCPG06c0DEJvh4Kco',
	// Renewal
	LeadRenewalApplication = 'hSc9CNfyt9cDEJvh4Kco',
	LeadRenewalTypeA = 'VvndCICC39wDEJvh4Kco',
	LeadRenewalTypeB = 'ZGg8CIOC39wDEJvh4Kco',
	LeadRenewalTypeC = 'TalBCIaC39wDEJvh4Kco'
}

export default class GoogleTagClient {
	public static setUserId(userId: UUID | undefined) {
		if ((window as any).dataLayer != undefined) {
			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				userId: userId
			});
		}
	}

	public static setApplicationId(applicationId: UUID | undefined) {
		if ((window as any).dataLayer != undefined) {
			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				applicationId: applicationId
			});
		}
	}

	public static pageView(
		name: string,
		type?: ApplicationType | undefined
	): void {
		if ((window as any).gtag != undefined) {
			(window as any).gtag('event', 'page_view', {
				page_title: `${this.getApplicationType(type)}${name}`,
				page_path: window.location.pathname,
				send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_PUBLIC_ID
			});
		} else {
			// eslint-disable-next-line no-console
			DataDogClient.getInstance().warn('GoogleTag not loaded');
		}
	}

	public static logPreQualConversion(
		applicationType: ApplicationType,
		applicationId: string
	): void {
		let googleAdsTag: GoogleAdsTags;
		if (applicationType === ApplicationType.NewMortgage) {
			googleAdsTag = GoogleAdsTags.LeadMortgageApplication;
		} else if (applicationType === ApplicationType.Refinance) {
			googleAdsTag = GoogleAdsTags.LeadRefinanceApplication;
		} else {
			googleAdsTag = GoogleAdsTags.LeadRenewalApplication;
		}
		this.gtagGoogleAds(googleAdsTag, applicationId);
		this.gtagGTM(applicationId);
	}

	public static logRating(
		applicationType: ApplicationType,
		applicationId: string,
		rating: ApplicationRating | null
	) {
		let allQualifiedRenewalLeads = false;
		let googleAdsTag: GoogleAdsTags | undefined = undefined;
		if (applicationType === ApplicationType.NewMortgage) {
			switch (rating) {
				case ApplicationRating.A:
					googleAdsTag = GoogleAdsTags.LeadMortgageTypeA;
					break;
				case ApplicationRating.B:
					googleAdsTag = GoogleAdsTags.LeadMortgageTypeB;
					break;
				case ApplicationRating.P:
					googleAdsTag = GoogleAdsTags.LeadMortgageTypeP;
					break;
				case ApplicationRating.C:
				case ApplicationRating.F:
					googleAdsTag = GoogleAdsTags.LeadMortgageTypeC;
					break;
				default:
					DataDogClient.getInstance().warn(
						`Unknown rating '${rating}' for GoogleTag`
					);
					break;
			}
		} else if (applicationType === ApplicationType.Refinance) {
			switch (rating) {
				case ApplicationRating.A:
					googleAdsTag = GoogleAdsTags.LeadRefinanceTypeA;
					break;
				case ApplicationRating.B:
					googleAdsTag = GoogleAdsTags.LeadRefinanceTypeB;
					break;
				case ApplicationRating.C:
				case ApplicationRating.F:
					googleAdsTag = GoogleAdsTags.LeadRefinanceTypeC;
					break;
				default:
					DataDogClient.getInstance().warn(
						`Unknown rating '${rating}' for GoogleTag`
					);
					break;
			}
		} else if (applicationType === ApplicationType.Renewal) {
			switch (rating) {
				case ApplicationRating.A:
					googleAdsTag = GoogleAdsTags.LeadRenewalTypeA;
					allQualifiedRenewalLeads = true;
					break;
				case ApplicationRating.B:
					googleAdsTag = GoogleAdsTags.LeadRenewalTypeB;
					allQualifiedRenewalLeads = true;
					break;
				case ApplicationRating.C:
				case ApplicationRating.F:
					googleAdsTag = GoogleAdsTags.LeadRenewalTypeC;
					break;
				default:
					DataDogClient.getInstance().warn(
						`Unknown rating '${rating}' for GoogleTag`
					);
					break;
			}
		}

		if (googleAdsTag != undefined) {
			this.gtagGoogleAds(googleAdsTag, applicationId);
			this.gtagGTM(applicationId);
		}

		if (allQualifiedRenewalLeads) {
			this.gtagGoogleAds(
				GoogleAdsTags.AllQualifiedRenewalLeads,
				applicationId
			);
		}
	}

	private static getApplicationType(
		type: ApplicationType | undefined
	): string {
		if (type === undefined) {
			return '';
		}
		return type !== ApplicationType.NewMortgage ? `${type} ` : '';
	}

	private static gtagGoogleAds(
		googleAdsTag: GoogleAdsTags,
		transactionId: string
	) {
		if ((window as any).gtag != undefined) {
			const event = {
				value: 1.0,
				currency: 'CAD',
				transaction_id: transactionId
			};
			(window as any).gtag('event', 'conversion', {
				...event,
				send_to: `${process.env.REACT_APP_GOOGLE_ADS_PUBLIC_ID}/${googleAdsTag}`
			});
			DataDogClient.getInstance().debug(
				`Google Ads - tag: ${googleAdsTag}`,
				{
					tagId: googleAdsTag
				}
			);
		} else {
			// eslint-disable-next-line no-console
			DataDogClient.getInstance().warn(
				'GoogleTag not loaded, Ads not sent'
			);
		}
	}

	private static gtagGTM(transactionId: string) {
		if (process.env.REACT_APP_GOOGLE_TAGS_PUBLIC_ID) {
			if ((window as any).gtag != undefined) {
				const event = {
					value: 1.0,
					currency: 'CAD',
					transaction_id: transactionId
				};
				(window as any).gtag('event', 'conversion', {
					...event,
					send_to: `${process.env.REACT_APP_GOOGLE_TAGS_PUBLIC_ID}`
				});
			} else {
				// eslint-disable-next-line no-console
				console.warn('GoogleTag not loaded');
			}
		} else {
			// eslint-disable-next-line no-console
			console.warn('GTM is disabled.');
		}
	}
}
