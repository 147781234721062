import CMHCPremiumHelper from '../../helpers/CMHCPremiumHelper';
import MathHelper from '../../helpers/MathHelper';
import Address from '../addresses/Address';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import Employment from '../employments/Employment';
import { ApplicationRating } from '../value-objects/ApplicationRating';
import { ApplicationType } from '../value-objects/ApplicationType';
import { ContactStage } from '../value-objects/ContactStage';
import { MortgagePurchaseDateType } from '../value-objects/MortgagePurchaseDateType';
import { MortgageStage } from '../value-objects/MortgageStage';
import { PropertyType } from '../value-objects/PropertyType';
import { YesNo } from '../value-objects/YesNo';
import BaseApplication from './BaseApplication';
import INewMortgageApplication from './INewMortgageApplication';

export default class NewMortgageApplication
	extends BaseApplication
	implements INewMortgageApplication
{
	public readonly willYouLiveInProperty: YesNo;
	public readonly contactStage: ContactStage;
	public readonly typeOfProperty: PropertyType;
	public readonly valueOfProperty: number | null;
	public readonly downPayment: number | null;
	public readonly hasRealEstateAgent: YesNo | null;
	public readonly purchaseDate: MortgagePurchaseDateType | null;

	constructor(input: INewMortgageApplication) {
		super(
			{ ...input, applicationType: ApplicationType.NewMortgage },
			EntityTypeEnum.NewMortgage
		);
		this.willYouLiveInProperty = input.willYouLiveInProperty;
		this.contactStage = input.contactStage;
		this.typeOfProperty = input.typeOfProperty;
		this.valueOfProperty = input.valueOfProperty;
		this.downPayment = input.downPayment;
		this.hasRealEstateAgent = input.hasRealEstateAgent;
		this.purchaseDate = input.purchaseDate;
	}

	public getMaximumPurchasePrice(): number {
		return MathHelper.round(
			Number(this.getAmount() ?? 0) + Number(this.downPayment),
			2
		);
	}

	public getAmount(originalRequestedAmount?: boolean): number {
		let result = 0;
		if (!this.maximumMortgageAmount || originalRequestedAmount) {
			const valueOfProperty = parseFloat(`${this.valueOfProperty}`);
			const downPayment = parseFloat(`${this.downPayment}`);
			const premium = CMHCPremiumHelper.calculate(
				valueOfProperty,
				downPayment
			);
			result = valueOfProperty - downPayment + premium.amount;
		} else {
			result = this.maximumMortgageAmount;
		}
		return result;
	}

	public getRenewalDate(): string {
		return this.purchaseDate ?? 'N/A';
	}

	public hasGoodLendingRatio(): boolean {
		if (!this.downPayment || !this.valueOfProperty) {
			return false;
		}
		const downPaymentRate = this.downPayment / this.valueOfProperty;
		const mortgageValue = this.getAmount(true);

		if (
			downPaymentRate < 0.2 &&
			this.getTotalAnnualIncome() * 4.5 < mortgageValue
		) {
			return false;
		}

		if (
			downPaymentRate >= 0.2 &&
			this.getTotalAnnualIncome() * 5.5 < mortgageValue
		) {
			return false;
		}

		return true;
	}

	public getProgress(): number {
		if (this.rating === ApplicationRating.F) {
			return 100;
		}
		if (this.stage && this.stage !== MortgageStage.Lead) {
			return 80;
		}
		// Critical Information
		if (this.applyingWithCoApplicants == null) {
			return 24;
		}
		if (
			this.applyingWithCoApplicants === YesNo.Yes &&
			this.coApplicants.length === 0
		) {
			return 36;
		}
		// Additional Income
		if (!this.additionalIncomeCompleted) {
			return 43;
		}
		// Personal Information
		if (!Address.isAddressTotalMonthsOk(this.mainApplicant.addresses)) {
			return 54;
		}
		// Current Employment
		const currentEmployment = this.mainApplicant.employments?.find(
			(i) => i.isCurrent === true
		);
		if (
			currentEmployment === undefined ||
			!Employment.isTotalMonthsOk(this.mainApplicant.employments)
		) {
			return 61;
		}
		// Customer Documents
		if (this.hasMissingDocuments()) {
			return 80;
		}

		return 100;
	}
}
