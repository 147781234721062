import CookieConsent from 'components/common/cookie-consent/CookieConsent';
import ThemeProvider from 'components/common/theme/ThemeProvider';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'styles/index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const containerRoot = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(containerRoot!);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider>
				<Suspense fallback={<></>}>
					<App />
				</Suspense>
			</ThemeProvider>
		</BrowserRouter>
		<CookieConsent />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
