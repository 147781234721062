import { useLanguageContext } from '../context/LanguageProvider';

const LoginRedirect = () => {
	const { language } = useLanguageContext();
	// const { loginWithRedirect } = useCustomAuth();
	// const location = useLocation();
	// const [searchParams] = useSearchParams();
	// const loginCallbackRedirect =
	// 	searchParams.get('redirect') ?? location.pathname;
	// useEffectOnce(() => {
	// 	sessionStorage.setItem('loginRedirectPath', loginCallbackRedirect);
	// 	loginWithRedirect({
	// 		ui_locales: language === Language.Fr ? 'fr-CA fr' : Language.En,
	// 		login_hint: searchParams.get('login_hint') ?? undefined,
	// 		redirectUri: `${process.env.REACT_APP_APPLICATION_ROOT_URL}/loginCallback`
	// 	});
	// });
	// We use to use Auth0 for customer logins. They use to create accounts.
	// Now, we only redirect them to Intellifi's client portal

	window.location.href = `https://client.wiseday.com/${language}/login`;
	return null;
};

export default LoginRedirect;
