import { ReactComponent as PhoneIcon } from 'assets/images/phone.svg';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './LiveSupportMenu.module.scss';

interface LiveSupportMenuProps {
	className?: string;
	hideIcon?: boolean;
	phoneNumberLeft?: boolean;
}

export default function LiveSupportMenu(
	props: LiveSupportMenuProps
): JSX.Element {
	const { hideIcon, phoneNumberLeft, className } = props;
	const { t } = useTranslation();
	return (
		<div
			className={classnames(
				styles.liveSupport,
				hideIcon && styles.noIcon,
				className
			)}
		>
			{!hideIcon && <PhoneIcon className={styles.icon} />}
			<span className={styles.title}>
				{t('common:live-support.title')}
			</span>
			<a
				className={classnames(
					styles.phoneNumber,
					phoneNumberLeft && styles.phoneNumberLeft
				)}
				href={`tel:${t('common:live-support.phone-number')}`}
			>
				{t('common:live-support.phone-number-display')}
			</a>
		</div>
	);
}
