import { useEffect } from 'react';
import DataDogClient from 'services/DataDogClient';
import ScriptHelper from './ScriptHelper';

const GOOGLE_TAGS_PUBLIC_ID =
	process.env.REACT_APP_GOOGLE_TAGS_PUBLIC_ID?.trim();
const GOOGLE_ANALYTICS_PUBLIC_ID =
	process.env.REACT_APP_GOOGLE_ANALYTICS_PUBLIC_ID?.trim();
const GOOGLE_ADS_PUBLIC_ID = process.env.REACT_APP_GOOGLE_ADS_PUBLIC_ID?.trim();

const GoogleAnalyticsScript = () => {
	return (
		<>
			<GoogleTagManagerScript />
			<GoogleTagManagerAnalyticsScript />
			<GoogleAdsScript />
			<GoogleDataLayerScript />
		</>
	);
};

const GoogleTagManagerScript = () => {
	useEffect(() => {
		if (GOOGLE_TAGS_PUBLIC_ID) {
			const script = document.createElement('script');
			script.nonce = ScriptHelper.getNonce();
			script.async = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAGS_PUBLIC_ID}`;
			document.body.appendChild(script);
		} else {
			DataDogClient.getInstance().warn('Disabled Google Tag Manager');
		}
	}, []);

	return null;
};

const GoogleTagManagerAnalyticsScript = () => {
	useEffect(() => {
		if (GOOGLE_ANALYTICS_PUBLIC_ID) {
			const script = document.createElement('script');
			script.nonce = ScriptHelper.getNonce();
			script.defer = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_PUBLIC_ID}`;
			document.body.appendChild(script);
		} else {
			DataDogClient.getInstance().warn(
				'Disabled Google Tag Manager Analytics'
			);
		}
	}, []);

	return null;
};

const GoogleAdsScript = () => {
	useEffect(() => {
		if (GOOGLE_ADS_PUBLIC_ID) {
			const script = document.createElement('script');
			script.nonce = ScriptHelper.getNonce();
			script.async = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_PUBLIC_ID}`;
			document.body.appendChild(script);
		} else {
			DataDogClient.getInstance().warn('Disabled Google Ads');
		}
	}, []);

	return null;
};

const GoogleDataLayerScript = () => {
	useEffect(() => {
		if (GOOGLE_ANALYTICS_PUBLIC_ID || GOOGLE_ADS_PUBLIC_ID) {
			const script = document.createElement('script');
			script.nonce = ScriptHelper.getNonce();
			script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                ${
					GOOGLE_ANALYTICS_PUBLIC_ID
						? `gtag('config', '${GOOGLE_ANALYTICS_PUBLIC_ID}', { send_page_view: false });`
						: ''
				}
                ${
					GOOGLE_ADS_PUBLIC_ID
						? `gtag('config', '${GOOGLE_ADS_PUBLIC_ID}');`
						: ''
				}
            `;
			document.body.appendChild(script);
		} else {
			DataDogClient.getInstance().warn('Disabled Google Data Layers');
		}
	}, []);

	return null;
};

export default GoogleAnalyticsScript;
