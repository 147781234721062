import { LenderType } from '../../value-objects/LenderType';
import { RateTerm } from '../../value-objects/RateTerm';
import { RateType } from '../../value-objects/RateType';

export default class RatePerMonth {
	constructor(
		public readonly isInsured: boolean,
		public readonly rate: number,
		public readonly ratePerMonth: number,
		public readonly monthlyPayment: number,
		public readonly amortizationMonths: number,
		public readonly term: RateTerm,
		public readonly rateType: RateType,
		public readonly lenderType: LenderType
	) {}
}
