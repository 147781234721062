import RateAndPaymentDto from '../model/dtos/mortgage-rates/RateAndPaymentDto';
import TermRatesDto from '../model/dtos/mortgage-rates/TermRatesDto';
import { LenderType } from '../model/value-objects/LenderType';
import { RateTerm } from '../model/value-objects/RateTerm';
import { RateType } from '../model/value-objects/RateType';

export default class TermRatesDtoUnitTestHelper {
	public static create(rateTerm: RateTerm): TermRatesDto {
		return new TermRatesDto({
			rateTerm: RateTerm.FourYear,
			wisedayFixed: new RateAndPaymentDto({
				rate: Math.random(),
				isInsured: true,
				lenderType: LenderType.Virtual,
				monthlyPayment: Math.random() * 10000,
				rateType: RateType.Fixed,
				term: rateTerm
			}),
			wisedayVariable: new RateAndPaymentDto({
				rate: Math.random(),
				isInsured: true,
				lenderType: LenderType.Virtual,
				monthlyPayment: Math.random() * 10000,
				rateType: RateType.Variable,
				term: rateTerm
			}),
			bankFixed: new RateAndPaymentDto({
				rate: Math.random(),
				isInsured: true,
				lenderType: LenderType.Institutional,
				monthlyPayment: Math.random() * 10000,
				rateType: RateType.Fixed,
				term: rateTerm
			}),
			bankVariable: new RateAndPaymentDto({
				rate: Math.random(),
				isInsured: true,
				lenderType: LenderType.Institutional,
				monthlyPayment: Math.random() * 10000,
				rateType: RateType.Variable,
				term: rateTerm
			})
		});
	}
}
