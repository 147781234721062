import React, { useCallback } from 'react';
import styles from './LanguageMenuItem.module.scss';
import { availableLanguages } from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import ExpandableContainer from 'components/common/containers/ExpandableContainer';
import { useLanguageContext } from 'components/common/context/LanguageProvider';

interface LanguageMenuItemProps {
	className: string;
}

export default function LanguageMenuItem(props: LanguageMenuItemProps) {
	const { className } = props;
	const { t } = useTranslation();
	const { language, changeLanguage } = useLanguageContext();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const updateLanguage = useCallback(
		(lng) => {
			changeLanguage(lng);
			navigate(`/${lng}/${pathname.split('/').slice(2).join('/')}`);
		},
		[navigate, changeLanguage]
	);

	const [open, setOpen] = React.useState(false);

	return (
		<ExpandableContainer
			className={classnames(styles.button, className)}
			expandedClassName={styles.dropDown}
			expanded={open}
			header={
				<span className={styles.buttonText}>
					{t(`common:language.${language}`)}
				</span>
			}
		>
			{availableLanguages.map((lng: string, i: any) => {
				return (
					<div
						key={i}
						className={styles.dropDownItem}
						onClick={() => {
							setOpen(false);
							updateLanguage(lng);
						}}
					>
						{t(`common:language.${lng}`)}
					</div>
				);
			})}
		</ExpandableContainer>
	);
}
