export default class PMTHelper {
	public static calculate(
		amount: number,
		ratePerMonth: number,
		amortizationPeriodInMonths: number
	) {
		return PMTHelper.pmt(
			amount,
			ratePerMonth,
			amortizationPeriodInMonths,
			0,
			1
		);
	}

	private static pmt(pv: number, r: number, n: number, future = 0, type = 1) {
		const rate = r / 100;
		if (rate === 0) {
			return (pv + future) / n;
		} else {
			const term = Math.pow(1 + rate, n);
			if (type === 1) {
				return (
					((future * rate) / (term - 1) +
						(pv * rate) / (1 - 1 / term)) /
					(1 + rate)
				);
			} else {
				return (
					(future * rate) / (term - 1) + (pv * rate) / (1 - 1 / term)
				);
			}
		}
	}
}
