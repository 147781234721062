export enum DocumentType {
	PhotoId = 'photo-id',
	PayStub = 'pay-stub',
	T4 = 'T4',
	T4A = 'T4A',
	T1 = 'T1',
	NOA = 'NOA',
	MortgageStatement = 'mortgage-statement',
	BankStatement90Days = 'bank-statement-90-days',
	EmploymentLetter = 'employment-letter',
	SecondaryId = 'secondary-id',
	VoidCheque = 'void-cheque',
	ConfirmationOfCondoFees = 'confirmation-of-condo-fees',
	RentalLeaseAgreement = 'rental-lease-agreement',
	MunicipalTaxBill = 'municipal-tax-bill',
	SchoolTaxBill = 'school-tax-bill',
	SelfEmployedFinancialStatement = 'self-employed-financial-statement',
	SavingAccountStatement = 'saving-account-statement',
	PromiseOfPurchase = 'promise-of-purchase',
	LetterOfAcceptance = 'letter-of-acceptance',
	MortgagePropertyBeingSoldStatement = 'mortgage-property-being-sold-statement',
	ProofOfGiftAmount = 'proof-of-gift-amount',
	PurchaseOffer = 'purchase-offer',
	SellersDeclaration = 'sellers-declaration',
	MlsListing = 'mls-listing',
	PreleminaryPurchaseContract = 'preleminary-purchase-contract',
	RenovationOrPlanQuotes = 'renovation-or-plan-quotes',
	SignedLeased = 'signed-leased',
	Miscellaneous = 'miscellaneous'
}
