import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../context/LanguageProvider';
import styles from './NotFound.module.scss';

const NotFound = () => {
	const { t } = useTranslation();
	const { language } = useLanguageContext();
	return (
		<div className={styles.container}>
			<h1>{t('common:not-found.title')}</h1>
			<h3>{t('common:not-found.subtitle')}</h3>
			<a href={`/${language}/account`}>
				{t('common:not-found.link-account')}
			</a>
			<a href="/">{t('common:not-found.link-text')}</a>
		</div>
	);
};

export default NotFound;
