import Exception from '../../exceptions/Exception';
import { AdditionalIncomeType } from '../value-objects/AdditionalIncomeType';
import { Frequency } from '../value-objects/Frequency';
import { UUID, createUUID } from '../value-objects/UUID';

interface IAdditionalIncome {
	readonly id: UUID;
	readonly type: AdditionalIncomeType;
	readonly description: string;
	readonly frequency: Frequency;
	readonly amount: number;
}

export default class AdditionalIncome implements IAdditionalIncome {
	public readonly id: UUID;
	public readonly type: AdditionalIncomeType;
	public readonly description: string;
	public readonly frequency: Frequency;
	public readonly amount: number;

	constructor(input: IAdditionalIncome) {
		this.id = input.id;
		this.type = input.type;
		this.description = input.description;
		this.frequency = input.frequency;
		this.amount = input.amount;
	}

	getAnnualAmount(): number {
		if (this.amount == null || this.frequency == null) {
			return 0;
		}
		switch (this.frequency) {
			case Frequency.Annual:
				return this.amount;
			case Frequency.SemiAnnual:
				return this.amount * 2;
			case Frequency.Monthly:
				return this.amount * 12;
			case Frequency.SemiMonthly:
				return this.amount * 24;
			case Frequency.BiWeekly:
				return this.amount * 26;
			case Frequency.Weekly:
				return this.amount * 52;
			default:
				throw new Exception(
					`Invalid Frequency value: ${this.frequency}`
				);
		}
	}

	public static create(instance?: Partial<AdditionalIncome>) {
		return new AdditionalIncome({
			id: instance?.id ?? createUUID(),
			type: instance?.type ?? AdditionalIncomeType.ChildSupport,
			amount: instance?.amount ?? 0,
			frequency: instance?.frequency ?? Frequency.Annual,
			description: instance?.description ?? ''
		});
	}
}
