import classnames from 'classnames';
import { ReactNode } from 'react';
import styles from './Section.module.scss';

interface SectionProps {
	className?: string;
	children: ReactNode;
}
export default function Section({
	className,
	children
}: SectionProps): JSX.Element {
	return (
		<section className={classnames(className, styles.section)}>
			<div className={classnames(className, styles.sectionContainer)}>
				{children}
			</div>
		</section>
	);
}
