import {
	FocusEventHandler,
	FormEvent,
	MouseEventHandler,
	useCallback
} from 'react';

interface FormProps {
	className?: string;
	submitButtonRef: React.RefObject<HTMLButtonElement>;
	handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
	children: any;
	onClick?: MouseEventHandler<HTMLFormElement>;
	onFocus?: FocusEventHandler<HTMLFormElement>;
	onBlur?: FocusEventHandler<HTMLFormElement>;
}

export default function Form(props: FormProps): JSX.Element {
	const {
		className,
		children,
		submitButtonRef,
		handleSubmit,
		onClick,
		onFocus,
		onBlur
	} = props;
	const formSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			await handleSubmit(e);
		},
		[handleSubmit]
	);

	return (
		<form
			className={className}
			onSubmit={formSubmit}
			onFocus={onFocus}
			onBlur={onBlur}
			onClick={onClick}
			noValidate={true}
		>
			{children}
			<button ref={submitButtonRef} hidden={true} type="submit" />
		</form>
	);
}
