import ArgumentException from '../exceptions/ArgumentException';

export default class GroupByHelper {
	public static groupBy(
		array: Array<any>,
		properties: Array<string>
	): Array<Array<any>> {
		return array.reduce((acc, item) => {
			const isExistingItem = acc
				.flatMap((accItem) => accItem)
				.find((accItem) =>
					properties.every((key) => {
						if (accItem[key] === undefined) {
							throw new ArgumentException('groupByProperty', key);
						}
						return accItem[key] === item[key];
					})
				);

			if (isExistingItem) {
				return acc;
			}

			const allRelatedItems = array.filter((ungroupedItem) =>
				properties.every((key) => ungroupedItem[key] === item[key])
			);

			acc.push(allRelatedItems);

			return acc;
		}, []);
	}
}
