import classnames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import styles from './Toast.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';

interface ToastProps {
	className?: string;
	autoHideDuration?: number;
	open: boolean;
	children: ReactNode;
	onClose: any;
}
export default function Toast(props: ToastProps): JSX.Element | null {
	const { className, children, autoHideDuration, open, onClose } = props;
	const [isOpen, setOpen] = useState(open);
	useEffect(() => {
		setOpen(open);
	}, [open]);
	useEffect(() => {
		if (autoHideDuration) {
			const timer = setTimeout(() => {
				setOpen(false);
				onClose();
			}, autoHideDuration);
			return () => clearTimeout(timer);
		}
	}, [autoHideDuration, open]);

	if (isOpen) {
		return (
			<div className={classnames(styles.toast, className)}>
				<div className={styles.content}>
					<CloseIcon
						className={styles.closeButton}
						onClick={() => {
							setOpen(false);
							onClose();
						}}
					/>
					{children}
				</div>
			</div>
		);
	} else {
		return null;
	}
}
