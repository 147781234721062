import classnames from 'classnames';
import styles from './StarRating.module.scss';
import { ReactComponent as StarIcon } from 'assets/images/star-rating.svg';

export type StarRatingProps = {
	className?: string;
	rating: number;
	starSpacing?: number;
	starSize?: number;
};

export default function StarRating(props: StarRatingProps) {
	const { className, starSpacing, starSize, rating } = props;
	const starSpacingValue: number = starSpacing ?? 2;
	const starSizeValue: number = starSize ?? 24;

	return (
		<div className={classnames(styles.container, className)}>
			{Array(5)
				.fill(null)
				.map((_v: any, i: number) => {
					i = i + 1;
					let w = 0;
					const x = i - rating;
					if (i <= rating) {
						w = 100;
					} else if (x > 0 && x < 1) {
						w = (1 - x) * 100;
					}
					return (
						<div
							key={i}
							style={{
								paddingLeft: `${
									i === 1 ? 0 : starSpacingValue
								}px`,
								paddingRight: `${starSpacingValue}px`
							}}
						>
							<div
								className={styles.starImageContainer}
								style={{
									width: `${w}%`
								}}
							>
								<StarIcon
									className={styles.starImage}
									style={{
										height: `${starSizeValue}px`,
										width: `${starSizeValue}px`
									}}
								/>
							</div>
						</div>
					);
				})}
		</div>
	);
}
