import ArgumentException from '../../exceptions/ArgumentException';
import Exception from '../../exceptions/Exception';
import Address from '../addresses/Address';
import CoApplicant from '../co-applicant/CoApplicant';
import CoApplicantDocuments from '../co-applicant/CoApplicantDocuments';
import ICoApplicant from '../co-applicant/ICoApplicant';
import BaseEntity from '../common/BaseEntity';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import CustomerDocuments from '../customer/CustomerDocuments';
import ICustomer from '../customer/ICustomer';
import ICustomerDocuments from '../customer/ICustomerDocuments';
import { IpaResultCode } from '../ipa/IpaResultCode';
import MainApplicant from '../main-applicant/MainApplicant';
import { AdditionalIncomeType } from '../value-objects/AdditionalIncomeType';
import { ApplicationRating } from '../value-objects/ApplicationRating';
import { ApplicationRatingType } from '../value-objects/ApplicationRatingType';
import { ApplicationRequestedRatingType } from '../value-objects/ApplicationRequestedRatingType';
import { ApplicationType } from '../value-objects/ApplicationType';
import CompletedSections from '../value-objects/CompletedSections';
import { DocumentStateType } from '../value-objects/DocumentStateType';
import { LenderType } from '../value-objects/LenderType';
import { MortgageStage } from '../value-objects/MortgageStage';
import { Province } from '../value-objects/Province';
import { RateTerm } from '../value-objects/RateTerm';
import { RateType } from '../value-objects/RateType';
import { UUID } from '../value-objects/UUID';
import { YesNo } from '../value-objects/YesNo';
import IBaseApplication from './IBaseApplication';

export default abstract class BaseApplication
	extends BaseEntity
	implements IBaseApplication
{
	public readonly applicationId: UUID;
	public readonly applicationType: ApplicationType;
	public readonly applicationNumber: number | null;
	public readonly province: Province;
	public readonly customerId: UUID;
	public readonly sdrOwner: string | null | undefined;
	public readonly brokerOwner: string | null | undefined;
	public readonly stage: MortgageStage;
	public readonly checkWithPartnerDeclineDate: Date | null;
	public readonly filogixApplicationId: string | undefined;
	public readonly createdDate: Date;
	public readonly updatedDate: Date | null;
	public readonly completedDate: Date | null;
	public readonly submittedDate: Date | null;
	public readonly version: number;
	public readonly rate: number | null;
	public readonly rateDate: Date | null;
	public readonly rateTerm: RateTerm;
	public readonly rateType: RateType;
	public readonly rateLenderType: LenderType;
	public readonly rateAmount: number | null;
	public readonly requestedRatingType: ApplicationRequestedRatingType;
	public readonly rating: ApplicationRating | null;
	public readonly ratingType: ApplicationRatingType | null;
	public readonly ratingDate: Date | null;
	public readonly ratingLogs: Array<string> | null;
	public readonly ipaResultCode: IpaResultCode | null;
	public readonly ipaMaxDate: Date | null;
	public readonly maximumMortgageAmount: number | null;
	public readonly mainApplicant: MainApplicant;
	public readonly applyingWithCoApplicants: YesNo | null;
	public readonly coApplicants: Array<ICoApplicant>;
	public readonly additionalIncomeMore: boolean;
	public readonly additionalIncomeCompleted: boolean;
	public readonly customerDocuments: ICustomerDocuments | undefined;
	public readonly promos?: any | null;
	public readonly completedSections: CompletedSections;

	constructor(input: IBaseApplication, entityType: EntityTypeEnum) {
		if (
			entityType !== EntityTypeEnum.NewMortgage &&
			entityType !== EntityTypeEnum.Refinance &&
			entityType !== EntityTypeEnum.Renewal
		) {
			throw new ArgumentException('entityType', entityType);
		}
		if (
			(entityType !== EntityTypeEnum.NewMortgage &&
				input.applicationType === ApplicationType.NewMortgage) ||
			(entityType !== EntityTypeEnum.Refinance &&
				input.applicationType === ApplicationType.Refinance) ||
			(entityType !== EntityTypeEnum.Renewal &&
				input.applicationType === ApplicationType.Renewal)
		) {
			throw new Exception(
				`applicationType: '${input.applicationType}' does not match entityType '${entityType}'.`
			);
		}
		super({ entityType: entityType });
		this.applicationId = input.applicationId;
		this.applicationType = input.applicationType;
		this.applicationNumber = input.applicationNumber;
		this.province = input.province;
		this.customerId = input.customerId;
		this.sdrOwner = input.sdrOwner;
		this.brokerOwner = input.brokerOwner;
		this.stage = input.stage ?? MortgageStage.Lead;
		this.checkWithPartnerDeclineDate = input.checkWithPartnerDeclineDate;
		this.filogixApplicationId = input.filogixApplicationId;
		this.createdDate = input.createdDate;
		this.updatedDate = input.updatedDate;
		this.completedDate = input.completedDate;
		this.submittedDate = input.submittedDate;
		this.version = input.version;
		this.requestedRatingType = input.requestedRatingType;
		this.rating = input.rating;
		this.ratingType = input.ratingType;
		this.rateTerm = input.rateTerm;
		this.ratingDate = input.ratingDate;
		this.ratingLogs = input.ratingLogs;
		this.rate = input.rate;
		this.rateDate = input.rateDate;
		this.rateType = input.rateType;
		this.rateLenderType = input.rateLenderType;
		this.rateAmount = input.rateAmount;
		this.ipaResultCode = input.ipaResultCode;
		this.ipaMaxDate = input.ipaMaxDate;
		this.maximumMortgageAmount = input.maximumMortgageAmount;
		this.additionalIncomeMore = input.additionalIncomeMore;
		this.additionalIncomeCompleted = input.additionalIncomeCompleted;
		this.mainApplicant = MainApplicant.create(
			input.customerId,
			input.applicationId,
			input.mainApplicant
		);
		this.applyingWithCoApplicants = input.applyingWithCoApplicants;
		this.coApplicants =
			input.coApplicants?.map((c) => new CoApplicant(c)) ??
			new Array<ICoApplicant>();
		this.promos = input.promos;
		this.customerDocuments = input.customerDocuments;
		this.completedSections = input.completedSections
			? new CompletedSections(
					input.completedSections.json
						? input.completedSections.json()
						: input.completedSections
			  )
			: new CompletedSections();
	}

	public json(): any {
		return {
			...this,
			completedSections: this.completedSections.json()
		};
	}

	public abstract getAmount(originalRequestedAmount?: boolean): number;

	public abstract hasGoodLendingRatio(): boolean;

	public abstract getProgress(): number;

	public abstract getRenewalDate(): string;

	public getAdditionalIncomeAmount(type: AdditionalIncomeType): number {
		const amounts: Array<number> = this.mainApplicant.additionalIncomes
			.filter((x) => x.type === type && x.amount != null)
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			.map((y) => y.getAnnualAmount());
		return amounts.reduce((result, v) => result + v, 0);
	}

	public getCoApplicant(): ICoApplicant | null {
		// Note: For now, should only have 1 Co-Applicant
		return this.coApplicants.length > 0 ? this.coApplicants[0] : null;
	}

	public isMainApplicant(customerId: UUID): boolean {
		return this.customerId === customerId;
	}

	public getCoApplicantByCustomer(
		coApplicantCustomer: ICustomer
	): ICoApplicant | null {
		// TODO: Eventually check phone number also.
		// Since we don't have an easy way for user to update information, lets only check email for now.
		return (
			this.coApplicants.find(
				(c) =>
					c.email &&
					c.email.toLocaleLowerCase() ===
						coApplicantCustomer.email.toLocaleLowerCase()
			) ?? null
		);
	}

	public getCurrentAddress(): Address | null {
		return (
			this.mainApplicant.addresses.filter((a) => a.isCurrent).pop() ??
			null
		);
	}

	public getTotalAnnualIncome(): number {
		let total = 0;
		const currentEmployment = this.mainApplicant.employments.find(
			(i) => i.isCurrent
		);
		const coApplicant = this.getCoApplicant();

		if (!currentEmployment?.incomeAmount) {
			total += this.mainApplicant.grossIncomeApprox ?? 0;
		} else {
			total += this.mainApplicant.employments.reduce(
				(result, v) => result + v.getAnnualAmount(),
				0
			);
		}

		// Only 30% of additional income can be used.
		total +=
			this.mainApplicant.additionalIncomes
				.map((y) => y.getAnnualAmount())
				.reduce((result, v) => result + v, 0) * 0.3;

		if (coApplicant) {
			total += coApplicant.grossIncomeApprox ?? 0;
		}

		return total;
	}

	public hasMissingDocuments(): boolean {
		const currentEmployment = this.mainApplicant.employments?.find(
			(i) => i.isCurrent === true
		);
		if (
			currentEmployment === undefined ||
			this.customerDocuments === undefined
		) {
			return true;
		}
		const customerDocuments = new CustomerDocuments(this.customerDocuments);
		const neededDocuments = CustomerDocuments.getNeededDocuments(
			this,
			currentEmployment.incomeType
		);
		const hasMainApplicantMissingDocuments = neededDocuments.some(
			(d) => customerDocuments.getState(d) !== DocumentStateType.Received
		);

		const coApplicant = this.getCoApplicant();
		if (
			!hasMainApplicantMissingDocuments &&
			this.applyingWithCoApplicants &&
			coApplicant &&
			coApplicant.documents
		) {
			const coApplicantDocuments = new CoApplicantDocuments(
				coApplicant.documents
			);
			const coApplicantNeededDocuments =
				CoApplicantDocuments.getNeededDocuments(
					this.applicationType,
					coApplicant.incomeType
				);
			const hasCoApplicantMissingDocuments =
				coApplicantNeededDocuments.some(
					(d) =>
						coApplicantDocuments.getState(d) !==
						DocumentStateType.Received
				);

			return (
				hasMainApplicantMissingDocuments ||
				hasCoApplicantMissingDocuments
			);
		} else {
			return hasMainApplicantMissingDocuments;
		}
	}

	public getHasBankruptcy(): boolean {
		if (this.mainApplicant.hasBankruptcy === YesNo.Yes) {
			if (this.mainApplicant.isBankruptcyLiberated === YesNo.No) {
				return true;
			}
			const minLiberatedYear = new Date(Date.now()).getFullYear() - 2;
			if (
				this.mainApplicant.isBankruptcyLiberated === YesNo.Yes &&
				(this.mainApplicant.bankruptcyYearLiberated === null ||
					this.mainApplicant.bankruptcyYearLiberated >
						minLiberatedYear)
			) {
				return true;
			}
		}
		return false;
	}

	public moveToStage(stage: MortgageStage): void {
		if (stage === MortgageStage.QualifiedCustomer) {
			if (this.stage === MortgageStage.Lead) {
				(this as any).stage = stage;
			}
		} else {
			(this as any).stage = stage;
		}
	}
}
