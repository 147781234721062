import LanguageMenu from 'components/common/menus/languageMenu/LanguageMenu';
import styles from './Header.module.scss';
import AccountMenu from '../menus/accountMenu/AccountMenu';
import { ReactElement } from 'react';
import classnames from 'classnames';
import { useCustomAuth } from '../hooks/useCustomAuth';
import StarRating from '../star-rating/StarRating';
import { ReactComponent as GoogleLogo } from 'assets/images/google-logo.svg';
import { Trans, useTranslation } from 'react-i18next';

export interface HeaderProps {
	className?: string;
	rightSideContent: ReactElement;
	showAccountMenu: boolean;
	hideMenus?: boolean;
}

function rightMenu(showAccountMenu: boolean, requiresLogin: boolean) {
	const { t } = useTranslation();
	if (showAccountMenu && !requiresLogin) {
		return <AccountMenu className={styles.menuItem} />;
	} else {
		return (
			<>
				<div className={styles.starRating}>
					<StarRating
						className={styles.starRatingDesktop}
						rating={4.9}
						starSize={20}
						starSpacing={6}
					/>
					<StarRating
						className={styles.starRatingMobile}
						rating={4.9}
						starSize={15}
						starSpacing={4}
					/>
					<div className={styles.starRatingText}>
						<Trans
							i18nKey="common:google-review"
							t={t}
							values={{ rating: 4.9 }}
							components={[
								<GoogleLogo
									key="0"
									className={styles.googleLogo}
								/>
							]}
						/>
					</div>
				</div>
				<LanguageMenu
					className={classnames(styles.menuItem, styles.languageMenu)}
				/>
			</>
		);
	}
}

export default function Header(props: HeaderProps) {
	const { requiresLogin } = useCustomAuth();
	const { className, rightSideContent, showAccountMenu, hideMenus } = props;
	const hideAllMenus = hideMenus ?? false;

	return (
		<div className={classnames(styles.header, className)}>
			<div className={styles.toolbarContainer}>
				{rightSideContent}
				<div className={styles.rightMenu}>
					{hideAllMenus
						? ''
						: rightMenu(showAccountMenu, requiresLogin)}
				</div>
			</div>
		</div>
	);
}
