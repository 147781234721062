export default class HttpClient {
	public static async fetch(
		input: RequestInfo,
		init: RequestInit | undefined = {}
	): Promise<Response> {
		init = {
			...init,
			headers: {
				...init?.headers
			}
		};

		return await fetch(input, init);
	}

	public static async download(url: string, filename: string) {
		const response = await HttpClient.fetch(url, {
			method: 'GET'
		});

		const objectUrl = window.URL.createObjectURL(await response.blob());
		const link = document.createElement('a');
		link.href = objectUrl;
		link.setAttribute('download', `${filename}`);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
}
