import { ExperimentDto } from '@application/common';
import AmplitudeClient from 'services/AmplitudeClient';

export default class ExperimentHelper {
	public static retrieveExperimentValue(
		experiments: Array<ExperimentDto>,
		id: number
	): number {
		const experiment = experiments.find((e) => e.id === id);

		if (experiment && experiment?.value !== ExperimentDto.ExcludedGroup) {
			const eventSent = window.sessionStorage.getItem(
				`wd-exp-${experiment.id}`
			);
			if (experiment && !eventSent) {
				AmplitudeClient.getInstance().logExperiment(experiment);
				window.sessionStorage.setItem(
					`wd-exp-${experiment.id}`,
					`${experiment.value}`
				);
			}
		}

		return experiment?.value ?? ExperimentDto.ExcludedGroup;
	}
}
