import { ReactComponent as ArrowDropDownIcon } from 'assets/images/chevron-down.svg';
import classnames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import styles from './ExpandableContainer.module.scss';

interface ExpandableContainerProps {
	className?: string;
	expandedClassName?: string;
	buttonClassName?: string;
	contentClassName?: string;
	headerClassName?: string;
	expanded?: boolean;
	isExpandable?: boolean;
	header: ReactElement;
	children: Array<ReactElement> | ReactElement;
	onExpanded?: (expanded: boolean) => void;
}
export default function ExpandableContainer(
	props: ExpandableContainerProps
): JSX.Element {
	const {
		className,
		expandedClassName,
		buttonClassName,
		contentClassName,
		headerClassName,
		header,
		children,
		expanded,
		isExpandable,
		onExpanded
	} = props;
	const [isExpanded, setIsExpanded] = useState(expanded);
	const [canExpand, setCanExpand] = useState(true);
	useEffect(() => {
		setIsExpanded(expanded);
	}, [expanded]);

	useEffect(() => {
		setCanExpand(isExpandable !== false);
	}, [isExpandable]);

	return (
		<div className={classnames(styles.expandableContainer, className)}>
			<div
				className={classnames(
					styles.header,
					!canExpand && styles.headerDisabled,
					headerClassName
				)}
				onClick={(e) => {
					e.stopPropagation();

					if (!canExpand) {
						return;
					}

					setIsExpanded(!isExpanded);
					if (onExpanded) {
						onExpanded(!isExpanded);
					}
				}}
			>
				{header}
				{canExpand && (
					<ArrowDropDownIcon
						className={classnames(
							styles.buttonArrow,
							buttonClassName,
							isExpanded === true ? styles.up : styles.down
						)}
					/>
				)}
			</div>
			<div
				className={classnames(
					styles.content,
					isExpanded === true ? styles.expanded : styles.collapsed,
					isExpanded && expandedClassName,
					contentClassName
				)}
			>
				{children}
			</div>
		</div>
	);
}
