import { UUID } from '../../value-objects/UUID';
import IStepGroupMetadata from './IStepGroupMetadata';
import IStepMetadata from './IStepMetadata';
import { ProgressState } from './ProgressState';
import { StepAction } from './StepAction';
import { StepType } from './StepType';

export default class StepGroupMetadata implements IStepGroupMetadata {
	public readonly id: string;
	public readonly name: string | undefined;
	public readonly type: StepType;
	public readonly steps: Array<IStepMetadata>;
	public readonly progressStep: number;
	public readonly action: StepAction;
	public readonly canDelete: boolean;
	public readonly deleteUrl: string;
	public readonly redirectUrl?: string | undefined;
	public get isPII(): boolean {
		return true;
	}
	public get instanceId(): UUID | undefined {
		return this.steps[0]?.instanceId as UUID;
	}
	public get progressState(): ProgressState {
		return this.calculateProgess();
	}

	constructor(object?: any) {
		this.id = '';
		this.name = object?.name ?? '';
		this.type = StepType.Group;
		this.action = StepAction.None;
		this.steps = object?.steps ?? new Array<IStepMetadata>();
		this.canDelete = object?.canDelete ?? false;
		this.deleteUrl = object?.deleteUrl;
		this.progressStep = 0;
	}

	public json(): any {
		const json = {
			id: this.id,
			name: this.name,
			type: this.type,
			canDelete: this.canDelete,
			deleteUrl: this.deleteUrl,
			action: this.action,
			progressStep: this.progressStep,
			progressState: this.progressState,
			steps: this.steps.map((step: IStepMetadata) => {
				return step.json();
			})
		};
		return json;
	}

	complete(): void {
		return;
	}

	skip(): void {
		this.steps.forEach((s) => s.skip());
	}

	current(): void {
		return;
	}

	none(): void {
		return;
	}

	private calculateProgess(): ProgressState {
		if (
			this.steps.every((s) => s.progressState === ProgressState.Completed)
		) {
			return ProgressState.Completed;
		}
		if (
			this.steps.every((s) => s.progressState === ProgressState.Skipped)
		) {
			return ProgressState.Skipped;
		}
		if (this.steps.every((s) => s.progressState === ProgressState.None)) {
			return ProgressState.None;
		}
		return ProgressState.Current;
	}

	hasStep(stepId: string, instanceId: string | undefined) {
		return (
			this.steps.findIndex(
				(s: IStepMetadata) =>
					s.id === stepId && s.instanceId === instanceId
			) !== -1
		);
	}
}
