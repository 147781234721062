import Exception from '../../../exceptions/Exception';
import ISectionMetadata from './ISectionMetadata';
import IStepMetadata from './IStepMetadata';
import IStepWithChildren from './IStepWithChildren';
import { ProgressState } from './ProgressState';
import StepGroupMetadata from './StepGroupMetadata';
import StepMetadata from './StepMetadata';
import StepParentMetadata from './StepParentMetadata';
import { StepType } from './StepType';

export default class SectionMetadata implements ISectionMetadata {
	private _progressStateSkip = false;
	public readonly id: string;
	public readonly name: string;
	public get progressState(): ProgressState {
		return this.calculateProgess();
	}
	public readonly steps: Array<IStepMetadata>;

	constructor(json?: any) {
		this.id = json?.id ?? '';
		this.name = json?.name ?? '';
		this.steps = new Array<IStepMetadata>();

		if (json?.steps) {
			json.steps.forEach((step) => {
				switch (step.type) {
					case StepType.Group:
						this.steps.push(new StepGroupMetadata(step));
						break;
					case undefined:
					case StepType.Standard:
					case StepType.NoDisplay:
						this.steps.push(new StepMetadata(step));
						break;
					case StepType.Parent:
						this.steps.push(new StepParentMetadata(step));
						break;
					default:
						throw new Exception(`Unknown step type '${step.type}'`);
				}
			});
		}
	}

	public getStep(
		stepId: string,
		instanceId: string | undefined
	): IStepMetadata {
		let stepFound: IStepMetadata | null = null;
		this.steps.forEach((s) => {
			if (s.id === stepId && s.instanceId === instanceId) {
				stepFound = s;
			} else if (
				s.type === StepType.Group ||
				s.type === StepType.Parent
			) {
				(s as IStepWithChildren).steps.forEach((s) => {
					if (s.id === stepId && s.instanceId === instanceId) {
						stepFound = s;
					}
				});
			}
		});

		if (stepFound === null) {
			throw new Exception(
				`Step '${stepId}'${
					instanceId ? ` with instance id '${instanceId}'` : ''
				} is invalid in section '${this.id}'.`
			);
		}
		return stepFound;
	}

	public firstStep(): IStepMetadata | null {
		let firstStep = this.steps.length > 0 ? this.steps[0] : null;

		if (
			firstStep?.type === StepType.Group ||
			firstStep?.type === StepType.Parent
		) {
			const stepWithChildren = firstStep as IStepWithChildren;
			firstStep =
				stepWithChildren.steps.length > 0
					? stepWithChildren.steps[0]
					: null;
		}

		return firstStep;
	}

	public lastStep(): IStepMetadata | null {
		let lastStep =
			this.steps.length > 0 ? this.steps[this.steps.length - 1] : null;

		if (
			lastStep?.type === StepType.Group ||
			lastStep?.type === StepType.Parent
		) {
			const stepWithChildren = lastStep as IStepWithChildren;
			lastStep =
				stepWithChildren.steps.length > 0
					? stepWithChildren.steps[stepWithChildren.steps.length - 1]
					: null;
		}

		return lastStep;
	}

	public previousStep(
		stepId: string,
		instanceId: string | undefined
	): IStepMetadata | null {
		let found = false;
		let previousStep: IStepMetadata | null = null;
		this.steps.forEach((s) => {
			if (s.id === stepId && s.instanceId === instanceId) {
				found = true;
			} else if (
				s.type === StepType.Group ||
				s.type === StepType.Parent
			) {
				(s as IStepWithChildren).steps.forEach((s) => {
					if (s.id === stepId && s.instanceId === instanceId) {
						found = true;
					}
					if (!found) {
						previousStep = s;
					}
				});
			} else {
				if (!found) {
					previousStep = s;
				}
			}
		});
		return found ? previousStep : null;
	}

	public nextStep(
		stepId: string,
		instanceId: string | undefined
	): IStepMetadata | null {
		let found = false;
		let nextStep: IStepMetadata | null = null;
		this.steps.forEach((s) => {
			if (s.type === StepType.Group || s.type === StepType.Parent) {
				(s as IStepWithChildren).steps.forEach((s) => {
					if (found) {
						nextStep = s;
						found = false;
					}
					if (s.id === stepId && s.instanceId === instanceId) {
						found = true;
					}
				});
			} else if (s.id === stepId && s.instanceId === instanceId) {
				if (found) {
					nextStep = s;
					found = false;
				}
				found = true;
			} else if (found) {
				nextStep = s;
				found = false;
			}
		});
		return nextStep;
	}

	public json(): any {
		return {
			id: this.id,
			name: this.name,
			progressState: this.progressState,
			steps: this.steps.map((step: IStepMetadata) => {
				return step.json();
			})
		};
	}

	public reCalculateStepProgess(): void {
		const count = this.steps.reduce((result, step) => {
			if (step.type === StepType.Group || step.type === StepType.Parent) {
				return result + (step as IStepWithChildren).steps.length;
			} else if (step.type === StepType.NoDisplay) {
				return result;
			} else {
				return result + 1;
			}
		}, 1);
		const increment = 100 / count;
		let total = increment;
		this.steps.forEach((s) => {
			if (s.type === StepType.Group || s.type === StepType.Parent) {
				const stepWithChildren = s as IStepWithChildren;
				stepWithChildren.steps.forEach((s2) => {
					s2.progressStep = Math.round(total);
					total = total + increment;
				});
			} else if (s.type === StepType.NoDisplay) {
				// Do nothing
			} else {
				s.progressStep = Math.round(total);
				total = total + increment;
			}
		});
	}

	public skip(): void {
		this._progressStateSkip = true;
		this.steps.forEach((s) => s.skip());
	}

	private calculateProgess(): ProgressState {
		if (this.steps.length > 0) {
			if (
				this.steps.every(
					(s) => s.progressState === ProgressState.Completed
				)
			) {
				return ProgressState.Completed;
			}
			if (
				this.steps.every(
					(s) => s.progressState === ProgressState.Skipped
				)
			) {
				return ProgressState.Skipped;
			}
		}

		if (this._progressStateSkip) {
			return ProgressState.Skipped;
		}

		if (this.steps.every((s) => s.progressState === ProgressState.None)) {
			return ProgressState.None;
		}
		return ProgressState.Current;
	}
}
