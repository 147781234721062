import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import stylesDefault from './ThemeDefault.module.scss';
import stylesBlue from './ThemeBlue.module.scss';
import stylesBluePale from './ThemeBluePale.module.scss';

export enum Theme {
	Default,
	Blue,
	BluePale
}

export interface ThemeStyles {
	readonly [key: string]: string;
}

export interface ThemeContext {
	theme: ThemeStyles;
	setTheme: (theme: Theme) => void;
}

const ThemeContext = React.createContext<ThemeContext>({
	theme: stylesDefault,
	setTheme: () => null
});

export function useTheme(): ThemeContext {
	return useContext(ThemeContext);
}

export default function ThemeProvider({ children }): JSX.Element {
	const setTheme = (theme: Theme) => {
		let themeStyles: ThemeStyles;
		switch (theme) {
			case Theme.Blue:
				themeStyles = stylesBlue;
				break;
			case Theme.BluePale:
				themeStyles = stylesBluePale;
				break;
			default:
				themeStyles = stylesDefault;
		}
		setState({ ...state, theme: themeStyles });
	};

	const initState = {
		theme: stylesDefault,
		setTheme: setTheme
	};

	const [state, setState] = useState(initState);
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.className = state.theme.main;
		}
	}, [state]);

	return (
		<ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
	);
}
