export default class AddressHelper {
	public static format(
		addressLine1: string | null | undefined,
		addressLine2: string | null | undefined,
		city: string | null | undefined,
		province: string | null | undefined,
		postalCode: string | null | undefined
	): string {
		return [addressLine1, addressLine2, city, province, postalCode]
			.filter((e) => e !== undefined && e !== '')
			.join(', ');
	}
}
