import classnames from 'classnames';
import { MouseEventHandler } from 'react';
import { Theme } from '../theme/ThemeProvider';
import styles from './Card.module.scss';

export interface Card {
	className?: string;
	theme?: Theme;
	children: any;
	onClick?: MouseEventHandler | undefined;
}

export default function Card(props: Card) {
	const { className, theme, children, onClick } = props;
	return (
		<div
			className={classnames(
				styles.card,
				theme === Theme.Blue && styles.blueCard,
				className
			)}
			onClick={onClick}
		>
			{children}
		</div>
	);
}
