import Dictionary from '../../common/Dictionary';

export enum CompletedSectionState {
	None,
	Skipped,
	Completed,
	NeedsAttention
}

export interface ICompletedSections {
	setState(id: string, state: CompletedSectionState): void;
	getState(id: string): CompletedSectionState;
}

export default class CompletedSections implements ICompletedSections {
	public readonly version = 2;
	private readonly sections: Dictionary<CompletedSectionState>;

	constructor(json?: any) {
		if (json && !json.version) {
			this.sections = new Dictionary<CompletedSectionState>({
				section1:
					json.section1 === true
						? CompletedSectionState.Completed
						: CompletedSectionState.None,
				ipa_start: json.ipa_start ?? CompletedSectionState.None
			});
		} else {
			this.sections = new Dictionary<CompletedSectionState>({
				section1: CompletedSectionState.None,
				ipa_start: CompletedSectionState.None,
				...json
			});
		}
	}

	public setState(id: string, state: CompletedSectionState): void {
		this.sections.set(id, state);
	}

	public getState(id: string): CompletedSectionState {
		let value = this.sections.get(id) as any;
		value = value === true ? CompletedSectionState.Completed : value;
		value = value === false ? CompletedSectionState.None : value;
		return value ?? CompletedSectionState.None;
	}

	public getStates(): Dictionary<CompletedSectionState> {
		return this.sections;
	}

	public json(): any {
		return { ...this.sections.json(), version: this.version };
	}
}
