import CoApplicantDocuments from '../model/co-applicant/CoApplicantDocuments';
import { DocumentStateType } from '../model/value-objects/DocumentStateType';
import { createUUID } from '../model/value-objects/UUID';

export default class CoApplicantDocumentsUnitTestHelper {
	public static create(updates?: any): CoApplicantDocuments {
		return new CoApplicantDocuments({
			applicationId: createUUID(),
			coApplicantId: createUUID(),
			photoId: DocumentStateType.NotRequested,
			payStub: DocumentStateType.NotRequested,
			T4: DocumentStateType.NotRequested,
			T1: DocumentStateType.NotRequested,
			NOA: DocumentStateType.NotRequested,
			mortgageStatement: DocumentStateType.NotRequested,
			...updates
		});
	}
}
