import Exception from '../../exceptions/Exception';
import DateHelper from '../../helpers/DateHelper';
import PhoneHelper from '../../helpers/PhoneHelper';
import Address from '../addresses/Address';
import Employment from '../employments/Employment';
import AdditionalIncome from '../incomes/AdditionalIncome';
import { IpaStatus } from '../ipa/IpaStatus';
import Liability from '../liabilities/Liability';
import { CreditScore } from '../value-objects/CreditScore';
import { IncomeType } from '../value-objects/IncomeType';
import { Language } from '../value-objects/Language';
import { ResidentialSituationType } from '../value-objects/ResidentialSituationType';
import { UUID } from '../value-objects/UUID';
import { YesNo } from '../value-objects/YesNo';
import { IMainApplicant } from './IMainApplicant';

export default class MainApplicant implements IMainApplicant {
	public readonly customerId: UUID;
	public readonly applicationId: UUID;
	public readonly firstName: string | null;
	public readonly lastName: string | null;
	public readonly language: Language | null;
	public readonly email: string | null;
	public readonly homePhone: string | null;
	public readonly mobilePhone: string | null;
	public readonly workPhone: string | null;
	public readonly birthdate: Date | null;
	public readonly ficoScore: number | null; // Should be removed from Public Domain
	public readonly creditScore: CreditScore | null;
	public readonly incomeType: IncomeType | null;
	public readonly employmentYears: number | null;
	public readonly employmentMonths: number | null;
	public readonly isFirstTimeBuyer: YesNo | null;
	public readonly hasBankruptcy: YesNo | null;
	public readonly isBankruptcyLiberated: YesNo | null;
	public readonly bankruptcyYearLiberated: number | null;
	public readonly grossIncomeApprox: number | null;
	public readonly residentialSituation: ResidentialSituationType | null;
	public readonly rentAmount: number | null;
	public readonly addresses: Array<Address>;
	public readonly employments: Array<Employment>;
	public readonly additionalIncomes: Array<AdditionalIncome>;
	public readonly liabilities: Array<Liability>;
	public readonly ipaStatus: IpaStatus | null;
	public readonly ipaSentDate: Date | null;

	constructor(input: IMainApplicant) {
		this.customerId = input.customerId;
		this.applicationId = input.applicationId;
		this.firstName = input.firstName;
		this.lastName = input.lastName;
		this.language = input.language;
		this.email = input.email;
		this.homePhone = PhoneHelper.formatNullable(input.homePhone, true);
		this.mobilePhone = PhoneHelper.formatNullable(input.mobilePhone, true);
		this.workPhone = PhoneHelper.formatNullable(input.workPhone, true);
		this.birthdate = DateHelper.parse(input.birthdate, null);
		this.ficoScore = input.ficoScore;
		this.creditScore = input.creditScore;
		this.incomeType = input.incomeType;
		this.employmentYears = input.employmentYears;
		this.employmentMonths = input.employmentMonths;
		this.isFirstTimeBuyer = input.isFirstTimeBuyer;
		this.hasBankruptcy = input.hasBankruptcy;
		this.isBankruptcyLiberated =
			input.hasBankruptcy === YesNo.Yes
				? input.isBankruptcyLiberated
				: null;
		this.bankruptcyYearLiberated =
			input.isBankruptcyLiberated === YesNo.Yes
				? input.bankruptcyYearLiberated
				: null;
		this.grossIncomeApprox = input.grossIncomeApprox;
		this.residentialSituation = input.residentialSituation;
		this.rentAmount = input.rentAmount;
		this.addresses = input.addresses.map((v) => new Address(v));
		this.employments = input.employments.map((v) => new Employment(v));
		this.additionalIncomes = input.additionalIncomes.map(
			(v) => new AdditionalIncome(v)
		);
		this.liabilities = input.liabilities.map((v) => new Liability(v));
		this.ipaStatus = input.ipaStatus;
		this.ipaSentDate = input.ipaSentDate;
	}

	public getCurrentAddress(): Address | null {
		return this.addresses.find((a) => a.isCurrent) ?? null;
	}

	public setCurrentAddress(address: Address): void {
		const index = this.addresses.findIndex((a) => a.isCurrent);
		const newAddress = new Address({
			...address,
			isCurrent: true
		});
		if (index >= 0) {
			this.addresses[index] = newAddress;
		} else {
			this.addresses.push(newAddress);
		}
	}

	public addAddress(address: Address): void {
		this.addresses.push(address);
		this.updateCurrentAddress();
	}

	public updateAddress(address: Address): void {
		const indexToUpdate = this.addresses.findIndex(
			(l) => l.id === address.id
		);
		if (indexToUpdate === -1) {
			throw new Exception('Cannot update, address does not exists');
		}
		this.addresses[indexToUpdate] = address;
		this.updateCurrentAddress();
	}

	public deleteAddress(address: Address): void {
		const indexToDelete = this.addresses.findIndex(
			(l) => l.id === address.id
		);
		if (indexToDelete === -1) {
			throw new Exception('Cannot delete, address does not exists');
		}
		this.addresses.splice(indexToDelete, 1);
		this.updateCurrentAddress();
	}

	public addEmployment(employment: Employment): void {
		this.employments.push(employment);
		this.updateCurrentEmployment();
	}

	public updateEmployment(employment: Employment): void {
		const indexToUpdate = this.employments.findIndex(
			(l) => l.id === employment.id
		);
		if (indexToUpdate === -1) {
			throw new Exception('Cannot update, employment does not exists');
		}
		this.employments[indexToUpdate] = employment;
		this.updateCurrentEmployment();
	}

	public deleteEmployment(employment: Employment): void {
		const indexToDelete = this.employments.findIndex(
			(l) => l.id === employment.id
		);
		if (indexToDelete === -1) {
			throw new Exception('Cannot delete, employment does not exists');
		}
		this.employments.splice(indexToDelete, 1);
		this.updateCurrentEmployment();
	}

	public addAdditionalIncome(income: AdditionalIncome): void {
		const index = this.additionalIncomes.findIndex(
			(l) => l.id === income.id
		);
		if (index !== -1) {
			throw new Exception('Cannot add, income already exists');
		}
		this.additionalIncomes.push(income);
	}

	public updateAdditionalIncome(income: AdditionalIncome): void {
		const indexToUpdate = this.additionalIncomes.findIndex(
			(l) => l.id === income.id
		);
		if (indexToUpdate === -1) {
			throw new Exception('Cannot update, income does not exists');
		}
		this.additionalIncomes[indexToUpdate] = income;
	}

	public deleteAdditionalIncome(income: AdditionalIncome): void {
		const indexToDelete = this.additionalIncomes.findIndex(
			(l) => l.id === income.id
		);
		if (indexToDelete === -1) {
			throw new Exception('Cannot delete, income does not exists');
		}
		this.additionalIncomes.splice(indexToDelete, 1);
	}

	public static create(
		customerId: UUID,
		applicationId: UUID,
		instance?: Partial<MainApplicant>
	): MainApplicant {
		return new MainApplicant({
			customerId: customerId,
			applicationId: applicationId,
			firstName: instance?.firstName ?? null,
			lastName: instance?.lastName ?? null,
			language: instance?.language ?? null,
			email: instance?.email ?? null,
			homePhone: instance?.homePhone ?? null,
			mobilePhone: instance?.mobilePhone ?? null,
			workPhone: instance?.workPhone ?? null,
			birthdate: instance?.birthdate ?? null,
			ficoScore: instance?.ficoScore ?? null,
			creditScore: instance?.creditScore ?? null,
			incomeType: instance?.incomeType ?? null,
			employmentYears: instance?.employmentYears ?? null,
			employmentMonths: instance?.employmentMonths ?? null,
			isFirstTimeBuyer: instance?.isFirstTimeBuyer ?? null,
			hasBankruptcy: instance?.hasBankruptcy ?? null,
			isBankruptcyLiberated: instance?.isBankruptcyLiberated ?? null,
			bankruptcyYearLiberated: instance?.bankruptcyYearLiberated ?? null,
			grossIncomeApprox: instance?.grossIncomeApprox ?? null,
			residentialSituation: instance?.residentialSituation ?? null,
			rentAmount: instance?.rentAmount ?? null,
			addresses: instance?.addresses ?? [],
			employments: instance?.employments ?? [],
			additionalIncomes: instance?.additionalIncomes ?? [],
			liabilities: instance?.liabilities ?? [],
			ipaStatus: instance?.ipaStatus ?? null,
			ipaSentDate: instance?.ipaSentDate ?? null
		});
	}

	private updateCurrentAddress(): void {
		const currentAddresses = this.addresses.filter((a) => a.isCurrent);
		if (currentAddresses.length > 1) {
			// More than 1 current
			let firstCurrentFound = false;
			(this.addresses as any) = this.addresses.map((a) => {
				if (a.isCurrent && !firstCurrentFound) {
					firstCurrentFound = true;
					return new Address({ ...a, isCurrent: true });
				} else {
					return new Address({ ...a, isCurrent: false });
				}
			});
		} else if (currentAddresses.length === 0 && this.addresses.length > 0) {
			// No current
			this.addresses[0] = new Address({
				...this.addresses[0],
				isCurrent: true
			});
		}
	}

	private updateCurrentEmployment(): void {
		const currentEmployments = this.employments.filter((e) => e.isCurrent);
		if (currentEmployments.length > 1) {
			// More than 1 current
			let firstCurrentFound = false;
			(this.employments as any) = this.employments.map((e) => {
				if (e.isCurrent && !firstCurrentFound) {
					firstCurrentFound = true;
					return new Employment({ ...e, isCurrent: true });
				} else {
					return new Employment({ ...e, isCurrent: false });
				}
			});
		} else if (
			currentEmployments.length === 0 &&
			this.employments.length > 0
		) {
			// No current
			this.employments[0] = new Employment({
				...this.employments[0],
				isCurrent: true
			});
		}
	}
}
