import ArgumentException from '../../exceptions/ArgumentException';
import Exception from '../../exceptions/Exception';
import BaseApplication from '../application/BaseApplication';
import IBaseApplication from '../application/IBaseApplication';
import INewMortgageApplication from '../application/INewMortgageApplication';
import NewMortgageApplication from '../application/NewMortgageApplication';
import BaseEntity from '../common/BaseEntity';
import { EntityTypeEnum } from '../common/EntityTypeEnum';
import { ApplicationType } from '../value-objects/ApplicationType';
import { ContactStage } from '../value-objects/ContactStage';
import { DocumentStateType } from '../value-objects/DocumentStateType';
import { DocumentType } from '../value-objects/DocumentType';
import { IncomeType } from '../value-objects/IncomeType';
import { UUID } from '../value-objects/UUID';
import { YesNo } from '../value-objects/YesNo';
import ICustomerDocuments from './ICustomerDocuments';

export interface ICustomerDocumentItem {
	documentType: DocumentType;
	state: DocumentStateType;
}
export default class CustomerDocuments
	extends BaseEntity
	implements ICustomerDocuments
{
	private static readonly applicableDocumentTypes = Object.values(
		DocumentType
	).filter((t) => t != DocumentType.Miscellaneous);
	public readonly applicationId: UUID;
	public readonly customerId: UUID;
	public readonly isStarted: boolean;
	public readonly photoId: DocumentStateType;
	public readonly payStub: DocumentStateType;
	public readonly T4: DocumentStateType;
	public readonly T4A: DocumentStateType;
	public readonly T1: DocumentStateType;
	public readonly NOA: DocumentStateType;
	public readonly mortgageStatement: DocumentStateType;
	public readonly bankStatement90Days: DocumentStateType;
	public readonly employmentLetter: DocumentStateType;
	public readonly secondaryId: DocumentStateType;
	public readonly voidCheque: DocumentStateType;
	public readonly confirmationOfCondoFees: DocumentStateType;
	public readonly rentalLeaseAgreement: DocumentStateType;
	public readonly municipalTaxBill: DocumentStateType;
	public readonly schoolTaxBill: DocumentStateType;
	public readonly selfEmployedFinancialStatement: DocumentStateType;
	public readonly savingAccountStatement: DocumentStateType;
	public readonly promiseOfPurchase: DocumentStateType;
	public readonly letterOfAcceptance: DocumentStateType;
	public readonly mortgagePropertyBeingSoldStatement: DocumentStateType;
	public readonly proofOfGiftAmount: DocumentStateType;
	public readonly purchaseOffer: DocumentStateType;
	public readonly sellersDeclaration: DocumentStateType;
	public readonly mlsListing: DocumentStateType;
	public readonly preleminaryPurchaseContract: DocumentStateType;
	public readonly renovationOrPlanQuotes: DocumentStateType;
	public readonly signedLeased: DocumentStateType;

	constructor(input: ICustomerDocuments) {
		super({ entityType: EntityTypeEnum.CustomerDocuments });
		this.applicationId = input.applicationId;
		this.customerId = input.customerId;
		this.isStarted = input.isStarted;
		this.photoId = input.photoId;
		this.payStub = input.payStub;
		this.T4 = input.T4;
		this.T4A = input.T4A;
		this.T1 = input.T1;
		this.NOA = input.NOA;
		this.mortgageStatement = input.mortgageStatement;
		this.bankStatement90Days = input.bankStatement90Days;
		this.employmentLetter = input.employmentLetter;
		this.secondaryId = input.secondaryId;
		this.voidCheque = input.voidCheque;
		this.confirmationOfCondoFees = input.confirmationOfCondoFees;
		this.rentalLeaseAgreement = input.rentalLeaseAgreement;
		this.municipalTaxBill = input.municipalTaxBill;
		this.schoolTaxBill = input.schoolTaxBill;
		this.selfEmployedFinancialStatement =
			input.selfEmployedFinancialStatement;
		this.savingAccountStatement = input.savingAccountStatement;
		this.promiseOfPurchase = input.promiseOfPurchase;
		this.letterOfAcceptance = input.letterOfAcceptance;
		this.mortgagePropertyBeingSoldStatement =
			input.mortgagePropertyBeingSoldStatement;
		this.proofOfGiftAmount = input.proofOfGiftAmount;
		this.purchaseOffer = input.purchaseOffer;
		this.sellersDeclaration = input.sellersDeclaration;
		this.mlsListing = input.mlsListing;
		this.preleminaryPurchaseContract = input.preleminaryPurchaseContract;
		this.renovationOrPlanQuotes = input.renovationOrPlanQuotes;
		this.signedLeased = input.signedLeased;
	}

	public getState(documentType: DocumentType): DocumentStateType {
		const fieldName = CustomerDocuments.getFieldName(documentType);
		return this[fieldName];
	}

	public list(): Array<ICustomerDocumentItem> {
		return CustomerDocuments.applicableDocumentTypes.map((t) => {
			return {
				documentType: t,
				state: this.getState(t)
			};
		});
	}

	public listMissing(application: IBaseApplication): Array<DocumentType> {
		const currentEmployment = application.mainApplicant.employments.find(
			(i) => i.isCurrent
		);

		if (currentEmployment === undefined) {
			throw new Exception(
				'CustomerDocuments.listMissing. Current application has no current employment.'
			);
		}

		const documentsNeeded = CustomerDocuments.getNeededDocuments(
			application,
			currentEmployment.incomeType
		);
		return documentsNeeded.filter(
			(d) => this.getState(d) !== DocumentStateType.Received
		);
	}

	public static create(
		application: IBaseApplication,
		currentIncomeType: IncomeType | null
	): CustomerDocuments {
		const oldDocuments = application.customerDocuments;
		let result = new CustomerDocuments({
			customerId: application.customerId,
			applicationId: application.applicationId,
			isStarted: oldDocuments?.isStarted ?? false
		} as ICustomerDocuments);

		CustomerDocuments.applicableDocumentTypes.forEach((t) => {
			result = CustomerDocuments.update(
				result,
				t,
				CustomerDocuments.getDefaultState(
					t,
					currentIncomeType,
					application,
					oldDocuments
				)
			);
		});

		return result;
	}

	public static update(
		customerDocuments: ICustomerDocuments | undefined,
		documentType: DocumentType,
		state: DocumentStateType
	): CustomerDocuments {
		const fieldName = CustomerDocuments.getFieldName(documentType);

		return new CustomerDocuments({
			...customerDocuments,
			[fieldName]: state
		} as ICustomerDocuments);
	}

	public static getNeededDocuments(
		application: IBaseApplication,
		incomeType: IncomeType | null
	): Array<DocumentType> {
		let result: Array<DocumentType> = [];
		if (incomeType === null) {
			return result;
		}

		if (incomeType === IncomeType.SelfEmployed) {
			result = [DocumentType.PhotoId, DocumentType.T1, DocumentType.NOA];
		} else if (
			incomeType === IncomeType.None ||
			incomeType === IncomeType.Pension
		) {
			result = [DocumentType.PhotoId, DocumentType.T4A, DocumentType.NOA];
		} else {
			result = [
				DocumentType.PhotoId,
				DocumentType.PayStub,
				DocumentType.T4
			];
		}

		result.push(DocumentType.BankStatement90Days);
		result.push(DocumentType.EmploymentLetter);
		result.push(DocumentType.SecondaryId);
		result.push(DocumentType.VoidCheque);
		result.push(DocumentType.ConfirmationOfCondoFees);
		result.push(DocumentType.RentalLeaseAgreement);
		result.push(DocumentType.MunicipalTaxBill);
		result.push(DocumentType.SchoolTaxBill);
		result.push(DocumentType.SelfEmployedFinancialStatement);

		if (application.applicationType === ApplicationType.NewMortgage) {
			const newMortgageApplication = new NewMortgageApplication({
				...(application as INewMortgageApplication)
			});

			if (
				newMortgageApplication.contactStage === ContactStage.MadeOffer
			) {
				result.push(DocumentType.SavingAccountStatement);
				result.push(DocumentType.PromiseOfPurchase);
				result.push(DocumentType.LetterOfAcceptance);
				result.push(DocumentType.MortgagePropertyBeingSoldStatement);
				result.push(DocumentType.ProofOfGiftAmount);
			}

			if (newMortgageApplication.willYouLiveInProperty === YesNo.Yes) {
				result.push(DocumentType.MlsListing);
				result.push(DocumentType.PreleminaryPurchaseContract);
				result.push(DocumentType.RenovationOrPlanQuotes);
				result.push(DocumentType.SellersDeclaration);
				result.push(DocumentType.PurchaseOffer);
			} else {
				result.push(DocumentType.MlsListing);
				result.push(DocumentType.SignedLeased);
				result.push(DocumentType.SellersDeclaration);
				result.push(DocumentType.PurchaseOffer);
			}
		} else {
			result.push(DocumentType.MortgageStatement);
		}

		return result;
	}

	public static isDocumentApplicable(
		application: BaseApplication,
		documentType: DocumentType
	): boolean {
		const currentEmployment = application.mainApplicant.employments.find(
			(i) => i.isCurrent
		);
		if (currentEmployment === undefined) {
			throw new Exception(
				'CustomerDocuments.isDocumentApplicable. Current application has no current employment.'
			);
		}

		return this.getNeededDocuments(
			application,
			currentEmployment.incomeType
		).includes(documentType);
	}

	public static getDefaultState(
		documentType: DocumentType,
		incomeType: IncomeType | null,
		application: IBaseApplication,
		oldDocuments?: ICustomerDocuments
	): DocumentStateType {
		if (incomeType === null) {
			throw new ArgumentException('incomeType', 'null');
		}
		const requestedDocuments = this.getNeededDocuments(
			application,
			incomeType
		);
		const newState = requestedDocuments.includes(documentType)
			? DocumentStateType.Requested
			: DocumentStateType.NotRequested;
		const oldCustomerDocuments = oldDocuments
			? new CustomerDocuments(oldDocuments)
			: undefined;

		return CustomerDocuments.getDocumentState(
			oldCustomerDocuments?.getState(documentType),
			newState
		);
	}

	public static getFieldName(documentType: DocumentType): string {
		if (
			!CustomerDocuments.applicableDocumentTypes.find(
				(x) => x === documentType
			)
		) {
			throw new ArgumentException('documentType', documentType);
		}

		let nextIsCapitalized = false;
		return (documentType as string).split('').reduce((p, c) => {
			if (c === '-') {
				nextIsCapitalized = true;
			} else {
				p += nextIsCapitalized ? c.toLocaleUpperCase() : c;
				nextIsCapitalized = false;
			}
			return p;
		});
	}

	private static getDocumentState(
		existingState: DocumentStateType | undefined,
		newState: DocumentStateType
	): DocumentStateType {
		return existingState === DocumentStateType.Received
			? DocumentStateType.Received
			: newState;
	}
}
