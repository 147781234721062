import { useState } from 'react';
import HttpClient from 'services/HttpClient';
import { useLanguageContext } from '../context/LanguageProvider';
import { useCustomAuth } from './useCustomAuth';

export type AuthenticatedApiCall = (
	accessToken: string | null,
	url: string,
	body?: any
) => Promise<AuthenticatedApiCallResponse>;

export type AuthenticatedApiCallResponse = {
	status?: number;
	data?: any;
	error?: Error;
};

const useCallApi = (
	apiCall: AuthenticatedApiCall,
	needsAuthentication: boolean
): any => {
	const { isTempAuthenticated, getAccessTokenSilently } = useCustomAuth();
	const [isBusy, setIsBusy] = useState(false);

	const callApi = async (
		url: string,
		body?: any
	): Promise<AuthenticatedApiCallResponse> => {
		try {
			setIsBusy(true);
			let accessToken: string | null = null;
			if (needsAuthentication) {
				accessToken = !isTempAuthenticated
					? await getAccessTokenSilently()
					: null;
			}
			return await apiCall(accessToken, url, body);
		} catch (error) {
			return {
				error: error
			} as AuthenticatedApiCallResponse;
		} finally {
			setIsBusy(false);
		}
	};

	return {
		isBusy,
		callApi
	};
};

const useCallApiMethod = (
	method: string,
	needsAuthentication: boolean
): any => {
	const { language } = useLanguageContext();
	return useCallApi(
		async (
			accessToken: string | null,
			url: string,
			body?: any
		): Promise<AuthenticatedApiCallResponse> => {
			const authorizationHeader =
				accessToken != null
					? {
							Authorization: `Bearer ${accessToken}`
					  }
					: undefined;
			const headers: HeadersInit = {
				'Content-Type': 'application/json',
				'Accept-Language': language,
				...authorizationHeader
			};
			const fetchOptions: RequestInit = {
				method: method,
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body)
			};
			const response = await HttpClient.fetch(url, fetchOptions);
			return {
				status: response.status,
				data:
					response.status !== 204 ? await response.json() : undefined
			} as AuthenticatedApiCallResponse;
		},
		needsAuthentication
	);
};

export const useCallGetApi = (needsAuthentication = true): any => {
	const state = useCallApiMethod('GET', needsAuthentication);
	return { isBusy: state.isBusy, callGetApi: state.callApi };
};

export const useCallPutApi = (needsAuthentication = true): any => {
	const state = useCallApiMethod('PUT', needsAuthentication);
	return { isBusy: state.isBusy, callPutApi: state.callApi };
};

export const useCallPostApi = (needsAuthentication = true): any => {
	const state = useCallApiMethod('POST', needsAuthentication);
	return { isBusy: state.isBusy, callPostApi: state.callApi };
};

export const useCallDeleteApi = (needsAuthentication = true): any => {
	const state = useCallApiMethod('DELETE', needsAuthentication);
	return { isBusy: state.isBusy, callDeleteApi: state.callApi };
};
