export type KeyValue<V> = { [key: string]: V };
type MapFuncton<V, X> = (v: V, i: number) => X;

export default class Dictionary<V> extends Map<string, V> {
	constructor(entries?: KeyValue<V> | undefined) {
		super(entries ? Object.entries(entries) : undefined);
	}

	public json(): any {
		return Object.fromEntries(this);
	}

	public push(key: keyof V, array: Array<V>): void {
		array.forEach((v) => {
			this.set(v[key as string], v);
		});
	}

	public map<X>(map: MapFuncton<V, X>): X[] {
		const mapResult: Array<X> = [];
		let i = 0;
		super.forEach((v) => {
			mapResult.push(map(v, i));
			i++;
		});
		return mapResult;
	}

	public array(): V[] {
		return this.map((v) => v);
	}
}
