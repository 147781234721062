import { z } from 'zod';
import Exception from '../../../exceptions/Exception';
import { RateTerm } from '../../value-objects/RateTerm';
import LtvRate from './LtvRate';

export const LenderLtvRateSchema = z.object({
	rates: z.array(LtvRate.Schema)
});

type LenderLtvRateInput = z.infer<typeof LenderLtvRateSchema>;

export default class LenderLtvRate {
	public static readonly Schema = LenderLtvRateSchema;
	public readonly rates: Array<LtvRate>;

	constructor(input: LenderLtvRateInput) {
		const schema = LenderLtvRateSchema.parse(input);
		this.rates = schema.rates.map((r) => new LtvRate(r));
	}

	public getLTVRate(
		loanToValue: number,
		isInsured: boolean,
		term: RateTerm
	): LtvRate {
		let result: Array<LtvRate> = [];
		if (loanToValue >= 1) {
			result = [
				this.rates
					.filter((r) => r.isInsured === isInsured && r.term === term)
					.reduce((prev, current) =>
						prev.loanToValueMax > current.loanToValueMax
							? prev
							: current
					)
			];
		} else {
			result = this.rates.filter(
				(r) =>
					loanToValue >= r.loanToValueMin &&
					loanToValue < r.loanToValueMax &&
					r.isInsured === isInsured
			);
		}
		result = result.filter((r) => r.term === term);
		if (result.length !== 1) {
			throw new Exception(
				`${result.length} rate found for LTV: ${loanToValue}, isInsured: ${isInsured}, term: ${term}.`
			);
		}
		return result[0];
	}
}
