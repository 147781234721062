import { Province } from '../value-objects/Province';
import { UUID } from '../value-objects/UUID';

export default class CustomerDto {
	constructor(
		public readonly id: UUID,
		public readonly firstName: string,
		public readonly lastName: string,
		public readonly email: string,
		public readonly mobilePhone: string,
		public readonly province?: Province
	) {}
}
