import React, { useCallback } from 'react';
import styles from './LanguageMenu.module.scss';
import { ReactComponent as ArrowDropDownIcon } from 'assets/images/chevron-down.svg';
import { availableLanguages } from '../../../../i18n';
import {
	useNavigate,
	useLocation,
	createSearchParams,
	useSearchParams
} from 'react-router-dom';
import { useEffectOnce } from 'components/common/hooks/useEffectOnce';
import { useLanguageContext } from 'components/common/context/LanguageProvider';

interface LanguageMenuProps {
	className: string;
}

export default function LanguageMenu(props: LanguageMenuProps) {
	const { language, changeLanguage } = useLanguageContext();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const updateLanguage = useCallback(
		(lng) => {
			changeLanguage(lng);
			navigate({
				pathname: `/${lng}/${pathname.split('/').slice(2).join('/')}`,
				search: createSearchParams(searchParams).toString()
			});
		},
		[navigate, changeLanguage]
	);

	const [open, setOpen] = React.useState(false);
	const drop = React.useRef<any>();
	function handleClick(e) {
		if (
			drop.current &&
			!e.target.closest(`.${drop.current.className}`) &&
			open
		) {
			setOpen(false);
		}
	}

	useEffectOnce(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	});

	return (
		<div className={(styles.container, props.className)} ref={drop}>
			<div
				className={styles.button}
				ref={drop}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<span className={styles.buttonText}>{language}</span>
				<ArrowDropDownIcon className={styles.buttonArrow} />
				{open && (
					<div
						className={styles.dropDown}
						style={{ backgroundColor: '#feffff' }}
					>
						{availableLanguages.map((lng: string, i: any) => {
							return (
								<div
									key={i}
									className={styles.dropDownItem}
									onClick={() => {
										setOpen(false);
										updateLanguage(lng);
									}}
								>
									{lng}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}
