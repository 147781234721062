import Exception from '../../../exceptions/Exception';
import { ProgressState } from './ProgressState';
import ISectionMetadata from './ISectionMetadata';
import SectionMetadata from './SectionMetadata';
import IStepMetadata from './IStepMetadata';
import { StepType } from './StepType';
import IStepWithChildren from './IStepWithChildren';
import ArgumentException from '../../../exceptions/ArgumentException';
import IGetApprovalMetadata from './IGetApprovalMetadata';
import IGetApprovalStep from './IGetApprovalStep';

export default class GetApprovalMetadataDto implements IGetApprovalMetadata {
	public readonly sections: Array<ISectionMetadata>;
	constructor(json?: { sections: Array<any> } | undefined) {
		this.sections = new Array<ISectionMetadata>();
		if (json) {
			this.loadJson(json);
		}
	}

	private loadJson(json: any): void {
		if (
			json.sections === null ||
			json.sections === undefined ||
			json.sections === ''
		) {
			throw new ArgumentException('json.sections', json.sections);
		}

		try {
			json.sections.forEach((section) => {
				this.sections.push(new SectionMetadata(section));
			});
		} catch (ex) {
			throw new ArgumentException('json.sections', JSON.stringify(json));
		}
	}

	public json(): any {
		const sections = this.sections.map((section) => {
			return {
				...section.json()
			};
		});

		return {
			sections: sections
		};
	}

	public getSection(sectionId: string): ISectionMetadata {
		const section = this.sections.find((s) => s.id === sectionId);
		if (section === undefined) {
			throw new Exception(`Section '${sectionId}' is invalid.`);
		}
		return section;
	}

	public getStep(
		sectionId?: string,
		stepId?: string,
		instanceId?: string
	): IGetApprovalStep {
		let section: ISectionMetadata;
		let step: IStepMetadata;

		if (!sectionId || !stepId) {
			return this.getCurrent();
		} else {
			section = this.getSection(sectionId);
			step = section.getStep(stepId, instanceId);
		}

		return {
			section: section,
			step: step
		};
	}

	public next(
		sectionId: string,
		stepId: string,
		instanceId: string | undefined
	): IGetApprovalStep | null {
		const section = this.getSection(sectionId);
		const step = section.nextStep(stepId, instanceId);
		if (step) {
			return {
				section: section,
				step: step
			};
		} else {
			const nextSection = this.nextSection(sectionId);
			const firstStep = nextSection?.firstStep();
			if (nextSection && firstStep) {
				return {
					section: nextSection,
					step: firstStep
				};
			} else {
				return null;
			}
		}
	}

	public previous(
		sectionId: string,
		stepId: string,
		instanceId: string | undefined
	): IGetApprovalStep | null {
		const section = this.getSection(sectionId);
		const step = section.previousStep(stepId, instanceId);
		if (step) {
			return {
				section: section,
				step: step
			};
		} else {
			const nextSection = this.previousSection(sectionId);
			const lastStep = nextSection?.lastStep();
			if (nextSection && lastStep) {
				return {
					section: nextSection,
					step: lastStep
				};
			} else {
				return null;
			}
		}
	}

	public complete(
		sectionId: string,
		stepId: string,
		instanceId: string | undefined
	) {
		const current = this.getStep(sectionId, stepId, instanceId);
		if (current.step.progressState === ProgressState.None) {
			throw new Exception(
				`Section '${sectionId}' with step '${stepId}'${
					instanceId ? ` and with instance id '${instanceId}'` : ''
				} is not the current one. Cannot complete it.`
			);
		}

		current.step.complete();

		const next = this.next(
			current.section.id,
			current.step.id,
			current.step.instanceId
		);
		if (next) {
			next.step.current();
		}
	}

	private getCurrent(): IGetApprovalStep {
		let section: ISectionMetadata;
		let step: IStepMetadata;
		const currentSectionIndex = this.sections.findIndex(
			(s) => s.progressState === ProgressState.Current
		);
		if (currentSectionIndex >= 0) {
			section = this.sections[currentSectionIndex];
		} else {
			section = this.sections[this.sections.length - 1];
		}
		let currentStepIndex = section.steps.findIndex(
			(s) => s.progressState === ProgressState.Current
		);
		if (currentStepIndex >= 0) {
			step = section.steps[currentStepIndex];
		} else {
			step = section.steps[section.steps.length - 1];
		}

		if (step.type === StepType.Group || step.type === StepType.Parent) {
			const stepWithChildren = step as IStepWithChildren;
			currentStepIndex = stepWithChildren.steps.findIndex(
				(s) => s.progressState === ProgressState.Current
			);

			if (currentStepIndex >= 0) {
				step = stepWithChildren.steps[currentStepIndex];
			} else {
				step = stepWithChildren.steps[section.steps.length - 1];
			}
		}

		return {
			section: section,
			step: step
		};
	}

	private previousSection(sectionId: string): ISectionMetadata | null {
		const sectionIndex = this.sections.findIndex((s) => s.id === sectionId);
		return this.sections[sectionIndex - 1];
	}

	private nextSection(sectionId: string): ISectionMetadata | null {
		const sectionIndex = this.sections.findIndex((s) => s.id === sectionId);
		return this.sections[sectionIndex + 1];
	}
}
