export class RateSavingRateDto {
	constructor(
		public readonly amount: number,
		public readonly percent: number
	) {}
}

export default class RateSavingDetailsDto {
	constructor(
		public readonly fixed: RateSavingRateDto,
		public readonly variable: RateSavingRateDto
	) {}
}
