import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow-right-icon.svg';
import classnames from 'classnames';
import Button from 'components/common/buttons/button/Button';
import Card from 'components/common/card/Card';
import { useLanguageContext } from 'components/common/context/LanguageProvider';
import Header from 'components/common/header/Header';
import { useCustomAuth } from 'components/common/hooks/useCustomAuth';
import { useEffectOnce } from 'components/common/hooks/useEffectOnce';
import useViewportHeight from 'components/common/hooks/useViewportHeight';
import WisedayHomeLink from 'components/common/links/WisedayHomeLink';
import Page from 'components/common/page/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AmplitudeClient from 'services/AmplitudeClient';
import FacebookPixelClient from 'services/FacebookPixelClient';
import GoogleTagClient from 'services/GoogleTagClient';
import layoutStyles from '../layout.module.scss';
import styles from './GetStarted.module.scss';

type ChoiceBoxProps = {
	title: string;
	subTitle: string;
	buttonText: string;
	onClick: () => void;
};

function ChoiceBox(props: ChoiceBoxProps) {
	const { title, subTitle, buttonText, onClick } = props;
	return (
		<>
			<Card className={styles.choice} onClick={onClick}>
				<div className={styles.choiceTitle}>{title}</div>
				<p className={styles.choiceSubText}>{subTitle}</p>
				<div className={styles.choiceAction}>
					{buttonText}
					<ArrowRightIcon className={styles.choiceActionIcon} />
				</div>
			</Card>
			<Card className={styles.choiceMobile} onClick={onClick}>
				<div className={styles.choiceTitle}>{title}</div>
				<p className={styles.choiceSubText}>{subTitle}</p>
				<Button className={styles.choiceButton}>
					<ArrowRightIcon className={styles.choiceActionIcon} />
				</Button>
			</Card>
		</>
	);
}

export default function GetStarted() {
	const { t } = useTranslation();
	const { language } = useLanguageContext();
	const [searchParams] = useSearchParams();
	const homePage2ButtonsActive = searchParams.get('exp') === '1'; // If experiment = 1
	const navigate = useNavigate();
	useViewportHeight();
	const { isAuthenticated, isTempAuthenticated } = useCustomAuth();

	useEffectOnce(() => {
		AmplitudeClient.getInstance().logEvent('get-started show');
		GoogleTagClient.pageView('get-started');
		FacebookPixelClient.pageView('get-started');
	});

	return (
		<main className={layoutStyles.layout}>
			<header className={layoutStyles.header}>
				<Header
					rightSideContent={<WisedayHomeLink />}
					showAccountMenu={isAuthenticated || isTempAuthenticated}
				/>
			</header>
			<section
				id="content"
				className={classnames(layoutStyles.content, styles.content)}
			>
				<Page
					title={t('get-started:page-title')}
					description={t('get-started:meta-description')}
					canonicalPath={`/${language}/get-started`}
					className={styles.container}
				>
					<h1
						className={classnames(
							styles.title,
							homePage2ButtonsActive && styles.choice2ButtonsTitle
						)}
					>
						{t('get-started:title')}
					</h1>
					<div
						className={classnames(
							styles.choices,
							homePage2ButtonsActive && styles.choice2Buttons
						)}
					>
						<ChoiceBox
							title={t('get-started:renewal.title')}
							subTitle={t('get-started:renewal.sub-title')}
							buttonText={t('get-started:renewal.button')}
							onClick={() => {
								AmplitudeClient.getInstance().logEvent(
									'get-started renewal-cta'
								);
								FacebookPixelClient.logEvent('StartRenewal');
								navigate(
									`/${language}/renewal/property-province`
								);
							}}
						/>
						{!homePage2ButtonsActive && (
							<ChoiceBox
								title={t('get-started:new-mortgage.title')}
								subTitle={t(
									'get-started:new-mortgage.sub-title'
								)}
								buttonText={t(
									'get-started:new-mortgage.button'
								)}
								onClick={() => {
									AmplitudeClient.getInstance().logEvent(
										'get-started new-mortgage-cta'
									);
									FacebookPixelClient.logEvent(
										'StartNewMortgage'
									);
									navigate(
										`/${language}/get-rate/lives-in-property`
									);
								}}
							/>
						)}
						{/* 
                            // TODO: Re-enable once we have Refinance in Truss
                        <ChoiceBox
							title={t('get-started:refinance.title')}
							subTitle={t('get-started:refinance.sub-title')}
							buttonText={t('get-started:refinance.button')}
							onClick={() => {
								AmplitudeClient.getInstance().logEvent(
									'get-started refinance-cta'
								);
								FacebookPixelClient.logEvent('StartRefinance');
								navigate(
									`/${language}/refinance/property-province`
								);
							}}
						/> */}
					</div>
				</Page>
			</section>
		</main>
	);
}
