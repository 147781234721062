import Exception from '../exceptions/Exception';
import { ApplicationType } from '../model/value-objects/ApplicationType';
import { StepSectionType } from './StepSectionType';

export default class StepDefinition {
	constructor(
		public readonly section: StepSectionType,
		public readonly questionIndex: number,
		public readonly questionsTotal: number,
		public readonly currentStep: string,
		public readonly previousStep: string | null,
		public readonly nextStep: string | null,
		public readonly progress: number,
		public readonly data: any,
		public readonly nextIsSubmit: boolean = false, // TODO: UI concern. Should not be here.
		public readonly isPII: boolean = true, // DO NOT CHANGE THIS. Used to determine what we track in Amplitude.
		public readonly meta: any = {}
	) {}

	public static parse(json: any): StepDefinition {
		return new StepDefinition(
			json.section,
			json.questionIndex,
			json.questionsTotal,
			json.currentStep,
			json.previousStep,
			json.nextStep,
			json.progress,
			json.data,
			json.nextIsSubmit,
			json.isPII,
			json.meta
		);
	}

	public get applicationType(): ApplicationType {
		switch (this.section) {
			case StepSectionType.NewMortgage:
			case StepSectionType.NewMortgageIpa:
			case StepSectionType.CoApplicantNewMortgageIpa:
				return ApplicationType.NewMortgage;
			case StepSectionType.Refinance:
				return ApplicationType.Refinance;
			case StepSectionType.Renewal:
				return ApplicationType.Renewal;
			default:
				throw new Exception(
					`StepDefinition has invalid StepSectionType value '${this.section}`
				);
		}
	}

	public isFirstStep(): boolean {
		switch (this.section) {
			case StepSectionType.NewMortgage:
				return this.currentStep === 'lives-in-property';
			case StepSectionType.NewMortgageIpa:
			case StepSectionType.Refinance:
			case StepSectionType.Renewal:
				return this.currentStep === 'property-province';
			case StepSectionType.CoApplicantNewMortgageIpa:
				return this.currentStep === 'date-of-birth';
			default:
		}

		return false;
	}

	public isLastStep(): boolean {
		return this.currentStep === 'confirm-identity';
	}
}
