import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from '../buttons/button/Button';
import Card from '../card/Card';
import Portal from '../portal/Portal';
import baseStyles from './Dialog.module.scss';
import styles from './ExitDialog.module.scss';

export type ExitDialogProps = {
	className?: string;
	onCancel: () => void;
	onExit: () => void;
};

export default function ExitDialog(props: ExitDialogProps) {
	const { t } = useTranslation();
	const { className, onCancel, onExit } = props;
	return (
		<Portal parentId="root" className={baseStyles.dialogBackground}>
			<Card className={classnames(baseStyles.dialog, className)}>
				<div className={styles.title}>
					{t(`common:exit-dialog.title`)}
				</div>
				<p className={styles.text}>{t(`common:exit-dialog.text`)}</p>
				<Button
					className={styles.button}
					autoFocus
					buttonType={ButtonType.Primary}
					buttonSize={ButtonSize.Big}
					onClick={onCancel}
				>
					{t(`common:exit-dialog.cancel`)}
				</Button>
				<Button
					className={styles.button}
					autoFocus
					buttonType={ButtonType.Secondary}
					buttonSize={ButtonSize.Big}
					onClick={onExit}
				>
					{t(`common:exit-dialog.exit`)}
				</Button>
			</Card>
		</Portal>
	);
}
