export enum EntityTypeEnum {
	Customer = 'Customer',
	CustomerAddress = 'CustomerAddress',
	CustomerDocuments = 'CustomerDocuments',
	CustomerAdditionalIncome = 'CustomerAdditionalIncome',
	CustomerIncome = 'CustomerIncome',
	CustomerGdsTdsReport = 'CustomerGdsTdsReport',
	CustomerPlaidReport = 'CustomerPlaidReport',
	CustomerCreditReport = 'CustomerCreditReport',
	CustomerIncomeDetail = 'CustomerIncomeDetail',
	NewMortgage = 'NewMortgage',
	Refinance = 'Refinance',
	Renewal = 'Renewal',
	CoApplicant = 'CoApplicant',
	CoApplicantLiability = 'CoApplicantLiability',
	CoApplicantDocuments = 'CoApplicantDocuments'
}
