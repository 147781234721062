import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTopEffect = (id?: string) => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		const pageElement = window.document.getElementById(id ?? 'page');
		try {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			pageElement?.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			pageElement?.scrollTo(0, 0);
		} catch (error) {
			pageElement?.scrollTo(0, 0);
		}
	}, [id, pathname, search]);

	return null;
};
