import classnames from 'classnames';
import { ReactNode, useEffect } from 'react';
import { useLanguageContext } from '../context/LanguageProvider';
import { useScrollToTopEffect } from '../hooks/useScrollToTopEffect';
import styles from './Page.module.scss';

const setMetaProperty = (name: string, content: string | undefined) => {
	if (content) {
		let metaElement = document.querySelector(
			`meta[property='${name}']`
		) as any;
		if (!metaElement) {
			metaElement = document.createElement('meta');
			metaElement.setAttribute('property', name);
			document.head.append(metaElement);
		}
		metaElement.content = content;
	}
};

interface PageProps {
	className?: string;
	title: string;
	description?: string;
	canonicalPath?: string;
	children: ReactNode;
}
export default function Page({
	className,
	title,
	description,
	canonicalPath,
	children
}: PageProps): JSX.Element {
	const { language } = useLanguageContext();

	useEffect(() => {
		const [privateData] = title.match('<0>(.*)</0>') ?? '';
		const titleWithNoName = title.replace(privateData, ''); // Server returns name in <0></0> for privacy mask
		document.title = titleWithNoName;
		document.documentElement.setAttribute('lang', language);

		// Meta Description
		if (description) {
			let metaDescription = (document.head.children as any).description;
			if (!metaDescription) {
				metaDescription = document.createElement('meta');
				metaDescription.name = 'description';
				document.head.append(metaDescription);
			}
			metaDescription.content = description;
		}

		// Canonical Path
		let canonicalLink = document.querySelector(
			"link[rel='canonical']"
		) as any;

		if (canonicalPath) {
			if (!canonicalLink) {
				canonicalLink = document.createElement('link');
				canonicalLink.rel = 'canonical';
				document.head.append(canonicalLink);
			}
			canonicalLink.href = `${process.env.REACT_APP_APPLICATION_ROOT_URL}${canonicalPath}`;
		} else if (canonicalLink) {
			canonicalLink.remove();
		}

		// Facebook OG tag properties
		setMetaProperty('og:title', title);
		setMetaProperty('og:description', description);
		setMetaProperty(
			'og:site_name',
			process.env.REACT_APP_APPLICATION_ROOT_URL
		);
		setMetaProperty('og:type', 'website');
		setMetaProperty('og:title', title);
		setMetaProperty('og:locale', language);
		setMetaProperty(
			'og:image',
			`${process.env.REACT_APP_APPLICATION_ROOT_URL}/images/wiseday-logo-200x200.png`
		);
		setMetaProperty(
			'og:url',
			canonicalPath
				? `${process.env.REACT_APP_APPLICATION_ROOT_URL}${canonicalPath}`
				: `${process.env.REACT_APP_APPLICATION_ROOT_URL}`
		);
	}, [title, description, canonicalPath]);
	useScrollToTopEffect();
	return (
		<article id="page" className={classnames(styles.page, className)}>
			{children}
		</article>
	);
}
