import React, { useEffect, useRef, useState } from 'react';
import styles from './SelectInput.module.scss';
import classnames from 'classnames';
import { ReactComponent as DropDownIcon } from 'assets/images/chevron-down.svg';

export interface SelectOption {
	object?: any | undefined;
	value: string;
	label: string;
}

interface SelectInputProps {
	label: string;
	name: string;
	value: string | null | undefined;
	options: Array<SelectOption>;
	placeholder?: string;
	autoComplete?: string;
	locked?: boolean;
	autoFocus?: boolean;
	error?: string;
	className?: string;
	isPublic?: boolean;
	onChange?: (selectedItem: SelectOption | undefined) => void;
}

export default function SelectInput(props: SelectInputProps): JSX.Element {
	const { isPublic, autoComplete } = props;
	const inputRef = useRef<HTMLSelectElement>(null);
	const [state, setState] = useState({
		value: props.value || ''
	});
	const doChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
		const value = e.target.value;
		setState({ ...state, value });
		if (props.onChange) {
			props.onChange(props.options.find((o) => o.value === value));
		}
	};
	useEffect(() => {
		setState({ ...state, value: props.value ?? '' });
	}, [props.value]);
	return (
		<>
			<div
				className={classnames(
					styles.selectInput,
					props.locked && styles.locked,
					props.error && styles.error,
					props.className
				)}
			>
				<div
					className={styles.border}
					onClick={() => {
						inputRef?.current?.focus();
					}}
				>
					<div
						className={classnames(
							styles.label,
							props.error && styles.error
						)}
					>
						{props.label}
					</div>
					<select
						ref={inputRef}
						autoFocus={props.autoFocus}
						className={classnames(
							state.value === '' && styles.placeholder
						)}
						id={props.name}
						name={props.name}
						value={state.value ?? ''}
						autoComplete={autoComplete}
						disabled={props.locked}
						data-public={isPublic ? '' : undefined}
						data-private={!isPublic ? '' : undefined}
						data-dd-privacy={!isPublic ? 'mask' : 'allow'}
						onChange={doChange}
					>
						<option
							className={styles.placeholder}
							defaultValue={undefined}
							value={''}
						>
							{props.placeholder}
						</option>
						{props.options.map((o: SelectOption, i) => (
							<option
								className={styles.option}
								key={i}
								value={o.value}
							>
								{o.label}
							</option>
						))}
					</select>
					<DropDownIcon className={styles.dropDownIcon} />
				</div>
				<div className={styles.errorMessage}>{props.error}</div>
			</div>
		</>
	);
}
