import Exception from '../../exceptions/Exception';
import Address from '../addresses/Address';
import { EmploymentType } from '../value-objects/EmploymentType';
import { Frequency } from '../value-objects/Frequency';
import { IncomeType } from '../value-objects/IncomeType';
import { UUID, createUUID } from '../value-objects/UUID';
import { YesNo } from '../value-objects/YesNo';

interface IEmployment {
	readonly id: UUID;
	readonly type: EmploymentType;
	readonly name: string;
	readonly title: string | null;
	readonly isCurrent: boolean;
	readonly isSelfEmployed: YesNo | null;
	readonly address: Address;
	readonly phone: string | null;
	readonly incomeType: IncomeType;
	readonly incomeFrequency: Frequency;
	readonly incomeAmount: number;
	readonly industry: string | null;
	readonly industryYears: number | null;
	readonly industryMonths: number | null;
}

export default class Employment implements IEmployment {
	public readonly id: UUID;
	public readonly type: EmploymentType;
	public readonly name: string;
	public readonly title: string | null;
	public readonly isCurrent: boolean;
	public readonly isSelfEmployed: YesNo | null;
	public readonly address: Address;
	public readonly phone: string | null;
	public readonly incomeType: IncomeType;
	public readonly incomeFrequency: Frequency;
	public readonly incomeAmount: number;
	public readonly industry: string | null;
	public readonly industryYears: number | null;
	public readonly industryMonths: number | null;

	constructor(input: IEmployment) {
		this.id = input.id;
		this.type = input.type;
		this.name = input.name;
		this.title = input.title;
		this.isCurrent = input.isCurrent;
		this.isSelfEmployed = input.isSelfEmployed;
		this.address = Address.create(input.address);
		this.phone = input.phone;
		this.incomeType = input.incomeType;
		this.incomeFrequency = input.incomeFrequency;
		this.incomeAmount = input.incomeAmount;
		this.industry = input.industry;
		this.industryYears = input.industryYears;
		this.industryMonths = input.industryMonths;
	}

	public getAnnualAmount(): number {
		if (this.incomeAmount == null || this.incomeFrequency == null) {
			return 0;
		}
		switch (this.incomeFrequency) {
			case Frequency.Annual:
				return this.incomeAmount;
			case Frequency.SemiAnnual:
				return this.incomeAmount * 2;
			case Frequency.Monthly:
				return this.incomeAmount * 12;
			case Frequency.SemiMonthly:
				return this.incomeAmount * 24;
			case Frequency.BiWeekly:
				return this.incomeAmount * 26;
			case Frequency.Weekly:
				return this.incomeAmount * 52;
			default:
				throw new Exception(
					`Invalid Frequency value: ${this.incomeFrequency}`
				);
		}
	}

	public static create(fields?: Partial<Employment>): Employment {
		return new Employment({
			id: fields?.id ?? createUUID(),
			type: fields?.type ?? EmploymentType.FullTime,
			name: fields?.name ?? '',
			title: fields?.title ?? null,
			isCurrent: fields?.isCurrent ?? false,
			isSelfEmployed: fields?.isSelfEmployed ?? null,
			address: fields?.address ?? Address.create({}),
			phone: fields?.phone ?? null,
			incomeType: fields?.incomeType ?? IncomeType.Salaried,
			incomeFrequency: fields?.incomeFrequency ?? Frequency.Annual,
			incomeAmount: fields?.incomeAmount ?? 0,
			industry: fields?.industry ?? null,
			industryYears: fields?.industryYears ?? null,
			industryMonths: fields?.industryMonths ?? null
		});
	}

	public static isTotalMonthsOk(employments: Array<Employment>) {
		const totalYears = employments
			.map((e) => e.address.years ?? 0)
			.reduce((result, v) => result + v, 0);
		const totalMonths = employments
			.map((e) => e.address.months ?? 0)
			.reduce((result, v) => result + v, 0);
		return totalYears * 12 + totalMonths >= 36;
	}
}
