import DateHelper from './DateHelper';

export default class TimeRewardHelper {
	public static getSecondsTotal(): number {
		return 5 * 60;
	}

	public static getTotalMinutes(): number {
		return TimeRewardHelper.getSecondsTotal() / 60;
	}

	public static getSecondsAlert(): number {
		return 3 * 60;
	}

	public static isExpired(date: Date | string): boolean {
		const dateLimit = DateHelper.addMinutes(
			new Date(Date.now()),
			-TimeRewardHelper.getTotalMinutes()
		);
		let dateParam: Date;
		if (date instanceof Date) {
			dateParam = date;
		} else {
			dateParam = new Date(Date.parse(date));
		}
		return dateLimit.getTime() >= dateParam.getTime();
	}
}
