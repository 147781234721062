import { Language } from '@application/common';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AmplitudeClient from 'services/AmplitudeClient';

interface ILanguageContext {
	language: Language;
	changeLanguage: (lng: Language) => void;
}

const defaultState = {
	language: Language.En,
	changeLanguage: () => {
		// Do Nothing
	}
};

export const LanguageContext =
	React.createContext<ILanguageContext>(defaultState);

export function useLanguageContext(): ILanguageContext {
	return useContext(LanguageContext);
}

export default function LanguageProvider({ children }): JSX.Element {
	const [state, setState] = useState<ILanguageContext>(defaultState);
	const { i18n } = useTranslation();

	useEffect(() => {
		setState({
			language:
				i18n.languages.length > 0
					? (i18n.languages[0] as Language)
					: Language.En,
			changeLanguage: (lng: Language) => {
				AmplitudeClient.getInstance().setLanguage(lng);
				i18n.changeLanguage(lng);
				setState({
					...state,
					language: lng
				});
			}
		});
	}, [i18n, i18n.language]);

	return (
		<LanguageContext.Provider value={state}>
			{children}
		</LanguageContext.Provider>
	);
}
