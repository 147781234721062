import { ReactComponent as Logo } from 'assets/images/wiseday-logo.svg';
import styles from './WisedayHomeLink.module.scss';
import { useUserContext } from '../login/UserContextProvider';
import { useLanguageContext } from '../context/LanguageProvider';

export interface WisedayHomeLinkProps {
	className?: string;
}

export default function WisedayHomeLink(props: WisedayHomeLinkProps) {
	const { className } = props;
	const { language } = useLanguageContext();
	const { userExit } = useUserContext();
	return (
		<a
			className={className}
			href={`${process.env.REACT_APP_WEBSITE_ROOT_URL}/${language}`}
			onClick={async (e) => {
				e.preventDefault();
				if (await userExit()) {
					window.location.href = `${process.env.REACT_APP_WEBSITE_ROOT_URL}/${language}`;
				}
			}}
		>
			<Logo className={styles.logo} />
		</a>
	);
}
